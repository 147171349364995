import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Button, Container, Grid, Typography, IconButton, List, Tooltip, TextField } from '@material-ui/core/';
import AppContainer from './AppContainer';
import { getAllPatients, deletePatient } from '../actions/patients';
import { Add, Edit, Delete } from '@material-ui/icons';
import { isAdmin, isDoctor, isSecretary } from '../utils/authUtils';
import CustomListItem from './CustomListItem';
import { mobileCheck } from '../utils/mobileCheck';

import { capitalize } from './../utils/stringUtils';

import ReactExport from "react-export-excel-xlsx-fix";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    paper: {
        padding: theme.spacing(2),
    },
    container: {
        padding: theme.spacing(mobileCheck() ? 0 : 4),
    },
    grid: {
        margin: '10px',
    },
    textField: {
        width: '100%',
    },
    button: {
        margin: theme.spacing(1),
    },
    typography: {
        margin: theme.spacing(1),
    },
}));

export default function Patients(props) {
    const classes = useStyles();

    const [state, setState] = React.useState({
        loaded: false,
        patients: [],
    });

    const [pageLoaded, setPageLoaded] = React.useState(false);

    useEffect(() => {
        if (pageLoaded) return;
        setPageLoaded(true);
        fetchData();
        // eslint-disable-next-line
    }, []);

    async function fetchData() {
        const result = await getAllPatients();

        if (!result.success) {
            if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
            props.openSnack(result.message.error, 'error');
            return;
        }

        setState({ ...state, patients: result.data });
    }

    function detailsCallback(item) {
        props.history.push('patient/' + item._id);
    }

    async function deleteCallback(item) {
        props.openDialog("Confirmación", "Seguro que desea eliminar este paciente?", item, handleDeleteCallback);
    }

    async function handleDeleteCallback(confirmed, item) {
        if (!confirmed) return;
        const result = await deletePatient(item._id);
        if (!result.success) {
            if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
            props.openSnack(result.message.error, 'error');
            return;
        }
        fetchData();
    }

    function handleAdd() {
        props.history.push('patient/');
    }

    function filter(e) {
        setState({ ...state, filter: e.target.value });
    }


    function compare(a, b) {

        let aa = a.lastname === undefined ? a.fullname : a.lastname;
        let bb = b.lastname === undefined ? b.fullname : b.lastname;

        if (aa === bb)
            return 0;
        if (aa.localeCompare(bb))
            return -1;
        else return 1;

    }

    return (
        <div>
            <AppContainer title={"Pacientes"} history={props.history} content={
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        {!mobileCheck() && <Grid item xs={1} className={classes.grid} />}
                        <Grid item xs={!mobileCheck() ? 10 : 12} className={classes.grid}>
                            <Grid container>
                                <Grid item className={classes.grid}>
                                    <Typography variant="h6" noWrap className={classes.typography}>
                                        Listado
                                </Typography>
                                </Grid>
                                <Grid item className={classes.grid}>
                                    <Tooltip title="Agregar nuevo paciente"><IconButton onClick={handleAdd}><Add color="primary" /></IconButton></Tooltip>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.grid}>
                                <ExcelFile element={
                                    <Tooltip title="Exporta todos los pacientes a un archivo de Excel">
                                        <Button color='primary' variant='contained'>
                                            EXPORTAR A EXCEL
                                        </Button>
                                    </Tooltip>}>
                                    <ExcelSheet data={state.patients} name="Pacientes">
                                        <ExcelColumn label="Nombre" value="fullname" />
                                        <ExcelColumn label="Apellido" value="lastname" />
                                        <ExcelColumn label="Email" value="mail" />
                                        <ExcelColumn label="Teléfono" value="phone" />
                                    </ExcelSheet>
                                </ExcelFile>
                            </Grid>
                            <Grid item xs={12} className={classes.grid}>
                                <TextField
                                    id="standard-name"
                                    label="Filtro"
                                    className={classes.textField}
                                    onChange={filter}
                                />
                            </Grid>

                            <Paper className={classes.paper}>
                                <List
                                    component="nav"
                                    className={classes.list}
                                >
                                    {state.patients &&
                                        state.patients
                                            .filter(item => !state.filter
                                                || (item.fullname + " " + item.lastname).toLowerCase().indexOf(state.filter.toLowerCase()) > -1
                                                || (item.lastname + " " + item.fullname).toLowerCase().indexOf(state.filter.toLowerCase()) > -1
                                                || item.prepaidNumber.toLowerCase().includes(state.filter.toLowerCase())
                                                || item.uid.toLowerCase().includes(state.filter.toLowerCase())
                                            )
                                            .sort((a, b) => compare(a, b))
                                            .slice(0, 50)
                                            .map((item) => {
                                                return <CustomListItem
                                                    key={item._id}
                                                    item={item}
                                                    text={capitalize(`${item.fullname.trim()}, ${item.lastname === undefined ? '' : item.lastname}`)}
                                                    icons={[
                                                        (isAdmin() || isDoctor() || isSecretary()) ? { icon: <Edit />, callback: detailsCallback, tooltip: "Editar" } : undefined,
                                                        isAdmin() ? { icon: <Delete />, callback: deleteCallback, tooltip: "Eliminar" } : undefined,
                                                    ]} />
                                            })}
                                </List>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            } />
        </div >
    );
}
