import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Logo from "../assets/logo.png";
import All from "../assets/all.png";
import {
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from '@material-ui/core';
import {
  ChevronLeft,
  ChevronRight,
  Home,
  Menu,
  SupervisedUserCircle,
  ViewAgenda,
  BrokenImage,
  CreditCard,
  People,
  BarChart,
  LocalHospital,
  Build,
  ExitToApp,
  Person,
  Spa,
} from '@material-ui/icons';
import {
  isAdmin,
  isSecretary,
  isAccountant,
  isDoctor,
  getUserData,
  isAuthenticated,
} from '../utils/authUtils';
import { logout } from '../actions/auth';
import { mobileCheck } from '../utils/mobileCheck';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  toolbarIcon: {
    marginLeft: 5,
  },
  menu: {
    marginLeft: 10,
    marginRight: 20,
  },
  img: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '30px',
    width: '220px',
  },
  imghidden: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '30px',
    width: '0px',
  },
}));

export default function AppContainer(props) {
  const content = props.content;
  const title = props.title;
  const classes = useStyles();
  const theme = useTheme();
  const buttons = [
    { label: 'Inicio', icon: <Home />, navigation: '/home', enabled: false },
    { label: 'Usuarios', icon: <SupervisedUserCircle />, navigation: '/users', enabled: isAdmin() },
    {
      label: 'Agenda',
      icon: <ViewAgenda />,
      navigation: '/schedule',
      enabled: isSecretary() || isAdmin(),
    },
    {
      label: 'Agenda',
      icon: <ViewAgenda />,
      navigation: '/scheduleDoctor',
      enabled: isDoctor(),
    },
    {
      label: 'Pacientes',
      icon: <People />,
      navigation: '/patients',
      enabled: isAccountant() || isDoctor() || isSecretary() || isAdmin(),
    },
    {
      label: 'Facturación',
      icon: <BarChart />,
      navigation: '/billing',
      enabled: isAccountant() || isAdmin(),
    },
    {
      label: 'Estudios',
      icon: <BrokenImage />,
      navigation: '/patientTreatments',
      enabled: false, // isDoctor() || isAdmin(),
    },
    {
      label: 'Editor Estudios',
      icon: <BrokenImage />,
      navigation: '/treatments',
      enabled: isAdmin(),
    },
    {
      label: 'Feriados',
      icon: <Spa />,
      navigation: '/holidays',
      enabled: isAdmin(),
    },
    {
      label: 'Prepagas',
      icon: <CreditCard />,
      navigation: '/prepaids',
      enabled: isDoctor() || isSecretary() || isAdmin(),
    },
    { divider: true },
    { label: 'Insumos', icon: <LocalHospital />, navigation: '/supplies', enabled: true },
    { label: 'Nomenclador', icon: <Build />, navigation: '/treatmentCodes', enabled: true },
    { divider: true },
    { label: 'Perfil', icon: <Person />, navigation: '/user/' + getUserData().id, enabled: true },
    { label: 'Cerrar sesión', icon: <ExitToApp />, navigation: '/exit', enabled: true },
  ];
  const [open, setOpen] = React.useState(false);

  if (!isAuthenticated()) props.history.push('/');

  function handleDrawerOpen() {
    setOpen(true);
  }

  function handleDrawerClose() {
    setOpen(false);
  }

  function handleNavigation(where) {
    handleDrawerClose();
    props.history.push(where);
  }

  function handleLogout() {
    logout();
    props.history.push('/');
  }

  function handleProfile() {
    props.history.push(`/user/${getUserData().id}`);
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <Menu />
            <Typography className={classes.menu}>Menu</Typography>
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          {!mobileCheck() && getUserData() && (
            <Button color="inherit" onClick={handleProfile}>
              {getUserData().name}
              <Person className={classes.toolbarIcon} />
            </Button>
          )}
          {!mobileCheck() && <Button color="inherit" onClick={handleLogout}>
            Salir
            <ExitToApp className={classes.toolbarIcon} />
          </Button>}
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {buttons.map((item, index) => {
            if (item.divider) return <Divider key={index} />;
            else
              return (
                item.enabled && (
                  <ListItem
                    disabled={!item.enabled}
                    button
                    key={index}
                    onClick={() => handleNavigation(item.navigation)}
                  >
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.label} />
                  </ListItem>
                )
              );
          })}
        </List>
        <img className={classes.img} src={Logo} alt="Logo" />
        <img className={classes.imghidden} src={All} alt="All" />
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        {content}
      </main>
    </div>
  );
}
