import axios from "axios";
import { logout } from "../actions/auth";

export const setAuthToken = (data) => {
  if (data) {
    localStorage.setItem("token", data.token);
    localStorage.setItem("userData", JSON.stringify(data.data));
    axios.defaults.headers.common["x-auth-token"] = data.token;
  } else {
    delete axios.defaults.headers.common["x-auth-token"];
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
  }
};

let apiPort = undefined;

export const getApiPort = async () => {
  if (apiPort) return;
  const r = await fetch("/apiPort.txt");
  const text = await r.text();
  //console.log("apiPort:", text);
  if (process.env.NODE_ENV === "production") {
    axios.defaults.baseURL = `http://eco.dscuatro.com:${text}`;
  } else {
    axios.defaults.baseURL = `http://192.168.100.8:${text}`;
    //axios.defaults.baseURL = `http://eco.dscuatro.com:${text}`;
    //axios.defaults.baseURL = `http://localhost:${text}`;
  }
  //axios.defaults.baseURL = `http://eco.dscuatro.com:${text}`;
};

export const isAuthenticated = () => {
  const token = localStorage.getItem("token");
  const userData = localStorage.getItem("userData");

  if (process.env.NODE_ENV === "production") getApiPort();
  axios.defaults.headers.common["x-auth-token"] = token;
  return token && userData;
};

export const getUserData = () => {
  try {
    return JSON.parse(localStorage.getItem("userData"));
  } catch (err) {
    logout();
  }
};

export function isAdmin(user) {
  if (!user) user = getUserData();

  if (!user) return false;

  if (!user.roles) return false;

  return user.roles.indexOf("Administrador/a") >= 0;
}

export function isAccountant(user) {
  if (!user) user = getUserData();

  if (!user) return false;

  if (!user.roles) return false;

  return user.roles.indexOf("Contador/a") >= 0;
}

export function isDoctor(user) {
  if (!user) user = getUserData();

  if (!user) return false;

  if (!user.roles) return false;

  return user.roles.indexOf("Doctor/a") >= 0;
}

export function isSecretary(user) {
  if (!user) user = getUserData();

  if (!user) return false;

  if (!user.roles) return false;

  return user.roles.indexOf("Secretario/a") >= 0;
}

export function hasPermission(props) {
  return true;
}
