import axios from "axios";

export const getAllTreatmentTypes = async () => {
  try {
    const res = await axios.get("/treatmentTypes");
    const r = { success: true, message: "", data: res.data };
    r.data.sort(function (a, b) {
      return a.position - b.position;
    });
    return r;
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const getTreatmentType = async (id) => {
  try {
    const res = await axios.get(`/treatmentTypes/${id}`);
    return { success: true, message: "", data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const createTreatmentType = async (treatment) => {
  try {
    const res = await axios.post(`/treatmentTypes/`, treatment);
    return { success: true, message: "", data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const modifyTreatmentType = async (treatment) => {
  try {
    const res = await axios.put(`/treatmentTypes/`, treatment);
    return { success: true, message: "", data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const modifyTreatmentTypesPositions = async (treatments) => {
  try {
    const res = await axios.patch(`/treatmentTypes/`, treatments);
    return { success: true, message: "", data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const deleteTreatmentType = async (id) => {
  try {
    const res = await axios.delete(`/treatmentTypes/${id}`);
    return { success: true, message: "", data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};
