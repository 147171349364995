import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Container, Grid, Typography, IconButton, List, Tooltip, TextField } from '@material-ui/core/';
import AppContainer from './AppContainer';
import { getAllHolidays, deleteHoliday } from '../actions/holidays';
import CustomListItem from './CustomListItem';
import { Add, Edit, Delete } from '@material-ui/icons';
import { isAdmin } from '../utils/authUtils';
import { mobileCheck } from '../utils/mobileCheck';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    paper: {
        padding: theme.spacing(2),
    },
    container: {
        padding: theme.spacing(mobileCheck() ? 0 : 4),
    },
    grid: {
        margin: '10px',
    },
    textField: {
        width: '100%',
    },
    button: {
        margin: theme.spacing(1),
    },
    typography: {
        margin: theme.spacing(1),
    },
}));

export default function Holidays(props) {
    const classes = useStyles();

    const [state, setState] = React.useState({
        loaded: false,
        holidays: [],
    });
    
    const [pageLoaded, setPageLoaded] = React.useState(false);

    useEffect(() => {
        if (!isAdmin())
            props.history.push("/");

        if(pageLoaded) return;
        setPageLoaded(true);
        fetchData();
        // eslint-disable-next-line
    }, []);

    async function fetchData() {
        const result = await getAllHolidays();

        if (!result.success) {
            if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
            props.openSnack(result.message.error, 'error');
            return;
        }

        setState({ ...state, holidays: result.data });
    }

    function detailsCallback(item) {
        props.history.push('holiday/' + item._id);
    }

    async function deleteCallback(item) {
        props.openDialog("Confirmación", "Seguro que desea eliminar este feriado?", item, handleDeleteCallback);
    }

    async function handleDeleteCallback(confirmed, item) {
        if (!confirmed)
            return;
        const result = await deleteHoliday(item._id);
        if (!result.success) {
            if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
            props.openSnack(result.message.error, 'error');
            return;
        }
        fetchData();
    }

    function handleAdd() {
        props.history.push('holiday/');
    }

    function filter(e) {
        setState({ ...state, filter: e.target.value });
    }

    return (
        <div>
            <AppContainer title={"Feriados"} history={props.history} content={
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        {!mobileCheck() && <Grid item xs={1} className={classes.grid} />}
                        <Grid item xs={!mobileCheck() ? 10 : 12} className={classes.grid}>
                            <Grid container>
                                <Grid item className={classes.grid}>
                                    <Typography variant="h6" noWrap className={classes.typography}>
                                        Listado
                                </Typography>
                                </Grid>
                                <Grid item className={classes.grid}>
                                    <Tooltip title="Agregar nuevo feriado">
                                        <IconButton onClick={handleAdd}><Add color="primary" /></IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} className={classes.grid}>
                                <TextField
                                    id="standard-name"
                                    label="Filtro"
                                    className={classes.textField}
                                    onChange={filter}
                                />
                            </Grid>

                            <Paper className={classes.paper}>
                                <List
                                    component="nav"
                                    className={classes.list}
                                >
                                    {state.holidays &&
                                        state.holidays.filter(item => !state.filter
                                            || (item.name && item.name.toLowerCase().includes(state.filter.toLowerCase()))
                                        ).map((item) =>
                                            (<CustomListItem
                                                key={item._id}
                                                item={item}
                                                text={item.name}
                                                icons={[
                                                    { icon: <Edit />, callback: detailsCallback, tooltip: "Editar" },
                                                    { icon: <Delete />, callback: deleteCallback, tooltip: "Eliminar" },
                                                ]} />))}
                                </List>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            } />
        </div>
    );
}
