import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Container,
  Grid,
  IconButton,
  Tooltip,
  TextField,
  Dialog,
  DialogContent,
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core/';
import AppContainer from './AppContainer';
import {
  getMedicalExam,
  createMedicalExam,
  modifyMedicalExam,
  deleteMedicalExam,
} from '../actions/medicalExams';
import { Delete, Done, Close, Edit } from '@material-ui/icons';
import PredictiveComboBox from './PredictiveText';
import { getAllDoctors } from '../actions/doctors';
import { getAllPatients } from '../actions/patients';
import PatientTreatmentDialog from './PatientTreatmentDialog';
import {
  createTreatment,
  uploadTreatmentFiles,
  downloadTreatmentsFile,
  getAllTreatments,
  sendMail,
  deleteTreatment,
} from '../actions/treatments';
import PatientTreatment from './PatientTreatment';
import { isDoctor, getUserData } from '../utils/authUtils';
import { mobileCheck } from '../utils/mobileCheck';
import { dateToString4File, dateToString } from '../utils/dateToString';
import { createAndDownloadPDFForMedicalExam, fixName } from '../utils/pdf';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { capitalize } from './../utils/stringUtils';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  paper: {
    padding: theme.spacing(2),
  },
  container: {
    padding: theme.spacing(mobileCheck() ? 0 : 4),
  },
  grid: {
    margin: '10px',
  },
  textField: {
    width: '100%',
  },
  button: {
    margin: theme.spacing(1),
  },
  typography: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    margin: theme.spacing(1),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  progress: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export default function MedicalExam(props) {
  const classes = useStyles();

  const [objectData, setObjectData] = React.useState({
    doctor: null,
    patient: null,
    date: new Date(),
    isDraft: false,
  });

  const [treatmentReports, setTreatmentReports] = React.useState([]);
  const [doctors, setDoctors] = React.useState([]);
  const [patients, setPatients] = React.useState([]);
  const [pageLoaded, setPageLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [disableButton, setDisableButton] = React.useState(false);

  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const [state, setState] = React.useState({
    loaded: false,
    create: false,
    edit: false,
    dialogOpened: false,
  });

  /* eslint-disable*/
  useEffect(() => {
    if (pageLoaded) return;
    fetchData(props.match.params.id);
  }, []);
  /* eslint-enable*/

  async function fetchData(id) {
    const doctors = await getAllDoctors();
    setDoctors([...doctors.data]);

    const patients = await getAllPatients();

    setPatients([...patients.data]);

    const patientTreatments = await getAllTreatments();

    if (!id) {
      let doctor = undefined;
      if (isDoctor()) {
        doctor = doctors.data.filter(item => item._id === getUserData().id)[0];
      }

      setObjectData({
        ...objectData,
        doctor: doctor,
        patient: patients.data.filter(item => item._id === props.match.params.patient)[0],
      });

      setState({ ...state, create: true, edit: true });
    } else {
      const medicalExam = await getMedicalExam(id);
      if (!medicalExam.success) {
        if (medicalExam.message.error.indexOf('Token') !== -1) props.tokenExpired();
        props.openSnack(medicalExam.message.error, 'error');

        return;
      }

      if (medicalExam.data[0] === undefined) {
        props.openSnack("Estudio no encontrado", 'error');
        return;
      }

      let ptnt = patients.data.filter(item => item._id === medicalExam.data[0].patient)[0];

      ptnt.fullname = fixName(ptnt.fullname);
      ptnt.lastname = fixName(ptnt.lastname);

      setObjectData({
        ...medicalExam.data[0],
        date: medicalExam.data[0].date,
        treatmentReports: [],
        doctor: doctors.data.filter(item => item._id === medicalExam.data[0].doctor)[0],
        patient: ptnt,
      });

      setSelectedDate(new Date(medicalExam.data[0].date));

      const tr = medicalExam.data[0].treatmentReports.map(
        item => patientTreatments.data.filter(pt => pt._id === item)[0],
      );

      setTreatmentReports(tr);
    }
    setPageLoaded(true);
    setLoading(false);
  }

  function handleDateChange(date) {
    setSelectedDate(date);
  }

  async function handleSendMail() {

    try {
      console.log(treatmentReports);
      const result = await sendMail(treatmentReports.map(x => x._id));
      if (!result.success) {
        if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
        props.openSnack(result.message.error, 'error');
        return;
      }
      props.openSnack("Mail enviado correctamente", 'success');
    }
    catch (err) {
      props.openSnack(err, 'error');
    }
  }

  function handleAddTreatment() {
    setState({ ...state, dialogOpened: true });
  }

  async function handleCloseTreatmentDialog(treatment, files) {
    if (!treatment) {
      setState({ ...state, dialogOpened: false });
      return;
    }

    treatment = { ...treatment, patient: objectData.patient._id, doctor: objectData.doctor._id };

    const result = await createTreatment(treatment);

    if (!result.success) {
      if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
      props.openSnack(result.message.error, 'error');
      return;
    }

    uploadFiles(result.data[0]._id, files);

    setTreatmentReports([...treatmentReports, result.data[0]]);
    setState({ ...state, dialogOpened: false });
  }

  async function uploadFiles(treatmentId, files) {
    files.forEach(async file => {
      const formData = new FormData();
      formData.append('file', file, file.name);

      await uploadTreatmentFiles(treatmentId, formData);
    });
  }

  async function handleFileDownload(file, item) {
    const result = await downloadTreatmentsFile(item._id, file);

    const downloadUrl = window.URL.createObjectURL(new Blob([result.data]));

    const link = document.createElement('a');

    link.href = downloadUrl;
    link.setAttribute('download', file); //any other extension

    document.body.appendChild(link);

    link.click();

    link.remove();
  }

  function handleDeleteTreatment(item) {
    props.openDialog("Confirmación", "Seguro que desea eliminar este usuario?", item, handleDeleteTreatmentCallback);
  }

  async function handleDeleteTreatmentCallback(confirmed, item) {
    if (!confirmed)
      return;
    const result = await deleteTreatment(item._id);
    if (!result.success) {
      if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
      props.openSnack(result.message.error, 'error');
      return;
    }

    const treatmentReportsModified = [...treatmentReports];

    for (var i = treatmentReportsModified.length - 1; i >= 0; i--) {
      if (treatmentReportsModified[i] === item) {
        treatmentReportsModified.splice(i, 1);
        break;
      }
    }
    setTreatmentReports(treatmentReportsModified);
  }

  function handleCancelEdit() {

    if (objectData.patient._id !== undefined) {
      props.history.push(`/patient/${objectData.patient._id}`);
      return;
    }

    props.history.push(`/patients`);
  }

  async function handleDoneEdit() {
    const medicalExamPrepared = {
      doctor: objectData.doctor._id,
      patient: objectData.patient._id,
      treatmentReports: treatmentReports.filter(x => x !== undefined).map(item => item._id),
      date: selectedDate.getTime(),
      isDraft: objectData.isDraft,
    };

    setDisableButton(true);

    try {
      let result = {};
      if (state.create) {
        result = await createMedicalExam(medicalExamPrepared);
        setObjectData({ ...objectData, _id: result.data[0]._id });
        props.history.push('/medicalExam/' + result.data[0]._id);
      } else {
        medicalExamPrepared._id = objectData._id;
        result = await modifyMedicalExam(medicalExamPrepared);
        props.history.push('/medicalExam/' + objectData._id);
      }
      window.location.reload();
    } catch (e) {
      setDisableButton(false);
      props.openSnack("Error de conexion, intentar nuevamente. " + e.message, 'error');
    }
  }

  function handleDeleteMedicalExam() {
    if (!state.create)
      props.openDialog(
        'Confirmación',
        'Seguro que desea eliminar este estudio?',
        objectData,
        handleDeleteCallback,
      );
  }

  async function handleDeleteCallback(confirmed, item) {
    if (!confirmed) return;
    const result = await deleteMedicalExam(item._id);
    if (!result.success) {
      if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
      props.openSnack(result.message.error, 'error');
      return;
    }
    props.history.push('/patients');
  }

  async function handleDraftChange() {
    setObjectData({ ...objectData, isDraft: !objectData.isDraft });
  }

  const componentRef = useRef();

  /*function getDate() {
    try {
      return objectData.date !== undefined && `${objectData.date.getDate()}/${objectData.date.getMonth() + 1}/${objectData.date.getYear() +
        1900} ${objectData.date.getHours()}:${objectData.date.getMinutes()}`;
    } catch (err) {
      return dateToString(new Date());
    }
  }*/

  return (
    <div>
      <AppContainer
        title={state.create ? 'Nuevo Informe' : 'Informe'}
        history={props.history}
        content={
          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
              {!mobileCheck() && <Grid item xs={1} className={classes.grid} />}

              {loading ?
                <Grid item xs={12} className={classes.grid}>
                  <CircularProgress className={classes.progress} />
                </Grid>
                :
                <Grid item xs={!mobileCheck() ? 10 : 12} className={classes.grid}>
                  <Grid container>
                    <Grid item className={classes.grid}>
                      {!state.edit &&
                        <Tooltip title="Editar">
                          <IconButton onClick={() => setState({ ...state, edit: true })}>
                            <Edit />
                          </IconButton>
                        </Tooltip>}
                      {state.edit && (
                        <Tooltip title="Cancelar">
                          <IconButton onClick={handleCancelEdit}>
                            <Close />
                          </IconButton>
                        </Tooltip>
                      )}
                      {state.edit && (
                        <Tooltip title="Aceptar">
                          <IconButton
                            disabled={disableButton}
                            onClick={() => { if (!disableButton) handleDoneEdit() }}>
                            <Done color={disableButton ? "grey" : "primary"} />
                          </IconButton>
                        </Tooltip>
                      )}
                      {state.edit && (
                        <Tooltip title="Eliminar">
                          <IconButton onClick={handleDeleteMedicalExam}>
                            <Delete color="error" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                  <div ref={componentRef}>
                    <Paper className={classes.paper}>
                      <Grid item xs={3} className={classes.grid}>
                        {state.edit ?
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <DatePicker
                              autoOk
                              openTo="date"
                              value={selectedDate}
                              onChange={handleDateChange}
                            />
                          </MuiPickersUtilsProvider>
                          :
                          <TextField
                            label={'Fecha'}
                            className={classes.textField}
                            value={dateToString(selectedDate)}
                            name={'date'}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        }
                      </Grid>
                      {!objectData.doctor && state.edit ? (
                        <Grid container>
                          <Grid item xs={10} className={classes.grid}>
                            <PredictiveComboBox
                              reduce={false}
                              label={'Doctor'}
                              suggestions={doctors.map((it, ix) => {
                                return {
                                  key: ix,
                                  label: it.fullname,
                                  value: it,
                                };
                              })}
                              multi={false}
                              changecallback={v => {
                                setObjectData({ ...objectData, doctor: v.value });
                              }}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid container>
                          <Grid item xs={10} className={classes.grid}>
                            <TextField
                              label={'Doctor'}
                              className={classes.textField}
                              value={objectData.doctor ? objectData.doctor.fullname : ''}
                              name={'doctor'}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                          {state.edit && treatmentReports.length === 0 && (
                            <Grid item>
                              <Tooltip title="Cancelar">
                                <IconButton
                                  onClick={() => {
                                    setObjectData({ ...objectData, doctor: null });
                                  }}
                                >
                                  <Close />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      )}
                      {!objectData.patient && state.edit ? (
                        <Grid container>
                          <Grid item xs={10} className={classes.grid}>
                            <PredictiveComboBox
                              label={'Paciente'}
                              suggestions={patients.map((it, ix) => {
                                return {
                                  key: ix,
                                  label: capitalize(`${(it.lastname ? it.lastname.trim() + "," : "")} ${it.fullname}`),
                                  value: it,
                                };
                              })}
                              multi={false}
                              changecallback={v => {
                                setObjectData({ ...objectData, patient: v.value });
                              }}
                            />
                          </Grid>
                        </Grid>
                      ) : (
                        <Grid container>
                          <Grid item xs={10} className={classes.grid}>
                            <TextField
                              label={'Paciente'}
                              className={classes.textField}
                              value={objectData.patient ? capitalize(objectData.patient.lastname ? objectData.patient.lastname.trim() + ", " + objectData.patient.fullname : objectData.patient.fullname) : ''}
                              name={'patient'}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                          </Grid>
                          {state.edit && treatmentReports.length === 0 && (
                            <Grid item>
                              <Tooltip title="Cancelar">
                                <IconButton
                                  onClick={() => {
                                    setObjectData({ ...objectData, patient: null });
                                  }}
                                >
                                  <Close />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      )}
                      <br />

                      {(state.create || (state.edit && objectData.isDraft)) &&
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={objectData.isDraft}
                              onChange={handleDraftChange}
                              value={'Borrador'}
                            />
                          }
                          label={'Borrador'}
                        />}

                      {state.edit && objectData.doctor && objectData.patient && (
                        <Grid item className={classes.grid}>
                          <Button onClick={handleAddTreatment} color="primary">
                            Agregar Estudio
                          </Button>
                        </Grid>
                      )}

                      <Grid container>
                        {!state.edit && (
                          <Grid item className={classes.grid}>
                            <Button onClick={handleSendMail} color="primary">
                              Enviar al mail
                            </Button>
                          </Grid>
                        )}
                        {!state.edit && (
                          <Grid item className={classes.grid}>
                            <Button onClick={() => createAndDownloadPDFForMedicalExam({ ...objectData, treatmentReports }, capitalize(`${objectData.patient.lastname !== undefined ? objectData.patient.lastname.trim() + ", " + objectData.patient.fullname : ""} ${dateToString4File(new Date())}.pdf`))}>Imprimir informe completo</Button>
                          </Grid>
                        )}
                      </Grid>
                    </Paper>
                    <br />
                    {treatmentReports &&
                      treatmentReports
                        .filter(x => x !== undefined)
                        .map((item, index) => (
                          <PatientTreatment
                            isDraft={objectData.isDraft}
                            openDialog={props.openDialog}
                            displayDate={false}
                            key={item._id}
                            data={item}
                            editable={state.create || state.edit}
                            patient={objectData}
                            delete={() => handleDeleteTreatment(item)}
                            download={handleFileDownload}
                          />
                        ))}
                  </div>
                </Grid>}
            </Grid>
          </Container>
        }
      />
      <Dialog
        open={state.dialogOpened ? state.dialogOpened : false}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <PatientTreatmentDialog close={handleCloseTreatmentDialog} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
