import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { useEffect } from 'react';

export default function RichTextEditor(props) {

  const [changedText, setChangexText] = useState(props.value ?? "");
  //const [changedText, setChangexText]=useState("");
  useEffect(() => {
    setChangexText(props.value)
  }, [props.value, props.readOnly])

  return (
    <div>
             {/* initialValue={props.value} */}
      <Editor

        apiKey={"flt7t19it1m7qlx0e46zumbl5zbrkyks7e7487f2p4st6g5o"}
        value={changedText}
        disabled={props.readOnly}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            'advlist autolink lists',
            'charmap anchor',
            'searchreplace visualblocks code',
            'insertdatetime media table paste wordcount'
          ],
          toolbar:
            'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent | table',


        }}
        onFocusOut={() => {
          props.onChange(changedText);
        }}
        onEditorChange={(value) => {
          setChangexText(value);
        }}
      />
    </div>
  );
}
