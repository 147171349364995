import axios from "axios";

export const getAllSchedules = async (date) => {
  try {
    if (!date) {
      date = new Date();
    }
    var dd = date.getDate();
    var mm = date.getMonth();
    var yyyy = date.getFullYear();
    const convertedDate = yyyy + "-" + dd + "-" + mm;
    const url = `/schedules/all/${convertedDate}`;
    const res = await axios.get(url);
    return { success: true, message: "", data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const getSchedule = async (doc, date) => {
  try {
    if (!date) {
      date = new Date();
    }
    var dd = date.getDate();
    var mm = date.getMonth();
    var yyyy = date.getFullYear();
    const convertedDate = yyyy + "-" + dd + "-" + mm;
    const url = `/schedules/${doc ? `${doc}/` : ""}${convertedDate}`;
    const res = await axios.get(url);
    return { success: true, message: "", data: res.data };
  } catch (err) {
    return { success: false, message: err };
  }
};

export const getScheduleAvailability = async (doc) => {
  try {
    const date = new Date();
    var dd = date.getDate();
    var mm = date.getMonth();
    var yyyy = date.getFullYear();
    const convertedDate = yyyy + "-" + dd + "-" + mm;
    const url = `/schedules/available/${doc ? `${doc}/` : ""}${convertedDate}`;
    const res = await axios.get(url);
    return { success: true, message: "", data: res.data };
  } catch (err) {
    return { success: false, message: err };
  }
};

export const getTurn = async (id) => {
  try {
    const url = `/schedules/turn/${id}`;
    const res = await axios.get(url);
    return { success: true, message: "", data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const getTurnsByPatient = async (id) => {
  try {
    const url = `/schedules/patient/${id}`;
    const res = await axios.get(url);
    return { success: true, message: "", data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const createTurn = async (turn) => {
  try {
    const url = `/schedules/turn/`;
    const res = await axios.post(url, turn);
    return { success: true, message: "", data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const modifyTurn = async (turn) => {
  try {
    const url = `/schedules/turn/`;
    const res = await axios.put(url, turn);
    return { success: true, message: "", data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const deleteTurn = async (id) => {
  try {
    const url = `/schedules/turn/${id}`;
    const res = await axios.delete(url);
    return { success: true, message: "", data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};
