import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Container, Grid, TextField, Button, Snackbar, Typography } from '@material-ui/core/';
import { updatePassword } from '../actions/auth';
import MySnackbarContentWrapper from './AppSnackbar';
import { mobileCheck } from '../utils/mobileCheck';
import Logo from "../assets/logo.png";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  paper: {
    padding: theme.spacing(2),
  },
  container: {
    padding: theme.spacing(mobileCheck() ? 0 : 4),
  },
  grid: {
    margin: '10px',
    width: '100%',
  },
  textField: {
    width: '100%',
  },
  button: {
    margin: theme.spacing(1),
  },
  typography: {
    margin: theme.spacing(1),
    textAlign: 'center',
    width: '100%',
  },
  img: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '500px',
    paddingTop: '20px',
    paddingBottom: '10px',
  },
}));

export default function UpdatePasswordView(props) {
  const classes = useStyles();

  const [values, setValues] = React.useState({
    password1: '',
    password2: '',
  });

  const [snack, setSnack] = React.useState({
    open: false,
    text: "Snack text",
    variant: "error",
  })

  useEffect(() => {
    if (!props.match.params.token)
      props.history.push('/');
    //eslint-disable-next-line
  }, []);

  function openSnack(message, variant) {
    setSnack({ ...snack, open: true, text: message, variant: variant });
  }

  function handleSnackClose() {
    setSnack({ ...snack, open: false });
    props.history.push('/');
  }

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const onLogin = async () => {
    const result = await updatePassword(props.match.params.token, values.password1);

    if (!result.success) {
      openSnack(result.message.error, 'error');
      return;
    }

    openSnack('Contraseña cambiada', 'success');
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.grid}>
          <img className={classes.img} src={Logo} alt="Logo" />
        </Grid>
        <Grid item xs={!mobileCheck() ? 3 : 2} />
        <Grid item xs={!mobileCheck() ? 6 : 8}>
          <Paper className={classes.paper}>
            <Typography variant="h6" className={classes.typography}>
              Actualizar contraseña
            </Typography>
            <Grid container>
              <Grid item xs={12} className={classes.grid}>
                <TextField
                  label="Contraseña"
                  className={classes.textField}
                  id="standard-password-input"
                  type="password"
                  onChange={handleChange('password1')}
                  value={values.password1}
                />
              </Grid>
              <Grid item xs={12} className={classes.grid}>
                <TextField
                  label="Confirmar contraseña"
                  className={classes.textField}
                  id="standard-password-input"
                  type="password"
                  onChange={handleChange('password2')}
                  value={values.password2}
                />
              </Grid>
              <Grid item xs={12}><br /></Grid>
              <Grid item xs={12}><br /></Grid>
              <Grid item xs={7} />
              <Grid item >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={onLogin}
                >
                  Confirmar
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snack.open}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <MySnackbarContentWrapper
          onClose={handleSnackClose}
          variant={snack.variant}
          message={snack.text}
        />
      </Snackbar>
    </Container>

  );
}
