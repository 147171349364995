/* eslint-disable*/
import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper, List, ListSubheader, Divider, ListItem, ListItemText, TextField, Button, FormControl,InputLabel,NativeSelect, IconButton } from '@material-ui/core';
import { ExpandLess, Search } from '@material-ui/icons';
import { getAllDoctors } from '../actions/doctors';
import { getBilling } from '../actions/billing';
import PredictiveComboBox from './PredictiveText';
import BillingListItem from './BillingListItem';
import { getAllTreatmentTypes } from '../actions/treatmentTypes';
import { getAllTreatmentPrices } from '../actions/treatmentTypePrices';
import { getAllPatients } from '../actions/patients';
import {
    MuiPickersUtilsProvider,
    DatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { getPrepaids } from '../actions/prepaids';
import { dateToString } from '../utils/dateToString';
import { mobileCheck } from '../utils/mobileCheck';
import ReactExport from "react-export-excel-xlsx-fix";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    paper: {
        padding: theme.spacing(2),
    },
    container: {
        padding: theme.spacing(mobileCheck() ? 0 : 4),
    },
    grid: {
        margin: '10px',
    },
    textField: {
        width: '100%',
    },
    button: {
        margin: theme.spacing(1),
    },
    typography: {
        margin: theme.spacing(1),
    },
    list: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    listItemText: {
        textAlign: 'left',
        width: '100px',
    },
    listItemTextRight: {
        textAlign: 'right',
        paddingRight: theme.spacing(2),
        width: '100px',
    },
    hidden: {
        visibility: 'hidden',
    }
}));

export default function BillingBasicView(props) {
    const classes = useStyles();

    const [selectedDateFrom, setSelectedFromDate] = React.useState(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()));
    const [selectedDateTo, setSelectedToDate] = React.useState(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1));

    const [treatmentTypes, setTreatmentTypes] = React.useState([]);
    const [treatmentPrices, setTreatmentPrices] = React.useState([]);

    const [prepaids, setPrepaids] = React.useState([]);
    const [patients, setPatients] = React.useState([]);
    const [treatments, setTreatments] = React.useState([]);
    const [doctors, setDoctors] = React.useState([]);
    const [selectedPrepaid, setSelectedPrepaid] = React.useState([]);
    const [selectedPatient, setSelectedPatient] = React.useState([]);
    const [selectedTreatment, setSelectedTreatment] = React.useState([]);
    const [selectedDoctor, setSelectedDoctor] = React.useState([])
    let tempBill = 0;
    const [pageLoaded, setPageLoaded] = React.useState(false);
    const [sortType,setSortType]=React.useState(1);
    useEffect(() => {
        if (pageLoaded) return;
        setPageLoaded(true);

        fetchData();

    }, []);

    async function fetchData() {

        const d = await getAllDoctors();

        if (!d.success) {
            if (d.message.error.indexOf('Token') !== -1) props.tokenExpired();
            props.openSnack(d.message.error, 'error');
            return;
        }
        setDoctors(d.data);

        const tt = await getAllTreatmentTypes();

        if (!tt.success) {
            if (tt.message.error.indexOf('Token') !== -1) props.tokenExpired();
            props.openSnack(tt.message.error, 'error');
            return;
        }

        setTreatmentTypes(tt.data);

        const tp = await getAllTreatmentPrices();

        if (!tp.success) {
            if (tp.message.error.indexOf('Token') !== -1) props.tokenExpired();
            props.openSnack(tp.message.error, 'error');
            return;
        }

        setTreatmentPrices(tp.data.reverse());

        const p = await getAllPatients();

        if (!p.success) {
            if (p.message.error.indexOf('Token') !== -1) props.tokenExpired();
            props.openSnack(p.message.error, 'error');
            return;
        }

        setPatients(p.data);

        const ppds = await getPrepaids();

        if (!ppds.success) {
            if (ppds.message.error.indexOf('Token') !== -1) props.tokenExpired();
            props.openSnack(ppds.message.error, 'error');
            return;
        }

        setPrepaids(ppds.data);
    }

    async function updateData(from, to, doctors) {

        const t = await getBilling(from, to);

        if (!t.success) {
            if (t.message.error && t.message.error.indexOf('Token') !== -1) props.tokenExpired();
            props.openSnack(t.message.error, 'error');
            return;
        }

        t.data.forEach(element => {

            const doctor = doctors.filter(it => {
                return it._id === element.doctor
            })[0];

            if (doctor)
                element.doctor = { _id: doctor._id, fullname: doctor.fullname };
        });

        const data = t.data.filter(treatment => {
            const patient = patients.filter(pat => pat._id === treatment.patient)[0];

            return patient;
        }).map(treatment => {
            const patient = patients.filter(pat => pat._id === treatment.patient)[0];

            const prices = treatmentPrices.filter(p => {
                return p && p.prepaid === patient.prepaid && treatment.treatmentType === p.treatmentType && treatment.date > p.from
            });

            if (treatment.treatmentType === '5dfadf0aea17f4238c2459e9' && patient.prepaid === '5dfa969eea17f4238c24599c') {
                //console.log(prices);
            }

            const price = prices[0];

            if (!price)
                treatment.price = 0;
            else
                treatment.price = parseInt(price.price);
            return treatment;
        })

        t.data.forEach(element => {

            const treatment = treatmentTypes.filter(it => {
                return it._id === element.treatmentType
            })[0];

            if (treatment)
                element.treatmentType = { _id: treatment._id, name: treatment.name };
        });

        t.data.forEach(element => {

            const patient = patients.filter(it => {
                return it._id === element.patient
            })[0];

            if (patient)
                element.patient = { _id: patient._id, fullname: patient.fullname, lastname: patient.lastname, prepaid: patient.prepaid, taxed: patient.taxed, prepaidType: patient.prepaidType, prepaidNumber: patient.prepaidNumber };
        });

        t.data.forEach(element => {

            const prepaid = prepaids.filter(it => {
                if (!element.patient)
                    return false;
                return it._id === element.patient.prepaid
            })[0];

            if (prepaid)
                element.prepaid = { _id: prepaid._id, name: prepaid.name, types: prepaid.types };
        });
        //setTreatments(data);
        sortTreatments(data,sortType);
    }
 

function sortTreatments(data,sortOption) {
    
    let treat={};
     switch (sortOption){
        case 1: 
            treat=data.sort((a,b)=>{return a.date-b.date;});
            break;
        case 2:
            const rd=24*60*60*1000;
            treat=data.sort((a,b)=>{
                const da=Math.floor(a.date/rd)*rd;
                const db=Math.floor(b.date/rd)*rd;
                if   (da>db){
                    return 1;
                } else if ( da===db){
                    if   (a.doctor.fullname>b.doctor.fullname){
                        return 1;
                    } else if (a.doctor.fullname===b.doctor.fullname){
                        return 0;
                    } else {
                        return -1;
                    }
                } else {
                    return -1;
                }
            });
            break;
        case 3:
            treat=data.sort((a,b)=>{
            if   (a.doctor.fullname>b.doctor.fullname){
                return 1;
            } else if (a.doctor.fullname===b.doctor.fullname){
                return a.date-b.date;
            } else {
                return -1;
            }
            });  
            break;
        default: 
            break;
        }
    setTreatments(treat);      
}

const  handleSortChange= ()=>{
    const sortOption=parseInt(document.getElementById("sortOption").value);
    setSortType(sortOption);
    sortTreatments(treatments,sortOption);
}

    async function handleDateFromChange(fromDate) {
        setSelectedFromDate(fromDate);
        await updateData(fromDate, selectedDateTo, doctors);
    }

    async function handleDateToChange(toDate) {
        setSelectedToDate(toDate);
        await updateData(selectedDateFrom, toDate, doctors);
    }

    function handleDoctorFilter(doctor) {
        setSelectedDoctor(doctor);
        tempBill = 0;
    }

    function handleTreatmentFilter(treatment) {
        setSelectedTreatment(treatment);
        tempBill = 0;
    }

    function handlePatientFilter(patient) {
        setSelectedPatient(patient);
        tempBill = 0;
    }

    function handlePrepaidFilter(prepaid) {
        setSelectedPrepaid(prepaid);
        tempBill = 0;
    }

    function filterItem(item) {
        if (!item)
            return false;

        if (selectedTreatment && selectedTreatment.length > 0 && selectedTreatment.filter(it => it.value === item.treatmentType._id).length === 0) {
            return false;
        }

        if (selectedDoctor && selectedDoctor.length > 0 && selectedDoctor.filter(it => it.value === item.doctor._id).length === 0) {
            return false;
        }

        if (selectedPrepaid && selectedPrepaid.length > 0 && selectedPrepaid.filter(it => it.value === item.prepaid._id).length === 0) {
            return false;
        }

        if (selectedPatient && selectedPatient.length > 0 && selectedPatient.filter(it => it.value === item.patient._id).length === 0) {
            return false;
        }

        tempBill += item.price;
        return true;
    }

    const componentRef = useRef();

    return (
        <Grid container spacing={3}>
            {!mobileCheck() && <Grid item xs={1} className={classes.grid} />}
            <Grid item xs={!mobileCheck() ? 10 : 12} className={classes.grid}>
                <Paper className={classes.paper}>
                    <Grid container>
                        <Grid item xs={5} className={classes.grid}>
                            <Typography noWrap className={classes.typography}>
                                Desde
                            </Typography>
                        </Grid>
                        <Grid item xs={5} className={classes.grid}>
                            <Typography noWrap className={classes.typography}>
                                Hasta
                            </Typography>
                        </Grid>
                        <Grid item xs={5} className={classes.grid}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    label="Desde"
                                    disableToolbar
                                    variant='static'
                                    autoOk
                                    orientation="landscape"
                                    openTo="date"
                                    value={selectedDateFrom}
                                    onChange={handleDateFromChange}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={5} className={classes.grid}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    label="Hasta"
                                    disableToolbar
                                    variant='static'
                                    autoOk
                                    orientation="landscape"
                                    openTo="date"
                                    value={selectedDateTo}
                                    onChange={handleDateToChange}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>
                </Paper>
                <br />
                <div ref={componentRef}>
                    <Grid item className={classes.grid}>
                        <Typography variant="h6" noWrap className={classes.typography}>
                            Filtros
                        </Typography>
                    </Grid>
                    <Grid item className={classes.grid}>
                        <IconButton color="primary" onClick={async () => await updateData(selectedDateFrom, selectedDateTo, doctors)}>
                            <Search />
                        </IconButton>
                    </Grid>
                    <br />
                    <Paper className={classes.paper}>
                        <Grid item xs={3} className={classes.grid}>
                            <TextField
                                className={classes.textField}
                                InputProps={{
                                    readOnly: true,
                                }}
                                label={'Desde'}
                                name={'from'}
                                value={dateToString(selectedDateFrom)}
                                InputLabelProps={{
                                    shrink: true,
                                }} />
                        </Grid>
                        <Grid item xs={3} className={classes.grid}>
                            <TextField
                                className={classes.textField}
                                InputProps={{
                                    readOnly: true,
                                }}
                                label={'Hasta'}
                                name={'to'}
                                value={dateToString(selectedDateTo)}
                                InputLabelProps={{
                                    shrink: true,
                                }} />
                        </Grid>
                        <Grid item xs={12} className={classes.grid}>
                            <PredictiveComboBox
                                reduce={false}
                                label={"Doctor"}
                                suggestions={doctors.map((it, ix) => {
                                    return {
                                        key: ix,
                                        label: it.fullname,
                                        value: it._id
                                    }
                                })}
                                multi={true}
                                value={selectedDoctor}
                                changecallback={(v) => { handleDoctorFilter(v); }}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.grid}>
                            <PredictiveComboBox
                                reduce={false}
                                label={"Estudios"}
                                suggestions={treatmentTypes.map((it, ix) => {
                                    return {
                                        key: ix,
                                        label: it.name,
                                        value: it._id
                                    }
                                })}
                                multi={true}
                                value={selectedTreatment}
                                changecallback={(v) => { handleTreatmentFilter(v); }}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.grid}>
                            <PredictiveComboBox
                                reduce={false}
                                label={"Prepagas"}
                                suggestions={prepaids.map((it, ix) => {
                                    return {
                                        key: ix,
                                        label: it.name,
                                        value: it._id
                                    }
                                })}
                                multi={true}
                                value={selectedPrepaid}
                                changecallback={(v) => { handlePrepaidFilter(v); }}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.grid}>
                            <PredictiveComboBox
                                label={"Pacientes"}
                                suggestions={patients.map((it, ix) => {
                                    return {
                                        key: ix,
                                        label: it.fullname,
                                        value: it._id
                                    }
                                })}
                                multi={true}
                                value={selectedPatient}
                                changecallback={(v) => { handlePatientFilter(v); }}
                            />
                        </Grid>
                    </Paper>
                    <br />

                    <Grid item className={classes.grid}>
                        <Typography variant="h6" noWrap className={classes.typography}>
                            Resumen
                        </Typography>
                    </Grid>
                    <Grid item className={classes.grid} style={{paddingLeft:"1%"}}>
                        <FormControl>
                            <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                Ordenar por
                            </InputLabel>
                            <NativeSelect defaultValue={1} id="sortOption"onChange={handleSortChange}>
                                <option value={1}>Fecha</option>
                                <option value={2}>Fecha - Doctor</option>
                                <option value={3}>Doctor - Fecha</option>
                            </NativeSelect>
                        </FormControl>
                    </Grid>
                    <Paper className={classes.paper}>
                        <ExcelFile element={<Button>EXPORTAR A EXCEL</Button>}>
                            <ExcelSheet data={treatments.filter(x => x.treatmentType !== undefined && treatmentTypes.find(y => y._id === x.treatmentType._id)).map(x => {

                                let rdate = new Date(x.date);
                                let date = `${rdate.getDate()}/${rdate.getMonth() + 1}/${rdate.getYear() + 1900}`;

                                if (!treatmentTypes.find(y => y._id === x.treatmentType._id)) {
                                    //console.log(x);
                                    //console.log(x.treatmentType._id);
                                }

                                return {
                                    date: date,
                                    doctor: x.doctor.fullname,
                                    patientFirstName: x.patient.fullname,
                                    patientLastName: x.patient.lastname !== undefined ? x.patient.lastname : "",
                                    prepaidNumber: x.patient.prepaidNumber,
                                    prepaid: x.prepaid === undefined ? "" : x.prepaid.name,
                                    prepaidType: x.prepaid === undefined ? "" : x.prepaid.types[x.patient.prepaidType],
                                    taxed: x.patient.taxed ? "Si" : "No",
                                    title: x.title,
                                    treatmentCode: treatmentTypes.find(y => y._id === x.treatmentType._id).code,
                                    smgCode:treatmentTypes.find(y => y._id === x.treatmentType._id).affects,
                                    price: x.price
                                }
                            })} name="Employees">
                                <ExcelColumn label="Fecha" value="date" />
                                <ExcelColumn label="Doctor" value="doctor" />
                                <ExcelColumn label="Nombre Paciente" value="patientFirstName" />
                                <ExcelColumn label="Apellido Paciente" value="patientLastName" />
                                <ExcelColumn label="Nro Socio" value="prepaidNumber" />
                                <ExcelColumn label="Prepaga" value="prepaid" />
                                <ExcelColumn label="Plan" value="prepaidType" />
                                <ExcelColumn label="Grabado" value="taxed" />
                                <ExcelColumn label="Estudio" value="title" />
                                <ExcelColumn label="Codigo Estudio" value="treatmentCode" />
                                <ExcelColumn label="Codigo SMG" value="smgCode" />
                                <ExcelColumn label="Monto" value="price" />
                            </ExcelSheet>
                        </ExcelFile>
                        <List
                            component="nav"
                            aria-labelledby="nested-list-subheader"
                            subheader={false &&
                                <ListSubheader component="div" id="nested-list-subheader">
                                    Estudios
                            </ListSubheader>
                            }
                            className={classes.list}
                        >
                            <ListItem>
                                <ListItemText className={classes.listItemText} primary="Fecha" />
                                <ListItemText className={classes.listItemText} primary="Doctor" />
                                <ListItemText className={classes.listItemText} primary="Paciente" />
                                <ListItemText className={classes.listItemText} primary="Paciente" />
                                <ListItemText className={classes.listItemText} primary="Estudio" />
                                <ListItemText className={classes.listItemText} primary="Prepaga" />
                                <ListItemText className={classes.listItemText} primary="Grabado" />
                                <ListItemText className={classes.listItemText} primary="Precio" />
                                <ExpandLess className={classes.hidden} />
                            </ListItem>
                            {treatments.filter(it => filterItem(it)).map((it, ix) => <div key={ix}><Divider /><BillingListItem treatment={it} /></div>)}
                            <Divider />
                            <ListItem>
                                <ListItemText className={classes.listItemText} primary="" />
                                <ListItemText className={classes.listItemText} primary="" />
                                <ListItemText className={classes.listItemTextRight} primary="Total:" />
                                <ListItemText className={classes.listItemText} primary={`$${tempBill}`} />
                                <ExpandLess className={classes.hidden} />
                            </ListItem>
                        </List>
                    </Paper>
                </div>
            </Grid>
        </Grid >
    );
}

/* eslint-enable*/