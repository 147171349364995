import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Container,
  Grid,
  TextField,
  IconButton,
  Typography,
  Select,
  MenuItem,
  Input,
  InputLabel,
  Tooltip,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Button,
} from '@material-ui/core/';
import { getPatient, createPatient, modifyPatient, deletePatient } from '../actions/patients';
import { Edit, Delete, Done, Close, Add } from '@material-ui/icons';
import { getPrepaids } from '../actions/prepaids';
import { isAdmin, isSecretary, isDoctor } from '../utils/authUtils';
import { mobileCheck } from '../utils/mobileCheck';
import { getMedicalExamsByPatientId } from '../actions/medicalExams';
import PatientMedicalExamListView from './PatientMedicalExamListView';
import { capitalize } from './../utils/stringUtils';
const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  paper: {
    padding: theme.spacing(2),
  },
  container: {
    padding: theme.spacing(mobileCheck() ? 0 : 4),
  },
  grid: {
    margin: '10px',
  },
  textField: {
    width: '100%',
  },
  button: {
    margin: theme.spacing(1),
  },
  typography: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    margin: theme.spacing(1),
  },
    progress: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export default function PatientPopup(props) {
  const classes = useStyles();

  const [originalObjectData, setOriginalObjectData] = React.useState({
    fullname: '',
    lastname: '',
    iud: '',
    mail: '',
    prepaid: '',
    prepaidType: 0,
    prepaidNumber: '',
    assignedDoctor: '',
    taxed: false,
    treatments: [],
  });

  const [objectData, setObjectData] = React.useState({
    fullname: '',
    lastname: '',
    uid: '',
    mail: '',
    prepaid: '',
    prepaidType: 0,
    prepaidNumber: '',
    assignedDoctor: '',
    phone: '',
    taxed: false,
    treatments: [],
  });


  const [prepaids, setPrepaids] = React.useState([]);

  const [prepaidTypes, setPrepaidTypes] = React.useState([]);

  const [prepaid, setPrepaid] = React.useState([]);

  const [loading, setLoading] = React.useState(true);

  const [pageLoaded, setPageLoaded] = React.useState(false);

  const [medicalExams, setMedicalExams] = React.useState([]);

  const [state, setState] = React.useState({
    loaded: false,
    create: false,
    edit: false,
    addNewTreatment: false,
    dialogOpened: false,
  });

  /* eslint-disable*/
  useEffect(() => {
    if (pageLoaded)
      return;

    setPageLoaded(true);

    fetchData(props.patientId);
  }, [props.patientId, state.create, state.edit, state.loaded]);
  /* eslint-enable*/

  async function fetchData(id) {

    const prepaidsData = await getPrepaids();

    if (!prepaidsData.success) {
      if (prepaidsData.message.error.indexOf('Token') !== -1) props.tokenExpired();
      props.openSnack(prepaidsData.message.error, 'error');
      return;
    }

    setPrepaids(prepaidsData.data);

    if (id === undefined) {
      setState({ ...state, create: true, edit: true, loaded: true });
      setLoading(false);
      return;
    }

    const patientData = await getPatient(id);

    if (!patientData.success) {
      if (patientData.message.error.indexOf('Token') !== -1) props.tokenExpired();
      props.openSnack(patientData.message.error, 'error');
      return;
    }


    /* eslint-disable*/
    if (patientData.data.phone == undefined)
      patientData.data.phone = '';
    /* eslint-disable*/

    setObjectData(patientData.data);
    setOriginalObjectData(patientData.data);

    if (!patientData.data.prepaid) return;

    for (let i = 0; i < prepaidsData.data.length; i++) {
      const element = prepaidsData.data[i];
      if (element._id !== patientData.data.prepaid) continue;
      setPrepaid(element);
      setPrepaidTypes(element.types);
    }



    const medicalExamsData = await getMedicalExamsByPatientId(id);

    if (!medicalExamsData.success) {
      if (medicalExamsData.message.error.indexOf('Token') !== -1) props.tokenExpired();
      props.openSnack(medicalExamsData.message.error, 'error');
      return;
    }

    setMedicalExams(medicalExamsData.data.reverse());

    setLoading(false);
  }

  function handleEdit() {
    setState({ ...state, edit: true });
  }

  function handleCancelEdit() {
    if (state.create) {
      props.history.push('/patients');
      return;
    }

    setObjectData({ ...originalObjectData });
    setState({ ...state, edit: false });
  }

  function isValid(data) {

    //console.log(data);
    if (data === undefined) {
      return false;
    }

    try {
      if (data.trim() === "")
        return false;
    }
    catch (err) { }

    if (data < 0) {
      return false;
    }

    return true;
  }

  async function handleDoneEdit() {
    if (!isValid(objectData.fullname) ||
      !isValid(objectData.lastname) ||
      !isValid(objectData.uid) ||
      !isValid(objectData.mail) ||
      !isValid(objectData.prepaid) ||
      !isValid(objectData.prepaidType) ||
      !isValid(objectData.prepaidNumber) ||
      !isValid(objectData.phone)) {

      props.openDialog(
        'Datos inválidos',
        'Debe completar todos los datos para poder continuar.'
      ); return;
    }

    let result = {};
    if (state.create) {
      result = await createPatient({ ...objectData, lastname: objectData.lastname.trim(), fullname: objectData.fullname.trim() });
      props.history && props.history.push('/patient/' + result.data[0]._id);
      props.onPatient && props.onPatient(result.data[0]);
    } else result = await modifyPatient(objectData);
    setOriginalObjectData({ ...result.data });
    setState({ ...state, edit: false, create: false });
  }

  function handleAddNewTreatment(treatment) {

    if (!isDoctor())
      return;

    if (treatment._id)
      props.history.push(`/medicalExam/patient/${objectData._id}/treatment/${treatment._id}`);
    else props.history.push(`/medicalExam/patient/${objectData._id}`);
  }

  function handleDeletePatient() {
    if (!state.create)
      props.openDialog(
        'Confirmación',
        'Seguro que desea eliminar este paciente?',
        objectData,
        handleDeleteCallback,
      );
    else props.history.push('/patients');
  }

  async function handleDeleteCallback(confirmed, item) {
    if (!confirmed) return;
    const result = await deletePatient(item._id);
    if (!result.success) {
      if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
      props.openSnack(result.message.error, 'error');
      return;
    }
    props.history.push('/patients');
  }

  function handleChangeSelect(event) {
    if (event.target.name === 'prepaid') {

      setPrepaidTypes(event.target.value.types);
      setPrepaid(event.target.value);
      setObjectData({
        ...objectData,
        prepaid: event.target.value._id,
      });
    } else {
      setObjectData({
        ...objectData,
        prepaidType: event.target.value,
      });
    }
  }

  function handleChange(event) {
    setObjectData({
      ...objectData,
      [event.target.name]: event.target.value,
    });
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Paper>
        <IconButton key="close" aria-label="close" color="inherit" onClick={() => { props.onClose() }}>
          <Close className={classes.icon} />
        </IconButton>
        <Grid container spacing={3}>,
          {!mobileCheck() && <Grid item xs={1} className={classes.grid} />}

          {loading ?
            <Grid item xs={12} className={classes.grid}>
              <CircularProgress className={classes.progress} />
            </Grid>
            :
            <Grid item xs={!mobileCheck() ? 10 : 12} className={classes.grid}>
              <Grid container>
                <Grid item className={classes.grid}>
                  <Typography variant="h6" noWrap className={classes.typography}>
                    Paciente
                  </Typography>
                </Grid><Grid item className={classes.grid}>
                  {(isAdmin() || isSecretary() || isDoctor()) && !state.edit && (
                    <Tooltip title="Editar">
                      <IconButton onClick={handleEdit}>
                        <Edit />
                      </IconButton>
                    </Tooltip>
                  )}

                  {isDoctor() && !state.edit && objectData._id &&
                    <Button color="inherit" onClick={handleAddNewTreatment}>
                      Nuevo informe
                      <Add />
                    </Button>}
                  {state.edit && (
                    <Tooltip title="Cancelar">
                      <IconButton onClick={handleCancelEdit}>
                        <Close />
                      </IconButton>
                    </Tooltip>
                  )}
                  {state.edit && (
                    <Tooltip title="Aceptar">
                      <IconButton onClick={handleDoneEdit}>
                        <Done color="primary" />
                      </IconButton>
                    </Tooltip>
                  )}
                  {isAdmin() && state.edit && (
                    <Tooltip title="Eliminar">
                      <IconButton onClick={handleDeletePatient}>
                        <Delete color="error" />
                      </IconButton>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={8} className={classes.grid}>
                  <TextField
                    InputProps={{
                      readOnly: !state.edit,
                    }}
                    label={'Nombre'}
                    className={classes.textField}
                    value={capitalize(objectData.fullname)}
                    name={'fullname'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={e => {
                      if (!state.edit) return;
                      handleChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={8} className={classes.grid}>
                  <TextField
                    InputProps={{
                      readOnly: !state.edit,
                    }}
                    label={'Apellido'}
                    className={classes.textField}
                    value={capitalize(objectData.lastname)}
                    name={'lastname'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={e => {
                      if (!state.edit) return;
                      handleChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={8} className={classes.grid}>
                  <TextField
                    InputProps={{
                      readOnly: !state.edit,
                    }}
                    label={'Documento'}
                    className={classes.textField}
                    value={objectData.uid}
                    name={'uid'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={e => {
                      if (!state.edit) return;
                      handleChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={8} className={classes.grid}>
                  <TextField
                    InputProps={{
                      readOnly: !state.edit,
                    }}
                    label={'Telefono'}
                    className={classes.textField}
                    value={objectData.phone}
                    name={'phone'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={e => {
                      if (!state.edit) return;
                      handleChange(e);
                    }}
                  />
                </Grid>
                <Grid item xs={8} className={classes.grid}>
                  <TextField
                    InputProps={{
                      readOnly: !state.edit,
                    }}
                    label={'Mail'}
                    className={classes.textField}
                    value={objectData.mail ? objectData.mail : ''}
                    name={'mail'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={e => {
                      if (!state.edit) return;
                      handleChange(e);
                    }}
                  />
                </Grid>
                <Grid container>
                  <Grid item xs={4} className={classes.grid}>
                    <InputLabel shrink htmlFor="prepaid-label-placeholder">
                      Prepaga
                    </InputLabel>
                    <Select
                      value={prepaid}
                      input={
                        <Input
                          name="prepaid"
                          id="prepaid-label-placeholder"
                          readOnly={!state.edit}
                        />
                      }
                      displayEmpty
                      name="prepaid"
                      className={classes.textField}
                      onChange={e => {
                        if (!state.edit) return;
                        handleChangeSelect(e);
                      }}
                    >
                      <MenuItem key={0} value="">
                        <em>None</em>
                      </MenuItem>
                      {prepaids.map(p => (
                        <MenuItem key={p._id} value={p}>
                          {p.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Grid>
                  <Grid item xs={3} className={classes.grid}>
                    <InputLabel shrink htmlFor="prepaidType-label-placeholder">
                      Plan
                    </InputLabel>
                    <Select
                      value={objectData.prepaidType}
                      input={
                        <Input
                          name="prepaidType"
                          id="prepaidType-label-placeholder"
                          readOnly={!state.edit}
                        />
                      }
                      displayEmpty
                      name="prepaidType"
                      className={classes.textField}
                      onChange={e => {
                        if (!state.edit) return;
                        handleChangeSelect(e);
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {prepaidTypes &&
                        prepaidTypes.filter(p => p !== "").map((p, i) => (
                          <MenuItem key={i} value={i}>
                            {p}
                          </MenuItem>
                        ))}
                    </Select>
                  </Grid>
                  <Grid item xs={8} className={classes.grid}>
                    <TextField
                      InputProps={{
                        readOnly: !state.edit,
                      }}
                      label={'Nro Socio'}
                      className={classes.textField}
                      value={objectData.prepaidNumber}
                      name={'prepaidNumber'}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={e => {
                        if (!state.edit) return;
                        handleChange(e);
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} className={classes.grid}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={objectData.taxed}
                          onChange={() => {
                            if (!state.edit) return;
                            setObjectData({ ...objectData, taxed: !objectData.taxed });
                          }}
                        />
                      }
                      label={'Grabado'}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={8} className={classes.grid}>
                  <TextField
                    InputProps={{
                      readOnly: !state.edit,
                    }}
                    label={'Notas'}
                    className={classes.textField}
                    value={objectData.notes ? objectData.notes : ''}
                    name={'notes'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={e => {
                      if (!state.edit) return;
                      handleChange(e);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          }</Grid>
      </Paper>
      <br />
      <br />
      {!state.create && medicalExams && medicalExams.length > 0 && <Grid container>
        <Grid item xs={12}>
          <Paper>
            <Typography variant="h6" noWrap className={classes.typography}>
              Estudios
            </Typography>
            {isDoctor() && (
              <Tooltip title="Agregar nuevo estudio">
                <IconButton onClick={handleAddNewTreatment}>
                  <Add />
                </IconButton>
              </Tooltip>
            )}
          </Paper>
        </Grid>
      </Grid>}
      {medicalExams &&
        medicalExams.map((item, index) => {
          return <PatientMedicalExamListView
            key={index}
            medicalExam={item}
            patient={objectData}
            openSnack={props.openSnack}
            detailHandle={() => props.viewDetails && props.viewDetails(item)}
          />
        }
        )}
      <br />

      <br />

      <br />

      <br />

      <br />

    </Container>
  );
}
