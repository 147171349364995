import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Container, Grid, TextField, IconButton, Typography, Tooltip } from '@material-ui/core/';
import AppContainer from './AppContainer';
import { createTreatmentType } from '../actions/treatmentTypes';
import { Done, Close } from '@material-ui/icons';
import { mobileCheck } from '../utils/mobileCheck';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    paper: {
        padding: theme.spacing(2),
    },
    container: {
        padding: theme.spacing(mobileCheck() ? 0 : 4),
    },
    grid: {
        margin: '10px',
    },
    textField: {
        width: '100%',
    },
    button: {
        margin: theme.spacing(1),
    },
    typography: {
        margin: theme.spacing(1),
    },
}));

export default function TreatmentNew(props) {
    const classes = useStyles();

    const [objectData, setObjectData] = React.useState({
        name: '',
        detail: '',
        affects: '',
        code: '',
        pre: '',
    });

    const [loaded, setLoaded] = React.useState(true);

    /* eslint-disable*/
    useEffect(() => {
        if (loaded) return;
        setLoaded(true);
    }, [loaded]);
    /* eslint-enable*/

    function handleCancelEdit() {
        props.history.push('/treatments');
    }

    async function handleDoneEdit() {
        const result = await createTreatmentType(objectData);

        if (!result.success) {
            if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
            props.openSnack(result.message.error, 'error');
            return;
        }

        props.history.push('/treatment/' + result.data[0]._id);
    }

    function handleChange(event) {
        setObjectData({
            ...objectData,
            [event.target.name]: event.target.value,
        });
    }

    return (
        <div>
            <AppContainer title={"Estudio"} history={props.history} content={
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        {!mobileCheck() && <Grid item xs={1} className={classes.grid} />}
                        <Grid item xs={!mobileCheck() ? 10 : 12} className={classes.grid}>
                            <Grid container>
                                <Grid item className={classes.grid}>
                                    <Typography variant="h6" noWrap className={classes.typography}>
                                        Información
                                </Typography>
                                </Grid>
                                <Grid item className={classes.grid}>
                                    <Tooltip title="Cancelar"><IconButton onClick={handleCancelEdit}><Close /></IconButton></Tooltip>
                                    <Tooltip title="Aceptar"><IconButton onClick={handleDoneEdit}><Done color="primary" /></IconButton></Tooltip>
                                </Grid>
                            </Grid>
                            <Paper className={classes.paper}>
                                <Grid container>
                                    <Grid item xs={8} className={classes.grid}>
                                        <TextField
                                            label={'Nombre'}
                                            className={classes.textField}
                                            value={objectData.name}
                                            name={'name'}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={e => {

                                                handleChange(e);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={8} className={classes.grid}>
                                        <TextField
                                            label={'Detalle'}
                                            className={classes.textField}
                                            value={objectData.detail}
                                            name={'detail'}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={e => {

                                                handleChange(e);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={8} className={classes.grid}>
                                        <TextField
                                            label={'Código SMG'}
                                            className={classes.textField}
                                            value={objectData.affects}
                                            name={'affects'}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={e => {

                                                handleChange(e);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={8} className={classes.grid}>
                                        <TextField
                                            label={'Codigo'}
                                            className={classes.textField}
                                            value={objectData.code}
                                            name={'code'}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={e => {

                                                handleChange(e);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={8} className={classes.grid}>
                                        <TextField
                                            label={'Preparacion'}
                                            className={classes.textField}
                                            value={objectData.pre}
                                            name={'pre'}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={e => {

                                                handleChange(e);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </ Container>
            } />
        </div>
    );
}
