import axios from 'axios';

export const getPrepaids = async () => {
  try {
    const res = await axios.get('/prepaids');
    const r = { success: true, message: '', data: res.data };
    return r;
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const getPrepaid = async id => {
  try {
    const res = await axios.get(`/prepaids/${id}`);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const createPrepaid = async prepaid => {
  try {
    const res = await axios.post(`/prepaids/`, prepaid);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const modifyPrepaid = async prepaid => {
  try {
    const res = await axios.put(`/prepaids/`, prepaid);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const deletePrepaid = async id => {
  try {
    const res = await axios.delete(`/prepaids/${id}`);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};
