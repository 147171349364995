import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, ListItemText, ListItem, Tooltip } from '@material-ui/core';
import { mobileCheck } from '../utils/mobileCheck';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    paper: {
        padding: theme.spacing(2),
    },
    container: {
        padding: theme.spacing(mobileCheck() ? 0 : 4),
    },
    grid: {
        margin: '10px',
    },
    textField: {
        width: '100%',
    },
    listItemTextCode: {
        width: '30%',
    },
    listItemText: {
        width: '90%',
    },
    button: {
        margin: theme.spacing(1),
    },
}));

export default function TreatmentCodeListItem(props) {
    const classes = useStyles();
    const [textValue] = React.useState(props.text);

    return (
        <div>
            <ListItem button onClick={() => props.onclick ? props.onclick() : {}}>
                <ListItemText className={classes.listItemTextCode} primary={props.item.code} />
                <ListItemText className={classes.listItemText} primary={props.item.name} />
                {props.icons &&
                    props.icons.map(
                        (icon, index) => {
                            return (icon &&
                                <Tooltip
                                    key={index}
                                    title={icon.tooltip}>
                                    <IconButton
                                        onClick={() => {
                                            if (props.editable)
                                                icon.callback(textValue);
                                            else
                                                icon.callback(props.item);
                                        }}>
                                        {icon.icon}
                                    </IconButton>
                                </Tooltip>
                            )
                        }
                    )}
            </ListItem>
        </div >
    );
}
