import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ListItemText, ListItem, Checkbox } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    title: {
    },
}));

export default function DoctorPaymentPerTreatmentTitle(props) {
    const classes = useStyles();

    return (
        <div>
            <ListItem button onClick={() => props.toggle ? props.toggle(props.index) : {}}>
                {props.editable && <Checkbox checked={props.enabled} onChange={props.statusChanged} />}
                <ListItemText className={classes.title} primary={props.treatment.name} />
            </ListItem>
        </div>
    );
}
