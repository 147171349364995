export default function updateColorStatus(futurePrices) {
  return futurePrices.map((price) => ({
    ...price,
    color: setColor(price, futurePrices),
  }));
  function setColor(price, futurePrices) {
    if (price.status === "original") return "black";
    const dateNotOkFinder = futurePrices.find(
      (futurePrice) =>
        futurePrice.from > price.from &&
        futurePrice.status !== "deleted" &&
        futurePrice.percentageAdj
    );
    if (dateNotOkFinder) return "red";
    return "green";
  }
}
