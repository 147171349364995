import React, { useEffect, useState } from 'react';
import {getAllHolidays} from '../actions/holidays';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Container,
  Grid,
  Typography,
  IconButton,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogContent,
  CircularProgress,
  List,
  Tooltip,
  Modal,
  Button,
} from '@material-ui/core/';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AppContainer from './AppContainer';
import ScheduleListItem from './ScheduleListItem';
import { Add, ArrowForward, Close, Person } from '@material-ui/icons';
import { getSchedule, createTurn, modifyTurn, deleteTurn } from '../actions/schedules';
import TurnDialog from './TurnDialog';
import { getDoctor, getAllDoctors } from '../actions/doctors';
import { mergeSchedule } from '../utils/unfilteredschedule';
import PredictiveComboBox from './PredictiveText';
import { isSecretary, isDoctor, isAdmin } from '../utils/authUtils';
import { mobileCheck } from '../utils/mobileCheck';
import { useInterval } from '../utils/setInterval';
import PatientPopup from './PatientPopup';
import { createAndDownloadPDFForSchedule } from '../utils/pdf';
import { dateToString4File } from '../utils/dateToString';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  paper: {
    padding: theme.spacing(2),
  },
  container: {
    padding: theme.spacing(mobileCheck() ? 0 : 4),
  },
  grid: {
    margin: '10px',
  },
 
  textField: {
    width: '100%',
  },
  button: {
    margin: theme.spacing(1),
  },
  typography: {
    margin: theme.spacing(1),
  },
  avmessage: {
    width: '100%',
    textAlign: 'center',
  },
  progress: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export default function ScheduleSecretary(props) {
  const classes = useStyles();

  const [state, setState] = React.useState({
    edit: false,
    bigDate: true,
    loaded: false,
    displayAll: true,
    turnDialog: false,
    turnDialogTurn: {},
  });

  const [patientModalShow, setPatientModalShow] = useState(false);

  const [patientId, setPatientId] = useState(undefined);

  const [loading, setLoading] = useState(false);

  const [schedule, setSchedule] = useState([]);

  const [doctors, setDoctors] = useState([]);
  const [holidays,setHolidays]=useState([]);
  const [calendarDate, setCalendarDate] = useState(getCurrentDate());

  const maxTimeLeft = 60;

  const [timeLeft, setTimeLeft] = useState(maxTimeLeft);

  const allDocOption = { label: 'Todos', value: "-1", _id: "-1", fullname: 'Todos' };

  const [pageLoaded, setPageLoaded] = useState(false);

  useInterval(async () => {

    if (timeLeft > 0) {
      setTimeLeft(timeLeft - 1);
      return;
    }

    setTimeLeft(maxTimeLeft);

    await fetchDataNoLoad();
    setLoading(false);
  }, 1000);

  useEffect(() => {
    if (pageLoaded) return;
    setPageLoaded(true);
    fetchHolidays();
    fetchData();
    // eslint-disable-next-line
  }, []);

  async function fetchData() {
    setLoading(true);
    await fetchDataNoLoad();
    setLoading(false);
  }

  function getDoctorInfo() {


    if (doctors === undefined || doctors.length === 0)
      return undefined;

    if (selectedDoctorId() === undefined)
      return allDocOption;

    const doc = doctors.filter(x => x._id.toString() === selectedDoctorId().toString())[0];

    if (doc === undefined)
      return allDocOption;

    return doc;
  }

  function selectedDoctorId() {
    return props.match.params.doctor;
  }

  function isDoctorSelected() {
    return props.match.params.doctor !== undefined && props.match.params.doctor.toString() !== "-1";
  }

  async function fetchDataNoLoad() {
  
    let date = getCurrentDate();
    const allDocs = await initDoctors();

    if (!isDoctorSelected()) {

      const mergedSch = await getAllDoctorsSchedules(allDocs, date);

      setSchedule(mergedSch);
    }
    else {
      const mergedSch = await getDoctorSchedule(selectedDoctorId(), date);

      setSchedule(mergedSch);
    }
    
  }

  function getCurrentDate() {
    return props.match.params.dateYear
      ? new Date(
        props.match.params.dateYear,
        props.match.params.dateMonth,
        props.match.params.dateDay,
      )
      : new Date();
  }

  async function getAllDoctorsSchedules(allDocs, date) {

    let allSchedules = [];

    if (allDocs === undefined)
      return;

    for (let i = 0; i < allDocs.length; i++) {
      const ds = await getDoctorSchedule(allDocs[i]._id, date);

      for (let j = 0; j < ds.length; j++) {
        ds[j].doctor = allDocs[i];
      }

      allSchedules = [...allSchedules, ...ds];
    }

    allSchedules.sort((a, b) => {


      let dateA = a.date ?? new Date(a.dateYear, a.dateMonth, a.dateDay, a.dateHour, a.dateMinute);
      let dateB = b.date ?? new Date(b.dateYear, b.dateMonth, b.dateDay, b.dateHour, b.dateMinute);

      if (dateA.getTime() < dateB.getTime()) {
        return -1;
      }
      if (dateB.getTime() < dateA.getTime()) {
        return 1;
      }
      return 0;
    })

    return allSchedules;
  }

  async function getDoctorSchedule(id, date) {
    const doc = await getDoctor(id);

    if (!doc.success) {
      if (doc.message.error.indexOf('Token') !== -1) props.tokenExpired();
      props.openSnack(doc.message.error, 'error');
      return;
    }

    const turns = await getSchedule(id, date);

    if (!turns.success) {
      props.openSnack(turns.message.error, 'error');
      return;
    }

    let mergedSch = mergeSchedule(turns.data, doc.data.schedule, date);

    for (let i = 0; i < mergedSch.length; i++) {
      const turn = mergedSch[i];

      if (!turn)
        continue;

      const turnsTaken = turn.durationMinutes / 15

      if (turnsTaken === 1)
        continue;

      for (let j = 0; j < turnsTaken - 1; j++) {

        if (!mergedSch[i + j + 1]) {
          mergedSch[i + j + 1] = null;
        }
        else if (!mergedSch[i + j + 1].patient)
          mergedSch[i + j + 1] = null;
      }

    }

    mergedSch = mergedSch.filter(x => x != null);

    return mergedSch;
  }

  async function initDoctors() {
    const allDocs = await getAllDoctors();

    if (!allDocs.success) {
      try {
        if (allDocs.message.error.indexOf('Token') !== -1) props.tokenExpired();
        props.openSnack(allDocs.message.error, 'error');
      } catch (error) {
        props.tokenExpired();
      }

      return undefined;
    }
    const docOptions = [allDocOption, ...allDocs.data];
    setDoctors(docOptions);
    return allDocs.data;
  }

  function handleGotoNewTurn() {
    props.history.push('/newturnfromscratch/');
  }

  // eslint-disable-next-line
  function handleAdd(emptyTurn) {

    if (emptyTurn !== undefined) {

      props.history.push(
        `/newturn/${emptyTurn.doctor !== undefined ? emptyTurn.doctor._id : selectedDoctorId()}/${getCurrentDate().getYear() +
        1900}/${getCurrentDate().getMonth()}/${getCurrentDate().getDate()}/${emptyTurn.dateHour}/${emptyTurn.dateMinute}/`,
      );
    }
    else {
      props.history.push(
        `/newturn/${selectedDoctorId()}/${getCurrentDate().getYear() +
        1900}/${getCurrentDate().getMonth()}/${getCurrentDate().getDate()}/${"-"}/${"-"}/`,
      );
    }
  }
  function disableWeekends(date) {
    
    if (date.getDay()===0) return true;

let isHoliday=false;
    holidays.forEach((holiday)=>{
      if ((holiday.date-date.getTime())===0) {
        isHoliday= true;
      }
    });
    return isHoliday;
   
  }
  
async function fetchHolidays(){
    const result = await getAllHolidays();
    if (!result.success) {
        if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
        props.openSnack(result.message.error, 'error');
        return;
    }
    setHolidays(result.data);  
}

  function handleDateChange(date) {

    setCalendarDate(date);

    props.history.push(
      `/schedule/${props.match.params.doctor ? `${props.match.params.doctor}/` : ''}${date.getYear() +
      1900}/${date.getMonth()}/${date.getDate()}/`,
    );
    setState({ ...state, bigDate: true });
    setLoading(true);
    setTimeLeft(1);
  }

  function handleDocChange(doc) {

    if (!doc.value) {
      props.history.push(
        `/schedule/${getCurrentDate().getYear() +
        1900}/${getCurrentDate().getMonth()}/${getCurrentDate().getDate()}/`,
      );
    } else {
      props.history.push(
        `/schedule/${doc.value}/${getCurrentDate().getYear() +
        1900}/${getCurrentDate().getMonth()}/${getCurrentDate().getDate()}/`,
      );
    }

    setLoading(true);
    setTimeLeft(1);
  }

  function handleClose() {
    setState({ ...state, turnDialog: false });
  }

  function handleDisplayAllClick() {
    setState({ ...state, displayAll: !state.displayAll });
  }

  async function handleTurn(turn) {

    if (turn) {
      const result = await createTurn(turn);
      if (!result.success) {
        if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
        props.openSnack(result.message.error, 'error');
      } else {
        props.openSnack('Turno creado correctamente', 'success');

        setLoading(true);
        setTimeLeft(1);
      }
    }
    handleClose();
  }


  function handleCancelTurnProcess(turn) {
    props.openDialog("Confirmación", `Esta seguro que desea cancelarel turno?`, turn, handleTurnCancelConfirmation);
  }

  async function handleTurnCancelConfirmation(confirmed, turn) {
    if (!confirmed) {
      props.openSnack('Cancelacion de turno cancelada', 'error');
      return;
    }

    await deleteTurn(turn._id);

    props.openSnack('Turno eliminado correctamente', 'success');

    setLoading(true);
    setTimeLeft(1);
  }

  async function handleTurnProcess(turn) {

    let newStatus;
    let message;
    let arrivalDate;

    switch (turn.status) {
      case 'poratender':
      case 'sobreturno':
        message = "'En sala de espera'";
        newStatus = 'ensaladeespera';
        arrivalDate = {
          dateHour: new Date().getHours(),
          dateMinute: new Date().getMinutes(),
        };
        break;
      case 'ensaladeespera':
        message = "'Atendiendo'";
        newStatus = 'atendiendo';
        break;
      case 'atendiendo':
        message = "'Finalizado'";
        newStatus = 'atendido';
        break;
      case 'atendido':

        break;
      case 'cancelado':

        break;
      default:
        message = "'En sala de espera'";
        newStatus = 'ensaladeespera';
        break;
    }

    const newturn = {
      ...turn,
      status: newStatus,
      doctor: turn.doctor._id,
      arrivalDate,
      patient: turn.patient._id,
      treatments: turn.treatments.map(it => it._id),
    };

    props.openDialog("Confirmación", `Esta seguro que desea cambiar el estado a ${message}`, newturn, handleTurnChangeStatusConfirmation);

  }

  async function handleTurnChangeStatusConfirmation(confirmed, item) {
    if (!confirmed) {
      props.openSnack('Modificacion cancelada', 'error');
      return;
    }

    await modifyTurn(item);
    props.openSnack('Turno modificado correctamente', 'success');

    setLoading(true);
    setTimeLeft(1);
  }

  function handlePatientView(turn) {

    setPatientId(turn.patient._id);
    setPatientModalShow(true);
  }



  function handleViewDetails(item) {
    props.history && props.history.push(`/medicalExam/${item._id}`)
  }


 
  return (
    <AppContainer
      title={'Agenda'}
      history={props.history}
      content={
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {!mobileCheck() && <Grid item xs={1} className={classes.grid} />}
            <Grid item xs={!mobileCheck() ? 10 : 12}  className={classes.grid}>
              <Grid container>
                <Grid item className={classes.grid}>
                  <Typography variant="h6" noWrap className={classes.typography}>
                    Turnos
                  </Typography>
                </Grid>
                {false && <Grid item className={classes.grid}>
                  {isSecretary() && <Tooltip
                    title={
                      loading || !isDoctorSelected()
                        ? 'Primero debe seleccionar un doctor'
                        : 'Agregar nuevo turno'
                    }
                  >
                    <div>
                      <IconButton
                        disabled={loading || !isDoctorSelected()}
                        onClick={() => {
                          handleGotoNewTurn();
                        }}
                      >
                        <Add
                          color={
                            loading || !isDoctorSelected() ? undefined : 'primary'
                          }
                        />
                      </IconButton>
                    </div>
                  </Tooltip>}
                </Grid>
                }
              </Grid>
              <Grid container>
                <Grid item xs={!mobileCheck() ? 6 : 12} className={classes.grid}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      autoOk
                      orientation="landscape"
                      variant="static"
                      openTo="date"
                      value={calendarDate}
                      onChange={handleDateChange}
                      shouldDisableDate={disableWeekends}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                {false && (
                  <Grid item xs={4} className={classes.grid}>
                    <FormControlLabel
                      control={
                        <Checkbox checked={state.displayAll} onChange={handleDisplayAllClick} />
                      }
                      label={'Mostrar todos'}
                    />
                  </Grid>
                )}
                <Grid item>
                  {getDoctorInfo() !== undefined && <PredictiveComboBox
                    reduce={false}
                    label={'Doctor'}
                    suggestions={doctors.map((it, ix) => {
                      return {
                        key: ix,
                        label: it.fullname,
                        value: it._id,
                      };
                    })}
                    multi={false}
                    value={isDoctorSelected() ? {
                      label: getDoctorInfo().fullname,
                      value: getDoctorInfo()._id
                    } : allDocOption}
                    changecallback={handleDocChange}
                  />}
                </Grid>
              </Grid>


              <Grid item className={classes.grid}>
                {(isAdmin() || isSecretary()) && <Button
                  color="primary"
                  variant="contained"
                  style={{ marginRight: "10px" }}
                  onClick={() => handleGotoNewTurn()}>Nuevo turno</Button>}
                {!loading &&
                  <Button
                    onClick={() => createAndDownloadPDFForSchedule({ schedule, date: getCurrentDate(), doctor: isDoctorSelected() ? getDoctorInfo() : undefined }, `agenda_${dateToString4File(getCurrentDate())}.pdf`)}>Imprimir agenda</Button>
                }
              </Grid>

              <Paper className={classes.paper}>
                {loading ? (
                  <Grid item xs={12} className={classes.grid}>
                    <CircularProgress className={classes.progress} />
                  </Grid>
                ) : schedule !== undefined && schedule.length > 0 ? (
                  <List component="nav" className={classes.list}>
                    {schedule.map((item, index) => {
                      if (!state.displayAll && !item.patient) return false;
                      return (
                        <ScheduleListItem
                          key={index}
                          turn={item}
                          allMode={!isDoctorSelected()}
                          onclick={() => { }}
                          icons={
                            !item.patient
                              ? !isDoctor() ? [
                                // {
                                //   icon: <Add />,
                                //   callback: item => {
                                //     handleAdd(item);
                                //   },
                                //   tooltip: 'Crear turno en horario',
                                // },
                              ] : []
                              : item.status === 'cancelado' || item.status === 'atendido' ? [
                                {
                                  icon: <Person />,
                                  callback: () => {
                                    handlePatientView(item);
                                  },
                                  tooltip: 'Ver Paciente',
                                },] : [
                                {
                                  icon: <Person />,
                                  callback: () => {
                                    handlePatientView(item);
                                  },
                                  tooltip: 'Ver Paciente',
                                },
                                {
                                  icon: <ArrowForward color="primary" />,
                                  callback: () => {
                                    handleTurnProcess(item);
                                  },
                                  tooltip: 'Atender',
                                },
                                //{ icon: <Edit />, callback: detailsCallback, tooltip: "Editar" },
                                {
                                  icon: <Close color="secondary" />,
                                  callback: () => {
                                    handleCancelTurnProcess(item);
                                  },
                                  tooltip: 'Cancelar',
                                },
                              ]
                          }
                        />
                      );
                    })}
                  </List>
                ) : (
                  <Grid item xs={12} className={classes.grid}>
                    <Typography variant="h6" className={classes.avmessage}>
                      {isSecretary() &&
                        !isDoctorSelected() &&
                        'No hay turnos asignados al día de la fecha.'}

                      {isDoctorSelected() &&
                        'El doctor no acepta turnos el día de la fecha.'}
                    </Typography>
                  </Grid>
                )}
              </Paper>
            </Grid>
          </Grid>
          <Dialog
            open={state.turnDialog ? state.turnDialog : false}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <TurnDialog
                history={props.history}
                turn={state.turnDialogTurn}
                openSnack={props.openSnack}
                doctor={props.match.params.doctor}
                date={getCurrentDate()}
                processTurn={handleTurnProcess}
                turnCallback={handleTurn}
                turns={schedule}
              />
            </DialogContent>
          </Dialog>
          <Modal
            open={patientModalShow}
            style={{
              overflow: 'scroll',
              height: '100%',
              display: 'block'
            }}
            onClose={() => { }}>
            <PatientPopup viewDetails={handleViewDetails} patientId={patientId} openDialog={props.openDialog} onClose={() => setPatientModalShow(false)} />
          </Modal>
        </Container>
      }
    />
  );
}
