import axios from 'axios';

export const getAllPatients = async () => {
  try {
    const res = await axios.get(`/patients/`);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const getPatientsByDoctor = async doctor => {
  try {
    const res = await axios.get(`/patients/doctor/${doctor}`);
    const r = { success: true, message: '', data: res.data };
    return r;
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const getPatient = async id => {
  try {
    const res = await axios.get(`/patients/${id}`);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const createPatient = async patient => {
  try {
    const res = await axios.post(`/patients/`, patient);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const modifyPatient = async patient => {
  try {
    const res = await axios.put(`/patients/`, patient);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const deletePatient = async id => {
  try {
    const res = await axios.delete(`/patients/${id}`);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};
