import React, { useEffect,useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Container,
  Grid,
  Typography,
  IconButton,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogContent,
  TextField,
  CircularProgress,
  List,
  Tooltip,
  Modal,
} from '@material-ui/core/';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AppContainer from './AppContainer';
import ScheduleListItem from './ScheduleListItem';
import { ArrowForward, Edit, Close, Person } from '@material-ui/icons';
import { getSchedule, createTurn, modifyTurn, getAllSchedules } from '../actions/schedules';
import TurnDialog from './TurnDialog';
import { getDoctor, getAllDoctors } from '../actions/doctors';
import { mergeSchedule } from '../utils/unfilteredschedule';
import { dateToString } from '../utils/dateToString';
import PredictiveComboBox from './PredictiveText';
import { isSecretary, isDoctor } from '../utils/authUtils';
import { mobileCheck } from '../utils/mobileCheck';
import { useInterval } from '../utils/setInterval';
import PatientPopup from './PatientPopup';
import {getAllHolidays} from '../actions/holidays';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  paper: {
    padding: theme.spacing(2),
  },
  container: {
    padding: theme.spacing(mobileCheck() ? 0 : 4),
  },
  grid: {
    margin: '10px',
  },
  textField: {
    width: '100%',
  },
  button: {
    margin: theme.spacing(1),
  },
  typography: {
    margin: theme.spacing(1),
  },
  avmessage: {
    width: '100%',
    textAlign: 'center',
  },
  progress: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}));

export default function ScheduleDoctor(props) {
  const classes = useStyles();
  const [patientModalShow, setPatientModalShow] = useState(false);
  const [holidays,setHolidays]=useState([]);
  const [patientId, setPatientId] = useState(undefined);

  const [selectedDate, setSelectedDate] = useState(
    props.match.params.dateYear
      ? new Date(
        props.match.params.dateYear,
        props.match.params.dateMonth,
        props.match.params.dateDay,
      )
      : new Date(),
  );

  const [state, setState] = useState({
    edit: false,
    bigDate: true,
    loaded: false,
    displayAll: true,
    turnDialog: false,
    turnDialogTurn: {},
  });

  const [loading, setLoading] = useState(false);

  const [schedule, setSchedule] = useState([]);

  const [doctors, setDoctors] = useState([]);

  const [selectedDoc, setSelectedDoc] = useState(undefined);

  const [pageLoaded, setPageLoaded] = useState(false);

  useInterval(() => {
    fetchDataNoLoad(selectedDoc.value, selectedDate);
  }, 5000);

  useEffect(() => {
    if (pageLoaded) return;
    setPageLoaded(true);
    fetchHolidays();
    fetchData(props.match.params.doctor, selectedDate);
    // eslint-disable-next-line
  }, []);
  async function fetchHolidays(){
    const result = await getAllHolidays();
    if (!result.success) {
        if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
        props.openSnack(result.message.error, 'error');
        return;
    }
    setHolidays(result.data);  
}
  async function fetchData(doctor, date) {
    setLoading(true);

    const allDocs = await getAllDoctors();

    if (!allDocs.success) {
      try {
        if (allDocs.message.error.indexOf('Token') !== -1) props.tokenExpired();
        props.openSnack(allDocs.message.error, 'error');
      } catch (error) {
        props.tokenExpired();
      }

      return;
    }

    const allDoc = { label: 'Todos', fullname: 'Todos', value: undefined };
    setDoctors([allDoc, ...allDocs.data]);

    if (isSecretary()) {
      if (doctor === "undefined" || doctor === undefined) {
        setSelectedDoc(allDoc);
      } else {
        const selDoc = { label: allDocs.data.filter(x => x._id === doctor)[0].fullname, value: allDocs.data.filter(x => x._id === doctor)[0]._id };
        setSelectedDoc(selDoc);
      }
    }


    fetchDataNoLoad(doctor, date)
    setLoading(false);
  }
  function disableWeekends(date) {
    
    if (date.getDay()===0) return true;

let isHoliday=false;
    holidays.forEach((holiday)=>{
      if ((holiday.date-date.getTime())===0) {
        isHoliday= true;
      }
    });
    return isHoliday;
   
  }
  async function fetchDataNoLoad(doctor, date) {

    if ((doctor === "undefined" || doctor === undefined) && isSecretary() && selectedDoc !== undefined)
      doctor = selectedDoc.value;

    if (isDoctor() || (doctor !== "undefined" && doctor !== undefined)) {
      const doc = await getDoctor(doctor);

      if (!doc.success) {
        if (doc.message.error.indexOf('Token') !== -1) props.tokenExpired();
        props.openSnack(doc.message.error, 'error');
        return;
      }

      if (!isSecretary()) {
        const selDoc = { label: doc.data.fullname, value: doc.data._id };
        setSelectedDoc(selDoc);
      }

      const turns = await getSchedule(doctor, date);

      if (!turns.success) {
        props.openSnack(turns.message.error, 'error');
        return;
      }

      let mergedSch = mergeSchedule(turns.data, doc.data.schedule, date);

      for (let i = 0; i < mergedSch.length; i++) {
        const turn = mergedSch[i];

        if (!turn)
          continue;

        const turnsTaken = turn.durationMinutes / 15

        if (turnsTaken === 1)
          continue;

        for (let j = 0; j < turnsTaken - 1; j++) {

          if (mergedSch[i + j + 1] === null)
            continue;

          if (!mergedSch[i + j + 1]) {
            mergedSch[i + j + 1] = null;
          }
          else if (!mergedSch[i + j + 1].patient)
            mergedSch[i + j + 1] = null;
        }

      }

      mergedSch = mergedSch.filter(x => x != null);
      setSchedule(mergedSch);
    } else {
      const turns = await getAllSchedules(date);

      if (!turns.success) {
        props.openSnack(turns.message.error, 'error');
        return;
      }

      const mergedSch = turns.data;
      setSchedule(mergedSch);
    }
  }

  function handleDateChange(date) {
    props.history.push(
      `/scheduleDoctor/${selectedDoc ? `${selectedDoc.value}/` : ''}${date.getYear() +1900}/${date.getMonth()}/${date.getDate()}/`,
    );
    setSelectedDate(date);
    setState({ ...state, bigDate: true });
    fetchData(selectedDoc ? selectedDoc.value : undefined, date);
  }

  function handleDocChange(doc) {
    if (!doc.value) {
      props.history.push(
        `/scheduleDoctor/${selectedDate.getYear() +
        1900}/${selectedDate.getMonth()}/${selectedDate.getDate()}/`,
      );
    } else {
      props.history.push(
        `/scheduleDoctor/${doc.value}/${selectedDate.getYear() +
        1900}/${selectedDate.getMonth()}/${selectedDate.getDate()}/`,
      );
    }
    fetchData(doc.value, selectedDate);
  }

  function handleClose() {
    setState({ ...state, turnDialog: false });
  }

  function handleDisplayAllClick() {
    setState({ ...state, displayAll: !state.displayAll });
  }

  async function handleTurn(turn) {
    if (turn) {
      const result = await createTurn(turn);
      if (!result.success) {
        if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
        props.openSnack(result.message.error, 'error');
      } else {
        props.openSnack('Turno creado correctamente', 'success');
        fetchDataNoLoad(selectedDoc ? selectedDoc.value : undefined, selectedDate);
      }
    }
    handleClose();
  }

  function handleCancelTurnProcess(turn) {

    fetchDataNoLoad(selectedDoc ? selectedDoc.value : undefined, selectedDate);
  }

  async function handleTurnProcess(turn) {

    let newStatus;
    let message;
    let arrivalDate;

    switch (turn.status) {
      case 'poratender':
      case 'sobreturno':
        message = "'En sala de espera'";
        newStatus = 'ensaladeespera';
        arrivalDate = {
          dateHour: new Date().getHours(),
          dateMinute: new Date().getMinutes(),
        };
        break;
      case 'ensaladeespera':
        message = "'Atendiendo'";
        newStatus = 'atendiendo';
        break;
      case 'atendiendo':
        message = "'Finalizado'";
        newStatus = 'atendido';
        break;
      case 'atendido':

        break;
      case 'cancelado':

        break;
      default:
        message = "'En sala de espera'";
        newStatus = 'ensaladeespera';
        break;
    }

    const newturn = {
      ...turn,
      status: newStatus,
      doctor: turn.doctor._id,
      arrivalDate,
      patient: turn.patient._id,
      treatments: turn.treatments.map(it => it._id),
    };
    props.openDialog("Confirmación", `Esta seguro que desea cambiar el estado a ${message}`, newturn, handleTurnChangeStatusConfirmation);

  }

  async function handleTurnChangeStatusConfirmation(confirmed, item) {
    if (!confirmed) {
      props.openSnack('Modificacion cancelada', 'error');
      return;
    }

    await modifyTurn(item);

    props.openSnack('Turno modificado correctamente', 'success');

    fetchDataNoLoad(selectedDoc.value, selectedDate);
  }

  function handlePatientView(turn) {
    setPatientId(turn.patient._id);
    setPatientModalShow(true);
  }



  function handleViewDetails(item) {
    props.history && props.history.push(`/medicalExam/${item._id}`)
  }


  return (
    <AppContainer
      title={'Agenda'}
      history={props.history}
      content={
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {!mobileCheck() && <Grid item xs={1} className={classes.grid} />}
            <Grid item xs={!mobileCheck() ? 10 : 12} className={classes.grid}>
              <Grid container>
                <Grid item className={classes.grid}>
                  <Typography variant="h6" noWrap className={classes.typography}>
                    Turnos
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={!mobileCheck() ? 6 : 12} className={classes.grid}>
                  {state.bigDate ? (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <DatePicker
                        autoOk
                        orientation="landscape"
                        variant="static"
                        openTo="date"
                        value={selectedDate}
                        onChange={handleDateChange}
                        shouldDisableDate={disableWeekends}
                      />
                    </MuiPickersUtilsProvider>
                  ) : (
                    <Grid container>
                      <Grid item xs={4} className={classes.grid}>
                        <TextField
                          InputProps={{
                            readOnly: !state.edit,
                          }}
                          label={'Fecha'}
                          className={classes.textField}
                          value={dateToString(selectedDate)}
                          name={'time'}
                          InputLabelProps={{
                            shrink: true,
                            readOnly: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={2} className={classes.grid}>
                        <IconButton
                          onClick={() => {
                            setState({ ...state, bigDate: true });
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
                {false && (
                  <Grid item xs={4} className={classes.grid}>
                    <FormControlLabel
                      control={
                        <Checkbox checked={state.displayAll} onChange={handleDisplayAllClick} />
                      }
                      label={'Mostrar todos'}
                    />
                  </Grid>
                )}
                {isSecretary() && (
                  <Grid item>
                    {state.edit ? (
                      <PredictiveComboBox
                        reduce={false}
                        label={'Doctor'}
                        suggestions={doctors.map((it, ix) => {
                          return {
                            key: ix,
                            label: it.fullname,
                            value: it._id,
                          };
                        })}
                        multi={false}
                        value={selectedDoc}
                        changecallback={handleDocChange}
                      />
                    ) : (
                      <Grid container>
                        <Grid item xs={!mobileCheck() ? 10 : 12}>
                          <TextField
                            InputProps={{
                              readOnly: !state.edit,
                            }}
                            label={'Doctor'}
                            className={classes.textField}
                            value={selectedDoc ? selectedDoc.label : ''}
                            name={'doctor'}
                            InputLabelProps={{
                              shrink: true,
                              readOnly: true,
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Tooltip title="Cambiar doctor">
                            <IconButton
                              onClick={() => {
                                setState({ ...state, edit: true });
                              }}
                            >
                              <Close />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                )}
              </Grid>

              <Paper className={classes.paper}>
                {loading ? (
                  <Grid item xs={12} className={classes.grid}>
                    <CircularProgress className={classes.progress} />
                  </Grid>
                ) : schedule.length > 0 ? (
                  <List component="nav" className={classes.list}>
                    {schedule.map((item, index) => {
                      if (!state.displayAll && !item.patient) return false;

                      return (
                        <ScheduleListItem
                          key={index}
                          turn={item}
                          allMode={selectedDoc && !selectedDoc.value}
                          onclick={() => { }}
                          icons={
                            !item.patient
                              ? []
                              : item.status === 'cancelado' || item.status === 'atendido' ? [
                                {
                                  icon: <Person />,
                                  callback: () => {
                                    handlePatientView(item);
                                  },
                                  tooltip: 'Ver Paciente',
                                },] : [
                                {
                                  icon: <Person />,
                                  callback: () => {
                                    handlePatientView(item);
                                  },
                                  tooltip: 'Ver Paciente',
                                },
                                {
                                  icon: <ArrowForward color="primary" />,
                                  callback: () => {
                                    handleTurnProcess(item);
                                  },
                                  tooltip: 'Atender',
                                },
                                //{ icon: <Edit />, callback: detailsCallback, tooltip: "Editar" },
                                {
                                  icon: <Close color="secondary" />,
                                  callback: () => {
                                    handleCancelTurnProcess(item);
                                  },
                                  tooltip: 'Cancelar',
                                },
                              ]
                          }
                        />
                      );
                    })}
                  </List>
                ) : (
                  <Grid item xs={12} className={classes.grid}>
                    <Typography variant="h6" className={classes.avmessage}>
                      {isSecretary() &&
                        selectedDoc &&
                        !selectedDoc.value &&
                        'No hay turnos asignados al día de la fecha.'}

                      {selectedDoc &&
                        selectedDoc.value &&
                        'El doctor no acepta turnos el día de la fecha.'}
                    </Typography>
                  </Grid>
                )}
              </Paper>
            </Grid>
          </Grid>
          <Dialog
            open={state.turnDialog ? state.turnDialog : false}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <TurnDialog
                history={props.history}
                turn={state.turnDialogTurn}
                openSnack={props.openSnack}
                doctor={selectedDoc}
                date={selectedDate}
                processTurn={handleTurnProcess}
                turnCallback={handleTurn}
                turns={schedule}
              />
            </DialogContent>
          </Dialog>
          <Modal
            open={patientModalShow}
            style={{
              overflow: 'scroll',
              height: '100%',
              display: 'block'
            }}
            onClose={() => { }}>
            <PatientPopup viewDetails={handleViewDetails} history={props.history} patientId={patientId} openDialog={props.openDialog} onClose={() => setPatientModalShow(false)} />
          </Modal>
        </Container>
      }
    />
  );
}
