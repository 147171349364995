import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Grid, IconButton, Tooltip } from '@material-ui/core';
import { Delete, Done, SettingsBackupRestore ,Edit,Add} from '@material-ui/icons';
import { createTreatmentPrice, modifyTreatmentPrice,deleteTreatmentPrice } from '../actions/treatmentTypePrices';
import TreatmentEditPriceDialog from './TreatmentEditPriceDialog';
import TreatmentAddPriceDialog from './TreatmentAddPriceDialog';
import updateColorStatus from "../actions/treatmentPricesSetColors";
import "./css/TreatmentPricesListByPrepaid.css";
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    paper: {
        width: '100%',
        padding: theme.spacing(2),
    },
    container: {
        width: '100%',
        padding: theme.spacing(4),
    },
    grid: {
        width: '100%',
        margin: '10px',
    },
    textField: {
        width: '100%',
    },
    button: {
        margin: theme.spacing(1),
    },
}));
export default function TreatmentPrepaidPriceListItem(props) {
    const classes = useStyles();
    function buildDateStr(date) {
        var d = new Date(date);
        var dateStr = `${d.getDate() < 10 ? "0" + d.getDate() : d.getDate()}/${
          d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1
        }/${d.getFullYear()}`;
        return dateStr;
      }
function getDate(date) {
    var d = new Date(date);
    var dateStr = `${d.getFullYear()}-${d.getMonth() + 1 < 11 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1}-${d.getDate() < 10 ? '0' + d.getDate() : d.getDate()}`;
    return dateStr;
}
const [futurePrices, setFuturePrices]=useState(props.futurePrices); 
const [editData,setEditData]=useState({value:{},item:0,visible:false});
const [addData,setAddData]=useState({visible:false});
const handleDeleteFuturePrices=(deletedItem)=>{
setFuturePrices(updateColorStatus (futurePrices.map((obj,item) => (item===deletedItem?{ ...obj, status: "deleted"}:obj))));
}
const handleEditFuturePrices=(editedValue,editedItem)=>{
    setEditData({item:editedItem, value:editedValue,visible:true});
}  
const handleAddFuturePrices=()=>{
    setAddData({visible:true});
}

const handleReset=()=>{                          
    setFuturePrices(props.futurePrices);
}
async function handlePriceCommit() {
  const datesOk=futurePrices.find(price=>price.color==="red")?false:true;
   if (datesOk){
    commitPricesChanges(true);
    } else {
   props.openDialog("Advertencia al procesar los cambios", "Existen aumentos porcentuales con fecha posterior a los aumentos modificados. Si continúa dichos aumentos porcentuales no tendran efecto en estos cambios. ", {}, commitPricesChanges);
   }

async function commitPricesChanges(confirmed) {
    if (!confirmed) {
      return
    }
    let result={};
    futurePrices.forEach(async (futurePrice)=>{
        switch (futurePrice.status){
         case "deleted":
           result=await deletePrice(futurePrice._id);
           if (!result.success) {
                if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
                props.openSnack(result.message.error, 'error');
            return;
           }
           break;
        case "edited":
            result=await editPrice(futurePrice);
            if (!result.success) {
             if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
             props.openSnack(result.message.error, 'error');
             return; 
            }
        break;
        case "added":
            result=await addNewPrice(futurePrice);
            if (!result.success) {
             if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
             props.openSnack(result.message.error, 'error');
             return; 
            }
            break;
        default:
            break;
        }
    }
    );
    props.openSnack("Los cambios fueron actualizados exitosamente", 'success');
    window.location.reload();
    }


}
async function addNewPrice(newPrice){
    const wat = {price: newPrice.price, treatmentType: props.treatmentType, from: newPrice.from , prepaid: props.prepaid._id };
    const result = await createTreatmentPrice(wat);
    return result;

}
async function deletePrice(id){
    const result = await deleteTreatmentPrice(id);
    return result
   /* if (!result.success) {
        if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
        props.openSnack(result.message.error, 'error');
        return;
    } else {
        props.openSnack("El  precio fue eliminado exitosamente", 'success');
        //window.location.reload();
    }*/
}
async function editPrice(treatment){
    const wat = { _id: treatment._id, price: treatment.price, treatmentType: treatment.treatmentType, from: treatment.from , prepaid: treatment.prepaid };
    const result = await modifyTreatmentPrice(wat)
    return result;
}
    return (
        <div>
            <Grid container>
                <Grid item xs={3} className={classes.grid}>
                    <TextField
                        InputProps={{
                            readOnly: !props.editable,
                        }}
                        label={'Prepaga'}
                        className={classes.textField}
                        name={'name'}
                        value={props.name}
                        InputLabelProps={{
                            shrink: true,
                        }} />
                </Grid>
                {/* {!props.editable && */}
                <Grid item xs={2} className={classes.grid}>
                    <TextField
                        InputProps={{
                            readOnly: true,
                        }}
                        label={'Precio actual'}
                        className={classes.textField}
                        name={'price'}
                        type='number'
                        value={props.editable ? props.price ? props.price.price : 0 : props.price ? parseInt(props.price.price) : 0}
                        InputLabelProps={{
                            shrink: true,
                        }} />
                </Grid>
                <Grid item xs={2} className={classes.grid}>
                        {props.price &&
                        <TextField
                            InputProps={{
                                readOnly: true,
                            }}
                            label={'Desde'}
                            className={classes.textField}
                            name={'currentPriceDate'}
                            type='text'
                            value={buildDateStr(props.price.from)}
                            InputLabelProps={{
                                shrink: true,
                            }} />}
                    </Grid>

                {props.editable &&
                    <Grid item xs={2} className={classes.grid}>
                        <Tooltip
                            title={"Agregar nuevo precio"}>
                            <IconButton
                                onClick={()=>{handleAddFuturePrices()}}>
                                <Add />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                }
      
                {/*false && <Grid item xs={2} className={classes.grid}>
                    <TextField
                        InputProps={{
                            readOnly: !props.editable,
                        }}
                        label={'Máximo consultas (0 = sin máximo)'}
                        className={classes.textField}
                        name={'max'}
                        type='number'
                        value={props.price ? props.price.max : 0}
                        onChange={e => {
                            if (!props.editable) return;
                            props.maxChanged(props.price, e.target.value);
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }} />
                    </Grid>*/}
                <Grid item xs={2} className={classes.grid}>
                    {props.editable && props.icons &&
                        props.icons.map(
                            (icon, index) => {
                                return (
                                    <Tooltip
                                        title={icon.tooltip}
                                        key={index}>
                                        <IconButton
                                            onClick={() => {
                                                icon.callback(props.price);
                                            }}>
                                            {icon.icon}
                                        </IconButton>
                                    </Tooltip>
                                )
                            }
                        )}
                </Grid>
            </Grid>
            <Grid item xs={12} lg={10} className={classes.grid}>
                    {futurePrices.map((dat, item) => {
                     
                      if (item === 0) {
                        return null;
                      } else {
                        return (
                          <div
                            style={{
                              width: "100%",
                              paddingLeft: "2%",
                              fontSize: "12px",
                            }}
                          >
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              <Grid item xs={3} spacing={1}>
                                <SmallTextData
                                  title={"Precio Futuro ($)"}
                                  content={dat.price}
                                  status={dat.status}
                                  color={dat.color} 
                                />
                              </Grid>
                              <Grid item xs={3}  spacing={1}>
                                <SmallTextData
                                  title={"Desde"}
                                  content={buildDateStr(dat.from)}
                                  status={dat.status} 
                                  color={dat.color} 
                                />
                              </Grid>
                              <Grid item xs={3}  spacing={1}>
                                <SmallTextData
                                  title={"Tipo"}
                                  content={dat.percentageAdj
                                      ? "Porcentual"
                                      :"Individual"
                                  }
                                  status={dat.status}
                                  color={dat.color} 
                                />
                              </Grid>
                              {props.editable &&  
                            <Grid item xs={3}  spacing={1}>
                              <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              <Grid item xs={6}  spacing={1}>
                                <Tooltip
                                    title={"Eliminar"}>
                                    <IconButton
                                        onClick={() => {
                                        handleDeleteFuturePrices(item)
                                    }}>
                                        <Delete/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={6}  spacing={1}>
                                <Tooltip
                                    title={"Editar"}>
                                    <IconButton
                                        onClick={() => {
                                        handleEditFuturePrices(dat,item)
                                    }}>
                                        <Edit/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            </Grid>
                            </Grid>
                            }
                            </Grid>
                          </div>
                        );
                      }
                    })}
            </Grid>
            {props.editable &&
                    <Grid item xs={4} lg={2} className={classes.grid}>
                        <Tooltip
                            title={"Resetear"}>
                            <IconButton
                                onClick={() => {    
                                   handleReset();
                                }}>
                                <SettingsBackupRestore />
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title={"Aceptar"}>
                            <IconButton
                                onClick={() => {
                                    handlePriceCommit();
                                }}>
                                <Done />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    
                }
                {editData.visible &&
            <TreatmentEditPriceDialog editData={editData} setEditData={setEditData} futurePrices={futurePrices} setFuturePrices={setFuturePrices} openDialog={ props.openDialog} openSnack={props.openSnack}/> 
                }
                 {addData.visible &&
            <TreatmentAddPriceDialog addData={addData} setAddData={setAddData} futurePrices={futurePrices} setFuturePrices={setFuturePrices} openDialog={ props.openDialog} openSnack={props.openSnack}/> 
                }
            </div>
    );
}

  const SmallTextData = (props) => {
    switch (props.status){
        case "deleted":
            return (
                <div>
                  <div className="smallTitle" >{props.title}</div>
                  <div className="smallContent" style={{color:props.color}}><del>{props.content}</del></div>
                </div>
              );
        case "edited":

            return (
                <div>
                  <div className="smallTitle">{props.title}</div>
                  <div className="smallContent" style={{color:props.color}}>{props.content}</div>
                </div>
              );
              default:
                return (
                    <div>
                      <div className="smallTitle">{props.title}</div>
                      <div className="smallContent" style={{color:props.color}}>{props.content}</div>
                    </div>
                  );
    }
  };
  function buildDateStr(date) {
    var d = new Date(date);
    var dateStr = `${d.getDate() < 10 ? "0" + d.getDate() : d.getDate()}/${
      d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1
    }/${d.getFullYear()}`;
    return dateStr;
  }
  
