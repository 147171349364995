import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Container, Grid, TextField, Button, Snackbar, Typography } from '@material-ui/core/';
import { login } from '../actions/auth';
import { isAuthenticated, getUserData } from '../utils/authUtils';
import MySnackbarContentWrapper from './AppSnackbar';
import ConfirmDialog from './ConfirmDialog';
import { mobileCheck } from './../utils/mobileCheck';
import Logo from "../assets/logo.png";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  paper: {
    padding: theme.spacing(2),
  },
  container: {
    padding: theme.spacing(mobileCheck() ? 0 : 4),
  },
  grid: {
    margin: '10px',
    width: '100%',
  },
  textField: {
    width: '100%',
  },
  button: {
    margin: theme.spacing(1),
  },
  typography: {
    display: 'block',
    margin: 'auto',
    textAlign: 'center',
    width: '100%',
  },
  img: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    paddingTop: '20px',
    paddingBottom: '10px',
  },
}));

export default function LoginView(props) {

  const classes = useStyles();

  const [values, setValues] = React.useState({
    username: '',
    password: '',
  });

  const [snack, setSnack] = React.useState({
    open: false,
    text: "Snack text",
    variant: "error",
  })


  const [confirmDialog, setConfirmDialog] = React.useState({
    open: false,
    title: "Confirmación",
    message: "Seguro?",
    item: {},
    callback: () => { },
  })

  useEffect(() => {
    if (isAuthenticated())
      props.history.push('/schedule');
    // eslint-disable-next-line
  }, []);

  function openDialog(title, message, item, callback) {
    setConfirmDialog({
      ...confirmDialog,
      open: true,
      title: title,
      message: message,
      item: item,
      callback: callback,
    });
  }

  function handleDeleteConfirm() {
    setConfirmDialog({ ...confirmDialog, open: false });
  }

  function handleSnackClose() {
    setSnack({ ...snack, open: false });
  }

  const handleChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };

  const onRecovery = async () => {
    props.history.push('/recover');
  }

  const onLogin = async () => {
    const res = await login(values.username, values.password);
console.log(res);
    if (!res.success){
    openDialog("Error", res.message);
      return;}

    try {
      if (getUserData().roles.indexOf("Administrador/a") >= 0)
        props.history.push('/users');
      else if (getUserData().roles.indexOf("Contador/a") >= 0)
        props.history.push('/billing');
      else if (getUserData().roles.indexOf("Doctor/a") >= 0)
        props.history.push('/scheduleDoctor');
        else if (getUserData().roles.indexOf("Secretario/a") >= 0)
        props.history.push('/schedule');
    } catch (err) {
      setValues({ ...values, password: '' });
      openDialog("Error", "Usuario o contraseña incorrectos");
    }
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid className={classes.paddingTop} container spacing={3}>
        <Grid item xs={!mobileCheck() ? 3 : 2} />
        <Grid item xs={!mobileCheck() ? 6 : 8}>
          <img className={classes.img} src={Logo} alt="Logo" />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={!mobileCheck() ? 3 : 2} />
        <Grid item xs={!mobileCheck() ? 6 : 8}>
          <Paper className={classes.paper}>
            <Typography variant="h6" className={classes.typography}>
              Ingreso
            </Typography>
            <Grid container>
              <Grid item xs={12} className={classes.grid}>
                <TextField
                  id="standard-name"
                  label="Usuario"
                  className={classes.textField}
                  onChange={handleChange('username')}
                  value={values.username}
                />
              </Grid>
              <Grid item xs={12} className={classes.grid}>
                <TextField
                  label="Contraseña"
                  className={classes.textField}
                  id="standard-password-input"
                  type="password"
                  autoComplete="current-password"
                  onChange={handleChange('password')}
                  value={values.password}
                />
              </Grid>
              <Grid item xs={12}><br /></Grid>
              <Grid item xs={12}><br /></Grid>
              <Grid item >
                <Button
                  className={classes.button}
                  onClick={onRecovery}
                  color="primary"
                >
                  Recuperar
                </Button>
              </Grid>
              <Grid item >
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={onLogin}
                >
                  Entrar
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snack.open}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <MySnackbarContentWrapper
          onClose={handleSnackClose}
          variant={snack.variant}
          message={snack.text}
        />
      </Snackbar>
      <ConfirmDialog open={confirmDialog.open} title={confirmDialog.title} message={confirmDialog.message} accept={handleDeleteConfirm} />
    </Container>
  );
}
