import { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import updateColorStatus from "../actions/treatmentPricesSetColors";
import { mobileCheck } from "../utils/mobileCheck";

export default function TreatmentEditPriceDialog(props) {
  const [calendarDate, setCalendarDate] = useState(
    new Date(props.editData.value.from)
  );
  const [currentPrice, setCurrentPrice] = useState(props.editData.value.price);
  const handleClose = () => {
    props.setEditData({ ...props.editData, visible: false });
  };
  const handleEditPrices = () => {
    if (!currentPrice) {
      props.openSnack("El campo precio no puede estar vacío", "error");
      return;
    }
    let futurePrices = props.futurePrices.map((obj, item) =>
      item === props.editData.item
        ? {
            ...obj,
            status: "edited",
            from: calendarDate.getTime(),
            price: currentPrice,
            color: "",
          }
        : obj
    );
    //Agrego e

    const actualPrice = props.futurePrices[0];
    futurePrices = updateColorStatus(futurePrices).slice(1); //Chequeo los colores y le saco el precio actual
    const allNewPrices = futurePrices.sort((a, b) => a.from - b.from); //ordeno
    allNewPrices.splice(0, 0, actualPrice); //agreo el precio actual
    props.setFuturePrices(allNewPrices);
    props.setEditData({ ...props.editData, visible: false });
  };

  return (
    <Dialog
      open={props.editData.visible}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{`Edición de aumento `}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Por favor edite el valor del estudio junto con su fecha de inicio.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="percentage"
          label="Aumento ($)"
          type="number"
          variant="standard"
          defaultValue={currentPrice}
          onChange={(e) => {
            setCurrentPrice(e.target.value);
          }}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            autook
            orientation={mobileCheck() ? "vertical" : "landscape"}
            variant="static"
            openTo="date"
            value={calendarDate}
            onChange={(date) => {
              setCalendarDate(date);
            }}
          />
        </MuiPickersUtilsProvider>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
          }}
          autoFocus
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleEditPrices();
          }}
          autoFocus
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
