import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Container,
    Grid,
    Paper,
    Typography,
    CircularProgress,
    TextField,
    FormControlLabel,
    Checkbox,
    Button,
    Modal,
} from '@material-ui/core/';
import AppContainer from './AppContainer';
import { mobileCheck } from '../utils/mobileCheck';
import { getAllPatients } from '../actions/patients';
import { createTurn, getScheduleAvailability } from '../actions/schedules';
import PredictiveComboBox from './PredictiveText';
import { getAllDoctors } from '../actions/doctors';
import { getAllTreatmentTypes } from '../actions/treatmentTypes';
import { mergeSchedule } from '../utils/unfilteredschedule';
import { dateToString } from '../utils/dateToString';
import { getPrepaids } from '../actions/prepaids';
import PatientPopup from './PatientPopup';
import { Person } from '@material-ui/icons';

import { capitalize } from '../utils/stringUtils';
import { getAllHolidays } from '../actions/holidays';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    paper: {
        padding: theme.spacing(2),
    },
    container: {
        padding: theme.spacing(mobileCheck() ? 0 : 4),
    },
    grid: {
        margin: '10px',
        width: '100%',
    },
    textField: {
        width: '100%',
    },
    button: {
        margin: theme.spacing(1),
    },
    typography: {
        margin: theme.spacing(1),
    },
    avmessage: {
        width: '100%',
        textAlign: 'center',
    },
    progress: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}));

export default function NewTurnFromScratch(props) {
    const classes = useStyles();

    const [timeTakenForTreatments, setTimeTakenForTreatments] = React.useState(0);


    const [patientModalShow, setPatientModalShow] = React.useState(false);
    const [doctors, setDoctors] = React.useState([]);
    const [patients, setPatients] = React.useState([]);
    const [prepaids, setPrepaids] = React.useState([]);
    const [holidays, setHolidays] = React.useState([]);
    const [treatmentTypes, setTreatmentTypes] = React.useState([]);
    const [availableDates, setAvailableDates] = React.useState([]);
    const [currentAvailability, setCurrentAvailability] = React.useState([]);
    const [availableTurns, setAvailableTurns] = React.useState([]);

    const [loading, setLoading] = React.useState(true);

    const [selectedDate, setSelectedDate] = React.useState(undefined);
    const [selectedTime, setSelectedTime] = React.useState(undefined);
    const [selectedPatient, setSelectedPatient] = React.useState();
    const [selectedDoctor, setSelectedDoctor] = React.useState();
    const [selectedTreatments, setSelectedTreatments] = React.useState([]);
    const [isOverturn, setIsOverturn] = React.useState(false);
    const [needsReminder, setNeedsReminder] = React.useState(true);

    const [pageLoaded, setPageLoaded] = React.useState(false);

    useEffect(() => {
        if (pageLoaded) return;
        setPageLoaded(true);
        fetchData();
        // eslint-disable-next-line
    }, []);

    async function fetchData() {
        setLoading(false);

        const doctors = await getAllDoctors();
        setDoctors(doctors.data);

        const patients = await getAllPatients();
        setPatients(patients.data);

        const treatmentTypes = await getAllTreatmentTypes()
        setTreatmentTypes([...treatmentTypes.data]);

        const prepaids = await getPrepaids();
        setPrepaids([...prepaids.data]);

        const holidays = await getAllHolidays();
        setHolidays([...holidays.data]);
    }

    async function updateAvailableTurns() {
        
        setSelectedDate(undefined);
        setSelectedTime(undefined);

        let timeTakenForTreatments = 0;

        treatmentTypes.forEach(tt => {
            if (!selectedTreatments.some(x => x.value === tt._id))
                return;

            const doc = doctors.find(x => x._id === selectedDoctor.value);

            let turnTakenDoc;
            if (doc) {
                const turnsTaken = doctors.find(x => x._id === selectedDoctor.value).turnsTaken;
                    // Que es turnstaken?
                if (turnsTaken)
                    turnTakenDoc = turnsTaken[tt._id];
            }
            // Aca suma los horarios de los turnos y parece andar bien
            if (turnTakenDoc) {
                timeTakenForTreatments += parseInt(turnTakenDoc.timeTaken);
            } else {
                timeTakenForTreatments += parseInt(tt.turnsTaken);
            }
        });
        if (!selectedDoctor)
            return;

        const turns = await getScheduleAvailability(selectedDoctor.value);
        if (!turns.success) {
            props.openSnack(turns.message.error, 'error');
            return;
        }

        turns.data.forEach(x => {
            for (let i = 0; i < x.turns.length - 1; i++) {
                const turn = x.turns[i];
                const nTurn = x.turns[i + 1];

                if (turn.dateHour === nTurn.dateHour) {
                    switch (turn.dateMinute) {
                        case 0:
                            if (nTurn.dateMinute === 15)
                                turn.combinableNext = true;
                            break;
                        case 15:
                            if (nTurn.dateMinute === 30)
                                turn.combinableNext = true;
                            break;
                        case 30:
                            if (nTurn.dateMinute === 45)
                                turn.combinableNext = true;
                            break;
                        default:
                            break;
                    }
                }
                else if (turn.dateHour + 1 === nTurn.dateHour && turn.dateMinute === 45 && nTurn.dateMinute === 0) {
                    turn.combinableNext = true;
                }
                
            }
        })

        turns.data.forEach(x => {
            for (let i = 0; i < x.turns.length; i++) {
                const turn = x.turns[i];

                let turnTime = 15;

                if (turn.combinableNext) {
                    for (let j = i + 1; j < x.turns.length; j++) {
                        const nTurn = x.turns[j];

                        turnTime += 15;

                        if (!nTurn.combinableNext)
                            j = x.turns.length
                    }
                } else {
                    turn.combinableNext = false;
                }

                turn.turnTime = turnTime;

                turn.validForTreatment = turnTime >= timeTakenForTreatments;
            }
        })

        turns.data.forEach(x => {
            x.turns = x.turns.filter(t => t.validForTreatment);

            if (!x.turns)
                x.turns = [];
        });

        setTimeTakenForTreatments(timeTakenForTreatments);

        const filteredTurns = turns.data;//.filter(x => x.turns.length > 0);

        setCurrentAvailability(filteredTurns);

        setAvailableDates(filteredTurns.filter(x => holidays.every(y => y.date !== x.date)).map(x => x.date));
    }

    async function handleAccept() {

        if (selectedTreatments.length === 0) {
            props.openSnack("Debe seleccionar al menos un estudio", 'error');
            return;
        }

        if (selectedPatient === undefined || selectedPatient.value === "") {
            props.openSnack("Debe seleccionar un paciente", 'error');
            return;
        }

        if (selectedDoctor === undefined || selectedDoctor.value === "") {
            props.openSnack("Debe seleccionar un doctor", 'error');
            return;
        }

        if (selectedDate === undefined || selectedDate.value === "") {
            props.openSnack("Debe seleccionar una fecha", 'error');
            return;
        }

        if (selectedTime === undefined || selectedTime.value === "") {
            props.openSnack("Debe seleccionar un horario", 'error');
            return;
        }

        setLoading(true);

        const turn = getTurn();

        const result = await createTurn(turn);
        if (!result.success) {
            if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
            props.openSnack(result.message.error, 'error');
            setLoading(false);
            return;
        } else {
            props.openSnack('Turno creado correctamente', 'success');
        }

        props.history.push(`/schedule/${turn.doctor}/${turn.dateYear}/${turn.dateMonth}/${turn.dateDay}`);
    }

    function getUserPrepaidName(patient) {
        let prepaid = prepaids.filter(y => y._id === patient.prepaid)[0];

        if (prepaid === undefined)
            return;

        let prepaidName = prepaid.name;

        const prepaidTypeName = prepaid.types !== undefined && prepaid.types.length > patient.prepaidType ? " " + prepaid.types[patient.prepaidType] : "";

        if (prepaidTypeName !== " undefined")
            prepaidName += prepaidTypeName;

        return prepaidName;
    }

    function getTurn() {
        return {
            dateYear: new Date(selectedDate.value).getYear() + 1900,
            dateMonth: new Date(selectedDate.value).getMonth(),
            dateDay: new Date(selectedDate.value).getDate(),
            dateHour: selectedTime.dateHour,
            dateMinute: selectedTime.dateMinute,
            durationMinutes: timeTakenForTreatments,
            doctor: selectedDoctor.value,
            patient: selectedPatient.value,
            treatments: selectedTreatments.map(it => it.value),
            overturn: isOverturn,
            reminder: needsReminder,
            notes: "",
        };
    }

    function handlePatientView(turn) {

        setPatientModalShow(true);
    }

    function handleViewDetails(item) {
        props.history && props.history.push(`/medicalExam/${item._id}`)
    }

    function getDoctorsThatCanAccessTreatments() {
        return doctors.filter(x => contains(x._id, selectedTreatments));

    }
    function contains(doctorId, selectedTreatments) {

        return selectedTreatments.every(x => {

            const treatment = treatmentTypes.find(y => y._id === x.value);

            if (!treatment)
                return false;

            return treatment.doctors.some(x => x === doctorId);
        });
    }


    return (
        <AppContainer
            title={'Nuevo Turno'}
            history={props.history}
            content={
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        {!mobileCheck() && <Grid item xs={1} className={classes.grid} />}
                        <Grid item xs={!mobileCheck() ? 10 : 12} className={classes.grid}>
                            <Grid container>
                                <Grid item className={classes.grid}>
                                    <Typography variant="h6" noWrap className={classes.typography}>
                                        Nuevo Turno
                                    </Typography>

                                    <Paper className={classes.paper}>
                                        {loading ? (
                                            <Grid item xs={12} className={classes.grid}>
                                                <CircularProgress className={classes.progress} />
                                            </Grid>
                                        ) :
                                            <Grid container>

                                                <Grid item xs={12}><br /></Grid>
                                                <Grid item xs={12}>
                                                    <Button color="primary" variant="contained" onClick={() => setPatientModalShow(true)}>
                                                        Nuevo paciente
                                                        <Person className={classes.toolbarIcon} />
                                                    </Button>
                                                </Grid>
                                                <Grid item xs={12}><br /></Grid>

                                                <Grid item xs={12} className={classes.grid}>
                                                    <PredictiveComboBox
                                                        label={'Paciente'}
                                                        suggestions={patients.map((it, ix) => {
                                                            return {
                                                                key: ix,
                                                                label: capitalize(`${it.uid} - ${it.lastname !== undefined ? it.lastname.trim() + ", " : ""}${it.fullname}`),
                                                                value: it._id,
                                                            };
                                                        })}
                                                        multi={false}
                                                        value={selectedPatient}
                                                        changecallback={p => { setSelectedPatient(p); }}
                                                    />
                                                </Grid>
                                                {selectedPatient !== undefined && <div>
                                                    <Grid item xs={12} className={classes.grid}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            label={'DNI'}
                                                            className={classes.textField}
                                                            value={!selectedPatient ? " " : patients.filter(x => x._id === selectedPatient.value)[0].uid}
                                                            name={'patient'}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                readOnly: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} className={classes.grid}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            label={'Telefono'}
                                                            className={classes.textField}
                                                            value={!selectedPatient ? " " : patients.filter(x => x._id === selectedPatient.value)[0].phone}
                                                            name={'patient'}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                readOnly: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} className={classes.grid}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            label={'Email'}
                                                            className={classes.textField}
                                                            value={!selectedPatient ? " " : patients.filter(x => x._id === selectedPatient.value)[0].mail}
                                                            name={'patient'}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                readOnly: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} className={classes.grid}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            label={'Cobertura'}
                                                            className={classes.textField}
                                                            value=
                                                            {!selectedPatient ? " "
                                                                : getUserPrepaidName(patients.filter(x => x._id === selectedPatient.value)[0])}
                                                            name={'patient'}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                readOnly: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} className={classes.grid}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            label={'Numero Cobertura'}
                                                            className={classes.textField}
                                                            value={!selectedPatient ? " " : patients.filter(x => x._id === selectedPatient.value)[0].prepaidNumber}
                                                            name={'patient'}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                readOnly: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} className={classes.grid}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={!selectedPatient ? " " : patients.filter(x => x._id === selectedPatient.value)[0].taxed}
                                                                />
                                                            }
                                                            label={'Grabado'}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}><br /></Grid>
                                                    <Grid item xs={12}>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            className={classes.button}
                                                            onClick={() => handlePatientView()}
                                                        >
                                                            Editar Paciente
                                                        </Button>
                                                    </Grid>
                                                    <Grid item xs={12}><br /></Grid>
                                                </div>}
                                                <Grid item xs={12} className={classes.grid}>
                                                    <PredictiveComboBox
                                                        label={"Estudios"}
                                                        //enable this when all treatments are configured correctly
                                                        suggestions={treatmentTypes.filter(x => true /*x.doctors.indexOf(doctor._id) > 0*/).map((it, ix) => {
                                                            return {
                                                                key: ix,
                                                                label: it.name,
                                                                value: it._id
                                                            }
                                                        })}
                                                        multi={true}
                                                        value={selectedTreatments}
                                                        reduce={false}
                                                        changecallback={(v) => {
                                                            if (v === null)
                                                                setSelectedTreatments([]);
                                                            else
                                                                setSelectedTreatments([...v]);

                                                            setSelectedDoctor({});
                                                            setAvailableDates([]);
                                                            setAvailableTurns([]);
                                                            setSelectedDate(undefined);
                                                            setSelectedTime(undefined);
                                                        }}
                                                    />
                                                </Grid>
                                                {selectedTreatments.length > 0 && <Grid item xs={12} className={classes.grid}>
                                                    <PredictiveComboBox
                                                        label={'Doctor'}
                                                        suggestions={getDoctorsThatCanAccessTreatments().map((it, ix) => {
                                                            return {
                                                                key: ix,
                                                                label: capitalize(`${it.lastname !== undefined ? it.lastname.trim() + ", " : ""}${it.fullname}`),
                                                                value: it._id,
                                                            };
                                                        })}
                                                        multi={false}
                                                        value={selectedDoctor}
                                                        changecallback={p => {
                                                            setSelectedDoctor(p);
                                                            setAvailableDates([]);
                                                            setAvailableTurns([]);
                                                            setSelectedDate(undefined);
                                                            setSelectedTime(undefined);
                                                        }}
                                                    />
                                                </Grid>}

                                                <Grid item xs={12}><br /></Grid>
                                                <Grid item xs={12}>
                                                    {selectedDoctor && selectedDoctor.value && <Button
                                                        variant="contained"
                                                        color="primary"
                                                        className={classes.button}
                                                        onClick={() => updateAvailableTurns()}
                                                    >
                                                        Buscar fechas disponibles
                                                    </Button>
                                                    }
                                                </Grid>
                                                <Grid item xs={12}><br /></Grid>

                                                {availableDates.length > 0 &&
                                                    <Grid item xs={12} className={classes.grid}>

                                                        <PredictiveComboBox
                                                            label={'Fechas Disponibles'}
                                                            suggestions={availableDates.map((it, ix) => {
                                                                return {
                                                                    key: ix,
                                                                    label: dateToString(it),
                                                                    value: it,
                                                                };
                                                            })}
                                                            multi={false}
                                                            value={selectedDate}
                                                            changecallback={p => {
                                                                setSelectedDate(p);
                                                                setAvailableTurns(currentAvailability.find(x => x.date === p.value).turns)
                                                            }}
                                                        />
                                                    </Grid>}

                                                {!availableTurns || (availableTurns.length <= 0 && !isOverturn) ? selectedDate ? <Grid item xs={12} className={classes.grid}>No hay horarios disponibles</Grid> : <div /> : !isOverturn ? <Grid item xs={12} className={classes.grid}>
                                                    <PredictiveComboBox
                                                        reduce={false}
                                                        label={"Horario"}
                                                        suggestions={availableTurns && availableTurns.filter(item => isOverturn || !item.patient).map((it, ix) => {
                                                            return {
                                                                key: ix,
                                                                dateHour: it.dateHour,
                                                                dateMinute: it.dateMinute,
                                                                label: !it ? '' : `${it.dateHour}:${it.dateMinute < 10 ? `0${it.dateMinute}` : it.dateMinute}`,
                                                                value: it
                                                            }
                                                        })}
                                                        value={
                                                            {
                                                                label: !selectedTime ? '' : `${selectedTime.dateHour}:${selectedTime.dateMinute < 10 ? `0${selectedTime.dateMinute}` : selectedTime.dateMinute}`,
                                                                value: -1
                                                            }
                                                        }
                                                        multi={false}
                                                        changecallback={(e) => { setSelectedTime({ ...selectedTime, dateHour: e.dateHour, dateMinute: e.dateMinute }); }}
                                                    />
                                                </Grid> :
                                                    <Grid item xs={12} className={classes.grid}>
                                                        <PredictiveComboBox
                                                            reduce={false}
                                                            label={"Horario"}
                                                            suggestions={mergeSchedule([], doctors.find(x => x._id === selectedDoctor.value).schedule, new Date(selectedDate.value)).map((it, ix) => {
                                                                return {
                                                                    key: ix,
                                                                    dateHour: it.dateHour,
                                                                    dateMinute: it.dateMinute,
                                                                    label: !it ? '' : `${it.dateHour}:${it.dateMinute < 10 ? `0${it.dateMinute}` : it.dateMinute}`,
                                                                    value: it
                                                                }
                                                            })}
                                                            value={
                                                                {
                                                                    label: !selectedTime ? '' : `${selectedTime.dateHour}:${selectedTime.dateMinute < 10 ? `0${selectedTime.dateMinute}` : selectedTime.dateMinute}`,
                                                                    value: -1
                                                                }
                                                            }
                                                            multi={false}
                                                            changecallback={(e) => { setSelectedTime({ ...selectedTime, dateHour: e.dateHour, dateMinute: e.dateMinute }); }}
                                                        />
                                                    </Grid>
                                                }

                                                {selectedDate &&
                                                    <Grid item xs={3} className={classes.grid}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox checked={isOverturn} onChange={() => { setIsOverturn(!isOverturn); }} />
                                                            }
                                                            label={"Sobreturno"} />
                                                    </Grid>
                                                }
                                                <Grid item xs={12}><br /></Grid>
                                                <Grid item xs={12}><br /></Grid>
                                                <Grid item xs={4} className={classes.grid}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox checked={needsReminder} onChange={() => { setNeedsReminder(!needsReminder) }} />
                                                        }
                                                        label={"Enviar al mail"} />
                                                </Grid>
                                                <Grid item xs={12} className={classes.grid}>
                                                    <Button
                                                        onClick={handleAccept}
                                                        color="primary"
                                                        variant="contained">
                                                        Aceptar
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Modal
                        open={patientModalShow}
                        style={{
                            overflow: 'scroll',
                            height: '100%',
                            display: 'block'
                        }}
                        onClose={() => { }}>
                        <PatientPopup viewDetails={handleViewDetails} patientId={selectedPatient && selectedPatient.value} openDialog={props.openDialog} onClose={() => setPatientModalShow(false)} onPatient={(p) => {
                            setPatients([...patients, p]);
                            setPatientModalShow(false);
                            setSelectedPatient({ value: p._id });
                        }} />
                    </Modal>
                </ Container>
            }
        />
    );
}
