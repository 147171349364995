import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  DialogActions,
  Button,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@material-ui/core/';
import PredictiveComboBox from './PredictiveText';
import { unfilteredschedule } from '../utils/unfilteredschedule';
import { numberToDay } from '../utils/dateToString';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  paper: {
    padding: theme.spacing(2),
  },
  container: {
    padding: theme.spacing(0),
  },
  grid: {
    margin: '10px',
  },
  textField: {
    width: '100%',
  },
  textFieldNotes: {
    width: '100%',
    height: '250px',
  },
  button: {
    margin: theme.spacing(1),
  },
  typography: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    margin: theme.spacing(1),
  },
}));

export default function AvailabilityDialogParticular(props) {
  const classes = useStyles();

  const [days, setDays] = React.useState([
    {
      day: 1,
      checked: false,
    },
    {
      day: 2,
      checked: false,
    },
    {
      day: 3,
      checked: false,
    },
    {
      day: 4,
      checked: false,
    },
    {
      day: 5,
      checked: false,
    },
    {
      day: 6,
      checked: false,
    },
    {
      day: 0,
      checked: false,
    },
  ]);

  const [inputValues, setInputValues] = React.useState({
    from: '',
    to: '',
    fromDate: new Date(),
    toDate: new Date(),
  });

  const date = new Date();
  const turns = unfilteredschedule(date);

  const [pageLoaded, setPageLoaded] = React.useState(false);

  useEffect(() => {
    if (pageLoaded) return;
    setPageLoaded(true);
    // eslint-disable-next-line
  }, []);

  function selectionChanged(property, value) {
    setInputValues({ ...inputValues, [property]: value });
  }

  function handleCancel() {
    props.close();
  }

  function handleAccept() {
    let chosenDays = [];

    for (let i = 0; i < days.length; i++) {
      const element = days[i];

      if (element.checked) chosenDays.push(element.day);
    }

    if (chosenDays.length < 1 || inputValues.from === '' || inputValues.to === '') return;

    props.close({
      day: [...chosenDays],
      range: [
        {
          from: inputValues.from.value,
          to: inputValues.to.value,
        },
      ],
      dateRange: [
        {
          from: inputValues.fromDate.getTime(),
          to: inputValues.toDate.getTime(),
        },
      ],
    });
  }

  function handleDayCheck(item) {
    let newDays = [...days];

    for (let i = 0; i < newDays.length; i++) {
      const element = newDays[i];

      if (element.day !== item.day) continue;

      element.checked = !item.checked;
      break;
    }

    setDays(newDays);
  }

  function handleDateFromChange(v) {
    setInputValues({ ...inputValues, fromDate: v });
  }

  function handleDateToChange(v) {
    setInputValues({ ...inputValues, toDate: v });
  }


  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container>
        <Grid item className={classes.grid}>
          <Grid container>
            <Grid item xs={12} className={classes.grid}>
              <Typography noWrap className={classes.typography}>
                Desde
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.grid}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="Desde"
                  disableToolbar
                  variant="static"
                  autoOk
                  orientation="landscape"
                  openTo="date"
                  value={inputValues.fromDate}
                  onChange={handleDateFromChange}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} className={classes.grid}>
              <Typography noWrap className={classes.typography}>
                Hasta
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.grid}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  label="Hasta"
                  disableToolbar
                  variant="static"
                  autoOk
                  orientation="landscape"
                  openTo="date"
                  value={inputValues.toDate}
                  onChange={handleDateToChange}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} className={classes.grid}>
              <PredictiveComboBox
                reduce={false}
                label={'Desde'}
                suggestions={turns.map((it, ix) => {
                  return {
                    key: ix,
                    label: `${it.dateHour}:${it.dateMinute < 10 ? `0${it.dateMinute}` : it.dateMinute
                      }`,
                    value: parseInt(
                      `${it.dateHour}${it.dateMinute < 10 ? `0${it.dateMinute}` : it.dateMinute}`,
                    ),
                  };
                })}
                multi={false}
                changecallback={v => {
                  selectionChanged('from', v);
                }}
              />
            </Grid>
            <Grid item xs={12} className={classes.grid}>
              <PredictiveComboBox
                reduce={false}
                label={'Hasta'}
                suggestions={turns.map((it, ix) => {
                  return {
                    key: ix,
                    label: `${it.dateHour}:${it.dateMinute < 10 ? `0${it.dateMinute}` : it.dateMinute
                      }`,
                    value: parseInt(
                      `${it.dateHour}${it.dateMinute < 10 ? `0${it.dateMinute}` : it.dateMinute}`,
                    ),
                  };
                })}
                multi={false}
                changecallback={v => {
                  selectionChanged('to', v);
                }}
              />
            </Grid>
            {days.map((item, idx) => (
              <Grid item xs={3} key={idx} className={classes.grid}>
                <FormControlLabel
                  control={
                    <Checkbox
                      key={idx}
                      checked={item.checked}
                      onChange={() => handleDayCheck(item)}
                      value={numberToDay(item.day)}
                    />
                  }
                  label={numberToDay(item.day)}
                />
              </Grid>
            ))}
            <br />
            <br />
            <Grid item xs={12} className={classes.grid}>
              <DialogActions>
                <Button onClick={handleCancel} color="primary">
                  Cancelar
                </Button>
                <Button onClick={handleAccept} color="primary">
                  Aceptar
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
