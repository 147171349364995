import React from 'react';
import ReactDOM from 'react-dom';
import { PDFDownloadLink, Document, Page, Text, Image, StyleSheet, View } from '@react-pdf/renderer'
import { Table, TableCell, TableBody, TableHeader, DataTableCell } from '@david.kucsai/react-pdf-table'
import { dateToString } from './dateToString';

const htmlToFormattedText = require("html-to-formatted-text");

const styles = StyleSheet.create({
    body: {
        paddingTop: 15,
        paddingBottom: 65,
        paddingHorizontal: 35,
    },
    dcm: {
        marginLeft: 12,
        fontSize: 24,
        textDecoration: "underline",
        //textAlign: 'center',
    },
    title: {
        fontSize: 24,
        marginLeft: 12,
        //fontFamily: 'Oswald'
    },
    author: {
        fontSize: 12,
        marginLeft: 12,
        marginBottom: 40,
    },
    doctor: {
        fontSize: 12,
        marginTop: 20,
        marginLeft: 12,
        textAlign: 'center',
    },
    doctorSchedule: {
        fontSize: 12,
        marginTop: 20,
        marginLeft: 12,
    },
    matricula: {
        fontSize: 12,
        marginLeft: 12,
        textAlign: 'center',
    },
    especialidad: {
        fontSize: 12,
        marginLeft: 12,
        textAlign: 'center',
    },
    address: {
        //textAlign: 'center',
        marginLeft: 12,
        fontSize: 12,
    },
    subtitle: {
        fontSize: 18,
        margin: 12,
        //fontFamily: 'Oswald'
    },
    info: {
        //textAlign: 'center',
        marginLeft: 12,
        fontSize: 18,
        //fontFamily: 'Oswald'
    },
    serv: {
        textAlign: 'center',
        marginLeft: 12,
        fontSize: 18,
        //fontFamily: 'Oswald'
    },
    add2: {
        textAlign: 'center',
        marginLeft: 12,
        fontSize: 14,
        //fontFamily: 'Oswald'
    },
    text: {
        margin: 12,
        fontSize: 14,
        textAlign: 'justify',
        //fontFamily: 'Times-Roman'
    },
    reporte: {
        margin: 12,
        fontSize: 12,
        textAlign: 'justify',
        //fontFamily: 'Times-Roman'
    },
    image: {
        marginVertical: 15,
    },
    header: {
        fontSize: 12,
        marginBottom: 20,
        textAlign: 'center',
        color: 'grey',

    },
    fecha: {
        marginTop: 5,
        fontSize: 13,
        marginLeft: 12,
        marginBottom: 5,
        //textAlign: "right",
    },
    paciente: {
        fontSize: 14,
        marginLeft: 12,
        marginBottom: 5,
        //fontFamily: 'Oswald'
    },
    listatratamientos: {
        fontSize: 14,
        marginLeft: 12,
        marginBottom: 5,
        //fontFamily: 'Oswald'
    },
    view: {
        marginBottom: 15,
        borderStyle: 'solid',
        borderWidth: 1,
    },
    view2: {
        marginTop: 5,
        marginBottom: 15,
        borderStyle: 'solid',
        borderWidth: 1,
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    },
    foot: {
        position: 'absolute',
        fontSize: 11,
        bottom: 42,
        left: 0,
        right: 0,
        textAlign: 'center',
    },
    foot2: {
        position: 'absolute',
        fontSize: 10,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
    },
    foot3: {
        position: 'absolute',
        fontSize: 10,
        bottom: 18,
        left: 0,
        right: 0,
        textAlign: 'center',
    },
});

const getResults = result => {

    let leftResult = result;

    const nextTable = result.indexOf('<table');
    let endNextTable = -1;

    let table = null;
    let prevText = null;

    if (nextTable > 0) {
        prevText = result.slice(0, nextTable);
    }

    if (nextTable >= 0) {
        endNextTable = result.indexOf('</table>') + '</table>'.length;
        table = result.slice(nextTable, endNextTable);
        leftResult = result.slice(endNextTable);
    }
    return { table, prevText, leftResult }

}

const convertArrayToObject = (array) => {
    const initialValue = {};
    array.forEach((element, idx) => {
        initialValue[idx] = element;
    });

    return initialValue;
};

const htmlToRenderTables = html => {

    const doc = new DOMParser().parseFromString(html, "text/html");

    const bodyChildren = doc.body.children;

    const datarows = [...bodyChildren[0].rows].map(row => {
        return convertArrayToObject(
            [...row.cells].map(cell => {
                return { text: htmlEntities(htmlToFormattedText(cell.innerHTML)), attributes: cell.attributes };
            }))
    });

    return <Table
    key={Date.now()}
        data={datarows}
    >
        <TableHeader>
            {Object.keys(datarows[0]).map((header, idx) => {

                let weighting = parseFloat(datarows[0][header].attributes[0].value.substring('width: '.length, datarows[0][header].attributes[0].value.indexOf(';')));

                return <TableCell key={idx} weighting={weighting / 100}></TableCell>
            })}
        </TableHeader>
        <TableBody>
            {Object.keys(datarows[0]).map((header, idx) => {
                let weighting = parseFloat(datarows[0][header].attributes[0].value.substring('width: '.length, datarows[0][header].attributes[0].value.indexOf(';')));
                return <DataTableCell key={idx} weighting={weighting} getContent={(r) => {
                    return r[header].text;
                }} />
            })}
        </TableBody>
    </Table>;
}

export const createAndDownloadPDFForMedicalExam = (data, filename) => {
    const theJsx = <Document ><Page style={styles.body}>
        <Image
            key="image1"
            style={styles.image}
            src="/static/media/all.8d5a05f2d6d444c3ba8c.png"
        />
        {
        /* 
        src="/static/media/all.8d5a05f2d6d444c3ba8c.png"
        <Text style={styles.serv}>
            Servicio de Imágenes
        </Text>
        <Text style={styles.add2}>
            Pasaje Bombay 2273, C.A.B.A - Tel: 4566-5734/4567-0975 - 4648-2579
        </Text>
        <Text style={styles.add2}>
            Wapp: +54911-6588-2625 / E-mail: turnos@diagnostico-devoto.com.ar
        </Text> */}
        <View style={styles.view2}>
            <Text style={styles.fecha} fixed>{`Fecha: ${dateToString(data.date)}`}</Text>
            <View style={{flexDirection:"row" }}>
                <Text style={styles.paciente}>{`Paciente: ${fixName(data.patient.lastname).trim() + ", " + fixName(data.patient.fullname)}`}</Text>
                <Text style={styles.paciente}>{`DNI: ${fixDNI(data.patient.uid)}`}</Text>
            </View>
            <Text style={styles.listatratamientos}>{`${data.treatmentReports.map(t => t.title).join(", ")}`}</Text>
        </View>
        {
            data.treatmentReports.map((x, idx) => {
                let results = [];

                let allResults = x.result;

                while (true) {
                    const nice = getResults(allResults);
                    if (nice.table === null) {
                        results.push(nice.leftResult);
                        break;
                    }

                    allResults = nice.leftResult;


                    if (nice.prevText !== null)
                        results.push(nice.prevText);

                    if (nice.table !== null)
                        results.push(nice.table);
                }

                let result = htmlToFormattedText(x.result);

                while (true) {
                    const newResult = htmlEntities(result);
                    if (newResult !== result)
                        result = newResult;
                    else
                        break;
                }

                return (
                    <View style={styles.view} key={idx}>
                        <Text key={-1} style={styles.subtitle}>
                            Estudio: {x.title}
                        </Text>

                        {results.map((y, idx) => {
                            if (y.indexOf('<table') > -1) {

                                while (true) {
                                    const newResult = htmlEntities(y);
                                    if (newResult !== y)
                                        y = newResult;
                                    else
                                        break;
                                }

                                return htmlToRenderTables(y)
                            }
                            else {

                                while (true) {
                                    const newResult = htmlEntities(y);
                                    if (newResult !== y)
                                        y = newResult;
                                    else
                                        break;
                                }
                                const rslt = y.trim() === '' ? '' : htmlToFormattedText(y)

                                return <Text key={idx} style={styles.reporte}>
                                    {rslt}
                                </Text>;
                            }
                        })}
                    </View>)
            })
        }

        {/* <Text style={styles.foot3} render={() => (
            
        )} />
        <Text style={styles.foot2} render={() => (
            
        )} /> */}
        <Text style={styles.foot} render={() => (
            "Diagnóstico Devoto - Servicio de Imágenes - Pasaje Bombay 2273, C.A.B.A - Tel:4648-2579\n\r" +
            "Wapp: +54911-3089-2625 / E-mail: turnos@diagnostico-devoto.com.ar\n\r" +
            'IMPORTANTE: NO OLVIDE TRAER ESTE ESTUDIO EN SU PRÓXIMA CONSULTA'
        )} />
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
        )} fixed />

        <View wrap={false}>
            <Text style={styles.doctor}>{data.doctor.fullname}</Text>
            <Text style={styles.especialidad}>{data.doctor.leyenda}</Text>
            <Text style={styles.matricula}>{"M.N.:" + data.doctor.matricula}</Text>
        </View>
    </Page >
    </Document >;


    createAndDownloadPDF(theJsx, filename);

}

export const createAndDownloadPDFForSchedule = ({ doctor, schedule, date }, filename) => {

    const filteredSchedule = schedule.filter(x => x.patient);

    createAndDownloadPDF(<Document ><Page style={styles.body}>
        <Text style={styles.dcm}>DIAGNOSTICO DEVOTO</Text>
        <Text style={styles.info}>
            Servicio de Imágenes
        </Text>
        <Text style={styles.address}>
            Pasaje Bombay 2273, C.A.B.A - Tel: 4566-5734/4567-0975 - 4648-2579
        </Text>
        <Text style={styles.address}>
            Wapp: +54911-6588-2625 / E-mail: turnos@diagnostico-devoto.com.ar
        </Text>
        <Text style={styles.fecha} fixed>{dateToString(date)}</Text>

        {doctor && <Text style={styles.doctorSchedule}>Agenda doctor: {doctor.fullname}</Text>}



        {filteredSchedule.map((item) =>

            <View warp={false} style={{ width: "100%", marginTop: 3, border: '1', borderStyle: 'solid', borderColor: 'black', }}>

                <Text
                    style={{ width: "100%", fontSize: 13, }}
                >
                    Hora: {`${item.dateHour < 10 ? `0${item.dateHour}` : item.dateHour}:${item.dateMinute < 10 ? `0${item.dateMinute}` : item.dateMinute}
                     `}
                    Doctor: {`${(item.doctor.lastname !== undefined ? item.doctor.lastname + ", " : "") + item.doctor.fullname} 
                    `}
                    Paciente: {`${(item.patient.lastname !== undefined ? item.patient.lastname + ", " : "") + item.patient.fullname} 
                    `}
                    Tratamientos: {`${item.treatments ? item.treatments.map(x => x.name).join(', ') : ''}`}
                </Text>
            </View >)}


        <Text style={styles.foot2} render={() => (
            'Devoto Centro Médico - Servicio de Imágenes - Pasaje Bombay 2273,C.A.B.A-Tel:4566-5734/4567-0975'
        )} fixed />
        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
        )} fixed />
    </Page >
    </Document >, filename);

}
export const fixDNI = (str) => {
    if (str === undefined || str === null) {
    return "--";
    }else {
        return str;   
    }
}
export const fixName = (str) => {

    if (str === undefined || str === null)
        return str;

    var splitStr = str.toLowerCase().split(' ');
    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    // Directly return the joined string
    return splitStr.join(' ');
}




const htmlEntities = (str) => {
    return String(str).replace('&ntilde;', 'ñ')
        .replace('&Ntilde;', 'Ñ')
        .replace('&amp;', '&')
        .replace('&Ntilde;', 'Ñ')
        .replace('&ntilde;', 'ñ')
        .replace('&Ntilde;', 'Ñ')
        .replace('&Agrave;', 'À')
        .replace('&Aacute;', 'Á')
        .replace('&Acirc;', 'Â')
        .replace('&Atilde;', 'Ã')
        .replace('&Auml;', 'Ä')
        .replace('&Aring;', 'Å')
        .replace('&AElig;', 'Æ')
        .replace('&Ccedil;', 'Ç')
        .replace('&Egrave;', 'È')
        .replace('&Eacute;', 'É')
        .replace('&Ecirc;', 'Ê')
        .replace('&Euml;', 'Ë')
        .replace('&Igrave;', 'Ì')
        .replace('&Iacute;', 'Í')
        .replace('&Icirc;', 'Î')
        .replace('&Iuml;', 'Ï')
        .replace('&ETH;', 'Ð')
        .replace('&Ntilde;', 'Ñ')
        .replace('&Ograve;', 'Ò')
        .replace('&Oacute;', 'Ó')
        .replace('&Ocirc;', 'Ô')
        .replace('&Otilde;', 'Õ')
        .replace('&Ouml;', 'Ö')
        .replace('&Oslash;', 'Ø')
        .replace('&Ugrave;', 'Ù')
        .replace('&Uacute;', 'Ú')
        .replace('&Ucirc;', 'Û')
        .replace('&Uuml;', 'Ü')
        .replace('&Yacute;', 'Ý')
        .replace('&THORN;', 'Þ')
        .replace('&szlig;', 'ß')
        .replace('&agrave;', 'à')
        .replace('&aacute;', 'á')
        .replace('&acirc;', 'â')
        .replace('&atilde;', 'ã')
        .replace('&auml;', 'ä')
        .replace('&aring;', 'å')
        .replace('&aelig;', 'æ')
        .replace('&ccedil;', 'ç')
        .replace('&egrave;', 'è')
        .replace('&eacute;', 'é')
        .replace('&ecirc;', 'ê')
        .replace('&euml;', 'ë')
        .replace('&igrave;', 'ì')
        .replace('&iacute;', 'í')
        .replace('&icirc;', 'î')
        .replace('&iuml;', 'ï')
        .replace('&eth;', 'ð')
        .replace('&ntilde;', 'ñ')
        .replace('&ograve;', 'ò')
        .replace('&oacute;', 'ó')
        .replace('&ocirc;', 'ô')
        .replace('&otilde;', 'õ')
        .replace('&ouml;', 'ö')
        .replace('&oslash;', 'ø')
        .replace('&ugrave;', 'ù')
        .replace('&uacute;', 'ú')
        .replace('&ucirc;', 'û')
        .replace('&uuml;', 'ü')
        .replace('&yacute;', 'ý')
        .replace('&thorn;', 'þ')
        .replace('&yuml;', 'ÿ')
        .replace('&lt;', '<')
        .replace('&gt;', '>');

}


const createAndDownloadPDF = (doc, filename) => {
    /*Font.register({
        family: 'Oswald',
        src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
    });*/

    const divId = "renderedPDF";
    const link = (
        <div id={divId}>
            <PDFDownloadLink document={doc} fileName={filename}></PDFDownloadLink>
        </div>
    )
    const elem = document.createElement('div')
    document.getElementById('root').appendChild(elem)
    ReactDOM.render(link, elem)
    setTimeout(() => {
        document.getElementById(divId).children[0].click()
        elem.remove()
    }, 100);
}