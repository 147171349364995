import axios from 'axios';

export const getAllHolidays = async () => {
  try {
    const res = await axios.get(`/holidays/`);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const getHoliday = async id => {
  try {
    const res = await axios.get(`/holidays/${id}`);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const createHoliday = async holiday => {
  try {
    const res = await axios.post(`/holidays/`, holiday);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const modifyHoliday = async holiday => {
  try {
    const res = await axios.put(`/holidays/`, holiday);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    //console.log(JSON.stringify(err));
    return { success: false, message: err.response.data };
  }
};

export const changePassword = async (_id, password) => {
  try {
    const res = await axios.put(`/holidays/changePassword`, { _id, password });
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const deleteHoliday = async id => {
  try {
    const res = await axios.delete(`/holidays/${id}`);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};
