import axios from 'axios';

export const getTreatmentsFeesByDoctor = async id => {
  try {
    const res = await axios.get(`/fees/t/${id}`);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};


export const getHourFeeByDoctor = async id => {
  try {
    const res = await axios.get(`/fees/h/${id}`);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const createTreatmentFee = async treatmentFee => {
  try {
    const res = await axios.post(`/fees/t/`, treatmentFee);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const createHourFee = async hourFee => {
  try {
    const res = await axios.post(`/fees/h/`, hourFee);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const modifyTreatmentFee = async treatmentFee => {
  try {
    const res = await axios.put(`/fees/t/`, treatmentFee);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const modifyHourFee = async hourFee => {
  try {
    const res = await axios.put(`/fees/h/`, hourFee);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const deleteTreatmentFee = async id => {
  try {
    const res = await axios.delete(`/fees/t/${id}`);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const deleteHourFee = async id => {
  try {
    const res = await axios.delete(`/fees/h/${id}`);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};