import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Select from "react-select";
import {
  Container,
  Grid,
  IconButton,
  Typography,
  Tooltip,
  CircularProgress,
} from "@material-ui/core/";
import "./css/TreatmentsPricesEdit.css";
import AppContainer from "./AppContainer";
import { getAllPrepaidTreatments } from "../actions/treatmentsPrepaid";
import { Add, ArrowBack } from "@material-ui/icons";
import TreatmentsPricesListByPrepaid from "./TreatmentPricesListByPrepaid";
import { getPrepaids } from "../actions/prepaids";
import { mobileCheck } from "../utils/mobileCheck";
import TreatmentPricesDialog from "./TreatmentsPricesDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    padding: theme.spacing(2),
  },
  container: {
    padding: theme.spacing(mobileCheck() ? 0 : 1),
  },
  grid: {
    margin: "10px",
  },
  textField: {
    width: "100%",
  },
  button: {
    margin: theme.spacing(1),
  },
  typography: {
    margin: theme.spacing(1),
  },
  progress: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

export default function TreatmentsPricesEdit(props) {
  const classes = useStyles();
  const [prepaids, setPrepaids] = useState([]);
  const [dialog, setDialog] = useState({ visible: false, type: null });
  const [dataLoading, setDataLoading] = useState(true);
  const [data, setData] = useState({
    prepaid: null,
    treatments: [],
    percentageAdj: [],
  });

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const ppds = await getPrepaids();
    //console.log(ppds);
    if (!ppds.success) {
      if (ppds.message.error.indexOf("Token") !== -1) props.tokenExpired();
      props.openSnack(ppds.message.error, "error");
      return;
    }
    setPrepaids(
      ppds.data.map((it, ix) => {
        return {
          label: it.name,
          value: it._id,
        };
      })
    );
    setDataLoading(false);
  }
  const handleSelection = async (value) => {
    setDataLoading(true);
    const tp = await getAllPrepaidTreatments(value.value);
    if (!tp.success) {
      if (tp.message.error.indexOf("Token") !== -1) props.tokenExpired();
      props.openSnack(tp.message.error, "error");
      return;
    }

    setData({
      prepaid: value,
      treatments: tp.prepaidPrices.sort(),
      percentageAdj: tp.percentageAdjustments,
    });
    setDataLoading(false);
  };

  const handleAddAdjustment = () => {
    if (data.prepaid) {
      setDialog({ visible: true, type: "percentage" });
    } else {
      setDialog({ visible: true, type: "error" });
    }
    //props.openSnack("Test", "error");
  };
  return (
    <AppContainer
      title={"Estudios"}
      history={props.history}
      content={
        !dataLoading ? (
          <div>
            <div
              style={{
                textAlign: "right",
              }}
            >
              <Tooltip title="Volver">
                <IconButton onClick={() => props.history.push("/treatments")}>
                  <ArrowBack color="primary" />
                </IconButton>
              </Tooltip>
            </div>
            <Container maxWidth="lg" className={classes.container}>
              <Grid container spacing={1}>
                {!mobileCheck() && (
                  <Grid item xs={1} className={classes.grid} />
                )}
                <Grid
                  item
                  xs={!mobileCheck() ? 10 : 12}
                  className={classes.grid}
                >
                  <Grid
                    container
                    spacing={3}
                    justifyContent="flex-start"
                    alignItems="flex-start"
                  >
                    <Grid item xs={12} lg={6}>
                      <Typography>Seleccione la prepaga </Typography>
                      <Select
                        options={prepaids}
                        onChange={handleSelection}
                        value={data.prepaid}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container alignItems="center">
                        <Grid item>
                          <div className="agregarAumento">
                            Agregar aumento porcentual
                          </div>
                        </Grid>
                        <Grid item>
                          <Tooltip title="Agregar aumento porcentual">
                            <IconButton onClick={handleAddAdjustment}>
                              <Add color="primary" />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <TreatmentsPricesListByPrepaid
                      data={data}
                      setData={setData}
                      setDataLoading={setDataLoading}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <TreatmentPricesDialog
                dialog={dialog}
                setDialog={setDialog}
                data={data}
                openSnack={props.openSnack}
                openDialog={props.openDialog}
                tokenExpired={props.tokenExpired}
                setData={setData}
              />
            </Container>
          </div>
        ) : (
          <div
            style={{
              marginTop: "3%",
              textAlign: "center",
              width: "100%",
            }}
          >
            <CircularProgress />{" "}
          </div>
        )
      }
    />
  );
}
