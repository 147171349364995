import axios from 'axios';

export const getAllMedicalExams = async () => {
  try {
    const res = await axios.get(`/medicalExams/`);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const getMedicalExam = async id => {
  try {
    const res = await axios.get(`/medicalExams/${id}`);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};


export const getMedicalExamsByPatientId = async id => {
  try {
    const res = await axios.get(`/medicalExams/patient/${id}`);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const createMedicalExam = async medicalExam => {
  try {
    const res = await axios.post(`/medicalExams/`, medicalExam);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const modifyMedicalExam = async medicalExam => {
  try {
    const res = await axios.put(`/medicalExams/`, medicalExam);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const deleteMedicalExam = async id => {
  try {
    const res = await axios.delete(`/medicalExams/${id}`);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};
