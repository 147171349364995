import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, ListItemText, ListItem, InputLabel } from '@material-ui/core';
import { isAdmin } from '../utils/authUtils';

const useStyles = makeStyles(theme => ({
  prepaidName: {
    width: '50%',
    height: '40px',
  },
  treatmentPrice: {
    width: '25%',
  },
  price: {
    width: '25%',
  },
}));

export default function DoctorPaymentPerTreatmentListView(props) {
  const classes = useStyles();

  return (
    <div>
      <ListItem className={classes.listItem}>
        <ListItemText
          id="prepaid"
          disabled={!props.enabled}
          className={classes.prepaidName}
          primary={props.prepaid.name}
        />
        {isAdmin() && (
          <div className={classes.treatmentPrice}>
            <InputLabel shrink htmlFor="price">
              Ganancia
            </InputLabel>
            <ListItemText
              id="price"
              disabled={!props.enabled}
              primary={props.treatmentPrice && props.treatmentPrice.price}
            />
          </div>
        )}
        {props.enabled ? (
          props.editable ? (
            <TextField
              className={classes.price}
              disabled={!props.enabled}
              InputProps={{
                readOnly: !props.editable,
              }}
              label={'Honorario'}
              name={'price'}
              type="number"
              value={props.price}
              onChange={e => {
                if (!props.editable) return;
                props.changed(
                  props.treatmentPrice.treatmentType,
                  props.prepaid._id,
                  e.target.value,
                );
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          ) : (
            <div className={classes.price}>
              <InputLabel shrink htmlFor="honorario">
                Honorario
              </InputLabel>
              <ListItemText
                id="honorario"
                disabled={!props.enabled}
                primary={props.treatmentPrice && props.treatmentPrice.price}
              />
            </div>
          )
        ) : (
          <ListItemText disabled={true} className={classes.price} primary={''} />
        )}
      </ListItem>
    </div>
  );
}
