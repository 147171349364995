import { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  getAllPrepaidTreatments,
  addPrepaidPriceAdjustment,
} from "../actions/treatmentsPrepaid";
import { mobileCheck } from "../utils/mobileCheck";
export default function TreatmentPricesDialog(props) {
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [dataLoading, setDataLoading] = useState(false);
  const handleClose = () => {
    props.setDialog({ ...props.dialog, visible: false });
  };
  const handleDateChange = (date) => {
    setCalendarDate(
      new Date(date.getFullYear(), date.getMonth(), date.getDate())
    );
  };
  const handleAddPrices = () => {
    const percentage = document.getElementById("percentage").value;
    if (percentage) {
      const from = calendarDate.getTime();
      const treatments = props.data.treatments.map((treatment) => {
        const price =
          Math.round(
            100 * treatment.price.slice(-1)[0].price * (1 + percentage / 100)
          ) / 100;
        return { treatmentType: treatment._id, price: price };
      });
      addAdjustment(props.data.prepaid.value, from, percentage, treatments);
      props.setDialog({ ...props.dialog, visible: false });
    } else {
      props.openSnack("Por favor ingrese un aumento", "error");
    }
  };
  async function addAdjustment(prepaid, from, percentage, treatments) {
    setDataLoading(true);
    const result = await addPrepaidPriceAdjustment({
      prepaid,
      from,
      percentage,
      treatments,
    });
    if (result.success) {
      props.openSnack(
        "Los precios fueron modificados exitosamente.",
        "success"
      );
    } else {
      if (result.message.error.indexOf("Token") !== -1) props.tokenExpired();
      props.openSnack(result.message.error, "error");
    }
    const tp = await getAllPrepaidTreatments(prepaid);
    if (!tp.success) {
      if (tp.message.error.indexOf("Token") !== -1) props.tokenExpired();
      props.openSnack(tp.message.error, "error");
      return;
    }
    props.setData((prevData) => {
      return {
        ...prevData,
        percentageAdj: tp.percentageAdjustments,
        treatments: tp.prepaidPrices.sort(),
      };
    });

    setDataLoading(false);
  }

  if (props.dialog.type === "error") {
    return (
      <Dialog
        open={props.dialog.visible}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Prepaga no seleccionada"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Por favor seleccione una prepaga
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleClose();
            }}
            autoFocus
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else if (props.dialog.type === "percentage") {
    if (dataLoading) {
      return (
        <div
          style={{
            marginTop: "3%",
            textAlign: "center",
            width: "100%",
          }}
        >
          <CircularProgress />{" "}
        </div>
      );
    } else {
      return (
        <Dialog
          open={props.dialog.visible}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`Aumento porcentual de ${props.data.prepaid.label}`}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Por favor agregue un aumento porcentual junto con su fecha de
              inicio.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="percentage"
              label="Aumento (%)"
              type="number"
              variant="standard"
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                autoOk
                orientation={mobileCheck() ? "vertical" : "landscape"}
                variant="static"
                openTo="date"
                value={calendarDate}
                onChange={handleDateChange}
              />
            </MuiPickersUtilsProvider>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                handleClose();
              }}
              autoFocus
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleAddPrices();
              }}
              autoFocus
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  } else {
    handleClose();
    return null;
  }
}
