import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Container, Grid } from '@material-ui/core/';
import AppContainer from './AppContainer';
import { mobileCheck } from '../utils/mobileCheck';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    paper: {
        padding: theme.spacing(2),
    },
    container: {
        padding: theme.spacing(mobileCheck() ? 0 : 4),
    },
    grid: {
        margin: '10px',
        width: '100%',
    },
    textField: {
        width: '100%',
    },
    button: {
        margin: theme.spacing(1),
    },
}));

export default function NotFoundView(props) {
    const classes = useStyles();

    return (
        <AppContainer title={"Inicio"} history={props.history} content={
            <Container maxWidth="lg" className={classes.container}>
                <Grid container spacing={3}>
                    {!mobileCheck() && <Grid item xs={1} />}
                    <Grid item xs={!mobileCheck() ? 10 : 12}>
                        <Paper className={classes.paper}>
                            <Grid container>
                                Pagina No Encontrada
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        } />
    );
}
