import React, { useEffect } from 'react';
import { remove } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  Container,
  Grid,
  TextField,
  IconButton,
  Typography,
  Checkbox,
  Chip,
  FormControlLabel,
  Tooltip,
  List,
  ListSubheader,
} from '@material-ui/core/';
import AppContainer from './AppContainer';
import { getUser, createUser, modifyUser, deleteUser, changePassword } from '../actions/users';
import { Edit, Delete, Done, Close } from '@material-ui/icons';
import { getRoles } from '../actions/roles';
import Availability from './Availability';
import DoctorPaymentPerTreatmentTitle from './DoctorPaymentPerTreatmentTitle';
import { isAdmin, isDoctor } from '../utils/authUtils';
import { getPrepaids } from '../actions/prepaids';
import { getAllTreatmentTypes, modifyTreatmentType } from '../actions/treatmentTypes';
import { getAllTreatmentPrices } from '../actions/treatmentTypePrices';
import PredictiveComboBox from './PredictiveText';
import DoctorPaymentPerTreatmentListView from './DoctorPaymentPerTreatmentListView';
import { getTreatmentsFeesByDoctor, getHourFeeByDoctor } from '../actions/doctorFees';
import { mobileCheck } from '../utils/mobileCheck';
import DoctorTreatmentTypes from './DoctorTreatmentTypes';
import CustomListItem from './CustomListItem';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  paper: {
    padding: theme.spacing(2),
  },
  container: {
    padding: theme.spacing(mobileCheck() ? 0 : 4),
  },
  grid: {
    margin: '10px',
  },
  textField: {
    width: '100%',
  },
  button: {
    margin: theme.spacing(1),
  },
  typography: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    margin: theme.spacing(1),
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
}));

export default function User(props) {
  const classes = useStyles();
  /* eslint-disable*/

  const feeTypeSuggestions = [
    {
      key: 0,
      label: 'Sin configurar',
      value: -1,
    },
    {
      key: 0,
      label: 'Por hora',
      value: 0,
    },
    {
      key: 1,
      label: 'Por Estudio',
      value: 1,
    },
  ];

  const [objectData, setObjectData] = React.useState({
    username: '',
    fullname: '',
    mail: '',
    password: '',
    roles: [],
    active: true,
    notes: '',
    schedule: {},
  });

  const [mode, setMode] = React.useState({
    key: 0,
    label: 'Sin configurar',
    value: -1,
  });

  const [possibleRoles, setPossibleRoles] = React.useState([]);

  const [pageLoaded, setPageLoaded] = React.useState(false);

  const [prepaids, setPrepaids] = React.useState([]);
  const [treatments, setTreatments] = React.useState([]);
  const [treatmentPrices, setTreatmentPrices] = React.useState([]);
  const [treatmentFees, setTreatmentFees] = React.useState([]);
  const [hourFee, setHourFee] = React.useState('');
  const [treatmentFeesOptions, setTreatmentFeesOptions] = React.useState([]);
  const [password, setPassword] = React.useState('********');
  const [passwordChanged, setPasswordChanged] = React.useState(false);

  const [state, setState] = React.useState({
    loaded: false,
    create: false,
    edit: false,
    rolesEdit: true,
    addNewTreatment: false,
  });

  useEffect(() => {
    if (pageLoaded) return;
    setPageLoaded(true);

    if (props.match.params.id) fetchData(props.match.params.id);
    else setState({ ...state, create: true, edit: true });

    fetchRoles();
  }, []);
  /* eslint-enable*/

  useEffect(() => {

    //console.log(password);

    if (password === '********') {
      //console.log("nope");
      return;
    }

    //console.log("password changed", password)

    setPasswordChanged(true);

  }, [password])

  async function fetchData(id) {
    const userData = await getUser(id);

    if (!userData.success) {
      if (userData.message.error.indexOf('Token') !== -1) props.tokenExpired();
      props.openSnack(userData.message.error, 'error');
      return;
    }

    setObjectData({ ...userData.data });

    let m = userData.data.feeType
      ? feeTypeSuggestions[userData.data.feeType + 1]
      : feeTypeSuggestions[0];
    setMode(m);

    const treatments = await getAllTreatmentTypes();

    if (!treatments.success) {
      if (treatments.message.error.indexOf('Token') !== -1) props.tokenExpired();
      props.openSnack(treatments.message.error, 'error');

      return;
    }

    setTreatments(treatments.data);

    const treatmentPrices = await getAllTreatmentPrices();

    if (!treatmentPrices.success) {
      if (treatmentPrices.message.error.indexOf('Token') !== -1) props.tokenExpired();
      props.openSnack(treatmentPrices.message.error, 'error');

      return;
    }

    setTreatmentPrices(treatmentPrices.data);

    const prepaids = await getPrepaids();

    if (!prepaids.success) {
      if (prepaids.message.error.indexOf('Token') !== -1) props.tokenExpired();
      props.openSnack(prepaids.message.error, 'error');

      return;
    }

    setPrepaids(prepaids.data);

    const tFees = await getTreatmentsFeesByDoctor(id);

    if (!tFees.success) {
      if (tFees.message.error) props.openSnack(tFees.message.error, 'error');
      else props.openSnack(tFees.message, 'error');
      return;
    }

    setTreatmentFees(tFees.data);

    const hFee = await getHourFeeByDoctor(id);

    if (!hFee.success) {
      if (hFee.message.error) props.openSnack(hFee.message.error, 'error');
      else props.openSnack(hFee.message, 'error');
      return;
    }

    setHourFee(hFee.data);

    treatmentFeesBuilder(treatments.data, prepaids.data, treatmentPrices.data, tFees.data);
  }

  function treatmentFeesBuilder(treatments, prepaids, treatmentPrices, treatmentFees) {
    let lol =
      treatments &&
      treatments.map(tit => {
        const ftps = treatmentPrices.filter(it => it.treatmentType === tit._id);
        return (
          prepaids &&
          prepaids.map(pit => {
            return {
              treatmentFee: treatmentFees.filter(it => tit._id === it.treatmentType)[0],
              treatment: tit,
              prepaid: pit,
              treatmentPrice: ftps.filter(it => it.prepaid === pit._id)[0],
            };
          })
        );
      });

    setTreatmentFeesOptions(lol);
  }

  async function fetchRoles() {
    const rolesData = await getRoles();
    setPossibleRoles([...rolesData.data]);

    if (!objectData.role) return;
  }

  function handleEdit() {
    setState({ ...state, edit: true });
  }

  function handleCancelEdit() {
    if (state.create) {
      props.history.push('/users');
      return;
    }
    props.history.push(`/users`);
  }

  async function handleDoneEdit() {
    let result = {};
    if (state.create) {
      result = await createUser({ ...objectData, feeType: mode.value, password });
      props.history.push('/user/' + result.data[0]._id);
    } else {
      result = await modifyUser({ ...objectData, feeType: mode.value });

      if (passwordChanged)
        await changePassword(props.match.params.id, password);

    }

    if (result.success)
      window.location.reload();
  }

  function handleDeleteUser() {
    if (!state.create)
      props.openDialog(
        'Confirmación',
        'Seguro que desea eliminar este usuario?',
        objectData,
        handleDeleteCallback,
      );
  }

  async function handleDeleteCallback(confirmed, item) {
    if (!confirmed) return;
    const result = await deleteUser(item._id);
    if (!result.success) {
      if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
      props.openSnack(result.message.error, 'error');
      return;
    }
    props.history.push('/users');
  }

  function handleRoleModification(event) {
    const data = [...objectData.roles];

    const find = objectData.roles.indexOf(event.target.value);
    if (find === -1) data.push(event.target.value);
    else data.splice(find, 1);

    setObjectData({ ...objectData, roles: data });
  }

  function handleChange(event) {
    setObjectData({
      ...objectData,
      [event.target.name]: event.target.value,
    });
  }

  function handleAvailabilityRemove(availability, index) {
    if (!availability) return;


    props.openDialog(
      'Confirmación',
      'Seguro que desea eliminar esta disponibilidad horaria?',
      { ...availability, index },
      handleAvailabilityRemoveConfirmed,
    );
  }

  function handleAvailabilityRemoveConfirmed(state, availability) {
    if (!state) return;
    let newObjectData = { ...objectData };
    newObjectData.schedule.availability.splice(availability.index, 1);
    setObjectData({ ...newObjectData });
  }

  function handleAvailabilityAdd(change) {
    if (!change) return;

    let newObjectData = { ...objectData };
    console.log(newObjectData.schedule.availability);
    if (newObjectData.schedule.availability === undefined || newObjectData.schedule.availability === null)
      newObjectData.schedule.availability = [];


    console.log(newObjectData.schedule.availability);
    newObjectData.schedule.availability.push(change);

    setObjectData({ ...newObjectData });
  }

  function handleAvailabilityExceptionRemove(availabilityException) {
    if (!availabilityException) return;

    props.openDialog(
      'Confirmación',
      'Seguro que desea eliminar esta excepcion de disponibilidad horaria?',
      availabilityException,
      handleAvailabilityExceptionRemoveConfirmed,
    );
  }

  function handleAvailabilityExceptionRemoveConfirmed(state, availabilityException) {
    if (!state) return;

    let newObjectData = { ...objectData };

    let idx = -1;

    for (let i = 0; i < newObjectData.schedule.exceptions.length; i++) {
      const element = newObjectData.schedule.exceptions[i];

      if (JSON.stringify(element) !== JSON.stringify(availabilityException)) continue;

      idx = i;
      break;
    }

    if (idx === -1) return;

    newObjectData.schedule.exceptions.splice(idx, 1);

    setObjectData({ ...newObjectData });
  }

  function handleAvailabilityExceptionAdd(change) {
    if (!change) return;

    let newObjectData = { ...objectData };

    if (!newObjectData.schedule.exceptions)
      newObjectData.schedule.exceptions = [];

    newObjectData.schedule.exceptions.push(change);

    setObjectData({ ...newObjectData });
  }

  function handleAvailabilityParticularRemove(availabilityParticular) {
    if (!availabilityParticular) return;

    props.openDialog(
      'Confirmación',
      'Seguro que desea eliminar este periodo de atención de disponibilidad horaria?',
      availabilityParticular,
      handleAvailabilityParticularRemoveConfirmed,
    );
  }

  function handleAvailabilityParticularRemoveConfirmed(state, availabilityParticular) {
    if (!state) return;

    let newObjectData = { ...objectData };

    let idx = -1;

    for (let i = 0; i < newObjectData.schedule.particulars.length; i++) {
      const element = newObjectData.schedule.particulars[i];

      if (JSON.stringify(element) !== JSON.stringify(availabilityParticular)) continue;

      idx = i;
      break;
    }

    if (idx === -1) return;

    newObjectData.schedule.particulars.splice(idx, 1);

    setObjectData({ ...newObjectData });
  }

  function handleAvailabilityParticularAdd(change) {
    if (!change) return;

    let newObjectData = { ...objectData };

    if (!newObjectData.schedule.particulars)
      newObjectData.schedule.particulars = [];

    newObjectData.schedule.particulars.push(change);

    setObjectData({ ...newObjectData });
  }

  function handleModeChange(mode) {
    setMode(mode);
  }

  function handleTreatmentFeeChange(index) {
    let currOpts = [...treatmentFeesOptions];

    currOpts[index].forEach(it => {
      if (it.treatmentFee) it.treatmentFee = undefined;
      else it.treatmentFee = {};
    });

    setTreatmentFeesOptions([...currOpts]);
  }

  function handleFeeChange(tindex, findex, value) {
    let currOpts = [...treatmentFeesOptions];

    currOpts[tindex][findex].treatmentFee.fee = value;

    setTreatmentFeesOptions([...currOpts]);
  }


  async function handleTreatmentChange(treatment, added) {
    const newTreatments = [...treatments];

    for (let i = 0; i < newTreatments.length; i++) {
      const element = newTreatments[i];

      if (element._id === treatment._id) {
        if (added) {
          element.doctors.push(objectData._id);
        } else {
          remove(element.doctors, v => v === objectData._id)
        }

        const result = await modifyTreatmentType(element);

        if (!result.success) {
          props.openSnack("Error configurando el tratamiento", 'error');
          return;
        }

        props.openSnack("Actualización correcta", 'success');
      }
    }

    setTreatments(newTreatments);
  }


  async function handleTreatmentTimeChange(treatment, time) {
    setObjectData({
      ...objectData,
      turnsTaken: {
        ...objectData.timeTaken,
        [treatment._id]: {
          timeTaken: time
        },
      }
    })
  }

  return (
    <div>
      <AppContainer
        title={'Usuario'}
        history={props.history}
        content={
          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
              {!mobileCheck() && <Grid item xs={1} className={classes.grid} />}
              <Grid item xs={!mobileCheck() ? 10 : 12} className={classes.grid}>
                <Grid container>
                  <Grid item className={classes.grid}>
                    <Typography variant="h6" noWrap className={classes.typography}>
                      Información
                    </Typography>
                  </Grid>
                  <Grid item className={classes.grid}>
                    {!state.edit && (
                      <Tooltip title="Editar">
                        <IconButton onClick={handleEdit}>
                          <Edit />
                        </IconButton>
                      </Tooltip>
                    )}
                    {state.edit && (
                      <Tooltip title="Cancelar">
                        <IconButton onClick={handleCancelEdit}>
                          <Close />
                        </IconButton>
                      </Tooltip>
                    )}
                    {state.edit && (
                      <Tooltip title="Aceptar">
                        <IconButton onClick={handleDoneEdit}>
                          <Done color="primary" />
                        </IconButton>
                      </Tooltip>
                    )}
                    {state.edit && (
                      <Tooltip title="Eliminar">
                        <IconButton onClick={handleDeleteUser}>
                          <Delete color="error" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
                <Paper className={classes.paper}>
                  <Grid container>
                    <Grid item xs={8} className={classes.grid}>
                      <TextField
                        InputProps={{
                          readOnly: !state.edit,
                        }}
                        label={'Usuario'}
                        className={classes.textField}
                        value={objectData.username}
                        name={'username'}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={e => {
                          if (!state.edit) return;
                          handleChange(e);
                        }}
                      />
                    </Grid>
                    <Grid item xs={8} className={classes.grid}>
                      <TextField
                        InputProps={{
                          readOnly: !state.edit,
                        }}
                        label={'Nombre Completo'}
                        className={classes.textField}
                        value={!!objectData.fullname ? objectData.fullname : ''}
                        name={'fullname'}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={e => {
                          if (!state.edit) return;
                          handleChange(e);
                        }}
                      />
                    </Grid>
                    {isDoctor(objectData) && <Grid item xs={8} className={classes.grid}>
                      <TextField
                        InputProps={{
                          readOnly: !state.edit,
                        }}
                        label={'Matricula'}
                        className={classes.textField}
                        value={objectData.matricula}
                        name={'matricula'}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={e => {
                          if (!state.edit) return;
                          handleChange(e);
                        }}
                      />
                    </Grid>}
                    {isDoctor(objectData) && <Grid item xs={8} className={classes.grid}>
                      <TextField
                        InputProps={{
                          readOnly: !state.edit,
                        }}
                        label={'Especialidad'}
                        className={classes.textField}
                        value={objectData.leyenda}
                        name={'leyenda'}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={e => {
                          if (!state.edit) return;
                          handleChange(e);
                        }}
                      />
                    </Grid>}
                    <Grid item xs={8} className={classes.grid}>
                      <TextField
                        InputProps={{
                          readOnly: !state.edit,
                        }}
                        label={'Mail'}
                        className={classes.textField}
                        value={objectData.mail}
                        name={'mail'}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={e => {
                          if (!state.edit) return;
                          handleChange(e);
                        }}
                      />
                    </Grid>
                    <Grid container>
                      {isAdmin() && state.edit && (
                        <Grid item xs={8} className={classes.grid}>
                          {possibleRoles.map((r, i) => (
                            <FormControlLabel
                              key={i}
                              control={
                                <Checkbox
                                  key={i}
                                  checked={objectData.roles.indexOf(r) !== -1}
                                  onChange={handleRoleModification}
                                  value={r}
                                />
                              }
                              label={r}
                            />
                          ))}
                        </Grid>
                      )}

                      {(!isAdmin() || !state.edit) && (
                        <Grid item xs={8} className={classes.grid}>
                          {objectData.roles.map((v, i) => (
                            <Chip key={i} label={v} className={classes.chip} />
                          ))}
                        </Grid>
                      )}
                    </Grid>
                    <Grid item xs={8} className={classes.grid}>
                      <TextField
                        InputProps={{
                          readOnly: !state.edit,
                        }}
                        label={'Passsword'}
                        className={classes.textField}
                        value={password}
                        name={'password'}
                        type="password"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={e => {
                          if (!state.edit) return;
                          setPassword(e.target.value);
                        }}
                      />
                    </Grid>
                    <Grid item xs={8} className={classes.grid}>
                      <TextField
                        InputProps={{
                          readOnly: !state.edit,
                        }}
                        label={'Notas'}
                        className={classes.textField}
                        value={!!objectData.notes ? objectData.notes : ''}
                        name={'notes'}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={e => {
                          if (!state.edit) return;
                          handleChange(e);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Paper>
                {objectData && objectData.roles.indexOf('Doctor/a') !== -1 && (
                  <div>
                    <Grid container className={classes.grid} spacing={3}>
                      <Typography variant="h6" noWrap className={classes.typography}>
                        Disponibilidad atención
                      </Typography>
                    </Grid>
                    <Paper className={classes.paper}>
                      <Availability
                        schedule={objectData.schedule}
                        edit={state.edit}
                        addCallback={handleAvailabilityAdd}
                        removeCallback={handleAvailabilityRemove}
                        addExceptionCallback={handleAvailabilityExceptionAdd}
                        removeExceptionCallback={handleAvailabilityExceptionRemove}
                        addParticularCallback={handleAvailabilityParticularAdd}
                        removeParticularCallback={handleAvailabilityParticularRemove}
                      />
                    </Paper>
                    {isDoctor(objectData) && isAdmin() &&
                      <DoctorTreatmentTypes
                        editMode={state.edit}
                        doctor={objectData}
                        treatments={treatments}
                        changedCallback={handleTreatmentChange}
                        changedTimeCallback={handleTreatmentTimeChange} />}
                    {isDoctor(objectData) && !isAdmin() && <div>
                      <Grid container>
                        <Grid item className={classes.grid}>
                          <Typography variant="h6" noWrap className={classes.typography}>
                            Estudios
                          </Typography>
                        </Grid>
                      </Grid>
                      <Paper className={classes.paper}>
                        <List
                          component="nav"
                          className={classes.list}
                        >
                          {treatments.filter(item => item.doctors.indexOf(objectData._id) > -1).map(item =>
                            <CustomListItem
                              key={item._id}
                              item={item}
                              text={item.name}
                              icons={[]} />

                          )}
                        </List>
                      </Paper>
                    </div >}
                    {false && (
                      <div>
                        <Grid container className={classes.grid} spacing={3}>
                          <Typography variant="h6" noWrap className={classes.typography}>
                            Honorarios
                          </Typography>
                        </Grid>
                        <Paper className={classes.paper}>
                          {state.edit ? (
                            <Grid item xs={4} className={classes.grid}>
                              <PredictiveComboBox
                                reduce={false}
                                label={'Tipo honorarios'}
                                suggestions={feeTypeSuggestions}
                                value={mode}
                                multi={false}
                                changecallback={v => {
                                  handleModeChange(v);
                                }}
                              />
                            </Grid>
                          ) : (
                            <Grid container>
                              <Grid item xs={4}>
                                <TextField
                                  InputProps={{
                                    readOnly: !state.edit,
                                  }}
                                  label={'Tipo honorarios'}
                                  className={classes.textField}
                                  value={mode.label}
                                  name={'mode'}
                                  InputLabelProps={{
                                    shrink: true,
                                    readOnly: true,
                                  }}
                                />
                              </Grid>
                              <Grid item xs={2}>
                                <Tooltip title="Cambiar doctor">
                                  <IconButton
                                    onClick={() => {
                                      setState({ ...state, edit: true });
                                    }}
                                  >
                                    <Close />
                                  </IconButton>
                                </Tooltip>
                              </Grid>
                            </Grid>
                          )}
                          <br />
                          {mode.value === 1 ? (
                            <List
                              component="nav"
                              aria-labelledby="nested-list-subheader"
                              subheader={
                                false && (
                                  <ListSubheader component="div" id="nested-list-subheader">
                                    Estudios
                                  </ListSubheader>
                                )
                              }
                              className={classes.list}
                            >
                              {treatmentFeesOptions.map((to, tox) => {
                                if (!state.edit && !to[0].treatmentFee) return <div></div>;

                                return (
                                  to.length > 0 && (
                                    <div key={tox}>
                                      <DoctorPaymentPerTreatmentTitle
                                        index={tox}
                                        enabled={!to[0].treatmentFee ? false : true}
                                        editable={state.edit}
                                        treatment={to[0].treatment}
                                        item={to}
                                        toggle={handleTreatmentFeeChange}
                                      />
                                      {to.map((tf, tfx) => {
                                        return (
                                          <DoctorPaymentPerTreatmentListView
                                            key={tfx}
                                            tindex={tox}
                                            findex={tfx}
                                            enabled={!tf.treatmentFee ? false : true}
                                            editable={state.edit}
                                            treatment={tf.treatmentType}
                                            prepaid={tf.prepaid}
                                            treatmentPrice={tf.treatmentPrice}
                                            treatmentFee={tf.fee}
                                            changed={handleFeeChange}
                                          />
                                        );
                                      })}
                                    </div>
                                  )
                                );
                              })}
                            </List>
                          ) : (
                            <div></div>
                          )}
                        </Paper>
                      </div>
                    )}
                  </div>
                )}
              </Grid>
            </Grid>
          </Container>
        }
      />
    </div>
  );
}
