import axios from 'axios';

export const getTreatmentCodes = async () => {
  try {
    const res = await axios.get('/treatmentCodes');
    const r = { success: true, message: '', data: res.data };
    return r;
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const getTreatmentCode = async id => {
  try {
    const res = await axios.get(`/treatmentCodes/${id}`);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const createTreatmentCode = async treatmentCode => {
  try {
    const res = await axios.post(`/treatmentCodes/`, treatmentCode);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const modifyTreatmentCode = async treatmentCode => {
  try {
    const res = await axios.put(`/treatmentCodes/`, treatmentCode);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const deleteTreatmentCode = async id => {
  try {
    const res = await axios.delete(`/treatmentCodes/${id}`);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};
