import axios from 'axios';

export const getSupplies = async () => {
  try {
    const res = await axios.get('/supplies');
    const r = { success: true, message: '', data: res.data };
    return r;
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const getSupply = async id => {
  try {
    const res = await axios.get(`/supplies/${id}`);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const createSupply = async supply => {
  try {
    const res = await axios.post(`/supplies/`, supply);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const modifySupply = async supply => {
  try {
    const res = await axios.put(`/supplies/`, supply);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const deleteSupply = async id => {
  try {
    const res = await axios.delete(`/supplies/${id}`);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};
