import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Container,
    Grid,
    IconButton,
    TextField,
    DialogActions,
    Button,
    Chip,
    FormControlLabel,
    Checkbox,
    Tooltip,
} from '@material-ui/core/';
import { getAllPatients, getPatient } from '../actions/patients';
import { getAllTreatmentTypes } from '../actions/treatmentTypes';
import { getPrepaids } from '../actions/prepaids';
import {
    Edit,
    Close,
    Person,
} from '@material-ui/icons';
import PredictiveComboBox from './PredictiveText';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { dateToString, getDateFromTurn } from '../utils/dateToString';
import { isSecretary, isAdmin } from '../utils/authUtils';
import { getTreatmentPriceByTreament } from '../actions/treatmentTypePrices';
import { getTreatmentsByPatient } from '../actions/treatments';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    paper: {
        padding: theme.spacing(2),
    },
    container: {
        padding: theme.spacing(0),
    },
    grid: {
        margin: '10px',
    },
    textField: {
        width: '100%',
    },
    textFieldNotes: {
        width: '100%',
        height: '250px',
    },
    button: {
        margin: theme.spacing(1),
    },
    typography: {
        margin: theme.spacing(1),
    },
    selectEmpty: {
        margin: theme.spacing(1),
    },
}));

export default function TurnDialog(props) {
    const classes = useStyles();

    const [turn] = React.useState(props.turn);
    const [selectedDate, setSelectedDate] = React.useState(props.turn ? getDateFromTurn(props.turn) : props.date);
    const [patients, setPatients] = React.useState([]);
    const [treatmentTypes, setTreatmentTypes] = React.useState([]);
    const [prepaids, setPrepaids] = React.useState([]);
    const [availableTurns] = React.useState(props.turns);

    const [missing, setMissing] = React.useState({
        time: false,
        patient: false,
        treatment: false,
        doctor: false,
    });

    const [inputValues, setInputValues] = React.useState({
        time: { label: props.turn ? `${props.turn.dateHour}:${props.turn.dateMinute < 10 ? `0${props.turn.dateMinute}` : props.turn.dateMinute}` : null },
        patient: props.turn && props.turn.patient ? { label: props.turn.patient.lastname !== undefined ? props.turn.patient.lastname + ", " + props.turn.patient.fullname : "", value: props.turn.patient._id } : null,
        treatment: props.turn && props.turn.treatments ? props.turn.treatments.map(i => { return { label: i.name, value: i._id }; }) : [],
        doctor: props.doctor.value ? props.doctor : undefined,
        notes: props.turn && props.turn.notes ? props.turn.notes : "",
        overturn: false,
        reminder: true,
    })

    const [state, setState] = React.useState({
        loaded: false,
        create: false,
        edit: !turn || !turn.patient,
    })

    /* eslint-disable*/
    useEffect(() => {

        if (pageLoaded) return;

        setPageLoaded(true);

        if (props.doctor === undefined) {
            handleCancel();
            return;
        }
        fetchData();

    }, []);
    /* eslint-enable*/

    async function fetchData() {

        const p = await getAllPatients()

        if (!p.success) {
            if (p.message.error.indexOf('Token') !== -1) props.tokenExpired();
            props.openSnack(p.message.error, 'error');
            return;
        }
        setPatients([...p.data]);

        const t = await getAllTreatmentTypes()
        setTreatmentTypes([...t.data]);

        const ppds = await getPrepaids();
        setPrepaids([...ppds.data]);
    }

    function handleEdit() {
        setState({ ...state, edit: true });
    }

    function handleDateChange(date) {
        setSelectedDate(date);
    }

    function selectionChanged(property, value) {
        setInputValues({ ...inputValues, [property]: value });
    }

    function getUserPrepaidName(patient) {


        let prepaid = prepaids.filter(y => y._id === patient.prepaid)[0];

        if (prepaid === undefined)
            return;

        let prepaidName = prepaid.name;
        prepaidName += prepaid.types !== undefined && prepaid.types.length > patient.prepaidType ? " " + prepaid.types[patient.prepaidType] : "";

        return prepaidName;
    }

    function handleCancel() {
        props.turnCallback();
    }

    function handleAddTreatment(t) {
        if (!t)
            return;

        setInputValues({ ...inputValues, treatment: [...t] });
    }

    function checkRequirements() {
        setMissing({ time: !inputValues.time || !inputValues.time.label, doctor: !inputValues.doctor, patient: !inputValues.patient, treatment: inputValues.treatment.length === 0 });

        if (!inputValues.time ||
            !inputValues.time.label ||
            !inputValues.doctor ||
            !inputValues.patient ||
            inputValues.treatment.length === 0) {
            return false;
        }

        return true;
    }

    async function handleAccept() {

        if (!checkRequirements())
            return;

        const rp = await getPatient(inputValues.patient.value);
        const patient = rp.data;
        const rt = await getTreatmentsByPatient(inputValues.patient.value);
        const treatments = rt.data;

        for (let i = 0; i < inputValues.treatment.length; i++) {

            const treatment = inputValues.treatment[i];

            const tt = treatmentTypes.filter(tt => tt._id === treatment.value)

            const prepaidPrices = await getTreatmentPriceByTreament(tt[0]._id);

            const pp = prepaidPrices.data.reverse().filter(pp => pp.prepaid === patient.prepaid)[0];

            if (pp.max !== 0) {
                const count = treatments.filter(t => t.treatmentType === tt[0]._id && selectedDate.getTime() - t.date < 1000 * 60 * 60 * 24 * 365).length;
                if (pp.max !== undefined && count >= pp.max) {
                    props.openSnack("Uno o mas Estudios han llegado a su limite anual permitido", 'error');
                    return;
                }
            }
        }

        const newturn = {
            dateYear: selectedDate.getYear() + 1900,
            dateMonth: selectedDate.getMonth(),
            dateDay: selectedDate.getDate(),
            dateHour: inputValues.time.label.split(':')[0],
            dateMinute: inputValues.time.label.split(':')[1],
            durationMinutes: 15,
            doctor: inputValues.doctor.value,
            patient: inputValues.patient.value,
            treatments: inputValues.treatment.map(it => it.value),
            overturn: inputValues.overturn,
            reminder: inputValues.reminder,
            notes: inputValues.notes,
        };

        props.turnCallback(newturn)
    }

    function handleTurnProcessing() {
        props.processTurn(turn);
    }

    function handlePatientEdit(patient_id) {
        props.history.push(
            `/patient/${patient_id}`
        );
    }

    return (
        <Container maxWidth="lg" className={classes.container}>
            <Grid container>
                <Grid item className={classes.grid}>
                    <Grid container>
                        <Grid item xs={12} className={classes.grid}>
                            <Tooltip title="Editar"><IconButton onClick={handleEdit} disabled={state.edit}><Edit /></IconButton></Tooltip>
                        </Grid>
                        <Grid item xs={12} className={classes.grid}>
                            {state.edit ?
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                        margin="normal"
                                        id="mui-pickers-date"
                                        label="Fecha"
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                                :
                                <TextField
                                    InputProps={{
                                        readOnly: !state.edit,
                                    }}
                                    label={'Fecha'}
                                    className={classes.textField}
                                    value={dateToString(selectedDate)}
                                    name={'time'}
                                    InputLabelProps={{
                                        shrink: true,
                                        readOnly: true,
                                    }}
                                />
                            }
                        </Grid>
                        <Grid item xs={3} className={classes.grid}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={inputValues.overturn} onChange={() => { setInputValues({ ...inputValues, overturn: !inputValues.overturn }) }} value={inputValues.overturn} />
                                }
                                label={"Sobreturno"} />
                        </Grid>
                        <Grid item xs={12} className={classes.grid}>
                            {state.edit && (!inputValues.time || inputValues.time.label === null) ?
                                <PredictiveComboBox
                                    reduce={false}
                                    error={missing.time}
                                    label={"Horario"}
                                    suggestions={availableTurns && availableTurns.filter(item => inputValues.overturn || !item.patient).map((it, ix) => {
                                        return {
                                            key: ix,
                                            label: `${it.dateHour}:${it.dateMinute < 10 ? `0${it.dateMinute}` : it.dateMinute}`,
                                            value: it._id
                                        }
                                    })}
                                    multi={false}
                                    changecallback={(v) => { selectionChanged("time", v); }}
                                /> :
                                <Grid container>
                                    <Grid item xs={state.edit ? 10 : 12}>
                                        <TextField
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            error={missing.time}
                                            label={'Horario'}
                                            className={classes.textField}
                                            value={!inputValues.time ? " " : inputValues.time.label}
                                            name={'time'}
                                            InputLabelProps={{
                                                shrink: true,
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>
                                    {state.edit &&
                                        <Grid item xs={2}>
                                            <Tooltip title="Cancelar">
                                                <IconButton onClick={() => { setInputValues({ ...inputValues, time: null }) }}>
                                                    <Close />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    }
                                </Grid>
                            }
                        </Grid>
                        <Grid item xs={12} className={classes.grid}>
                            {state.edit && !inputValues.patient ?
                                <PredictiveComboBox
                                    error={missing.patient}
                                    label={"Paciente"}
                                    suggestions={patients.map((it, ix) => {
                                        return {
                                            key: ix,
                                            label: `${it.uid} - ${it.lastname !== undefined ? it.lastname + ", " : ""}${it.fullname}`,
                                            value: it._id
                                        }
                                    })}
                                    multi={false}
                                    changecallback={(v) => { selectionChanged("patient", v); }}
                                />
                                :
                                <Grid container spacing={3}>
                                    <Grid item xs={state.edit ? 10 : 12}>
                                        <TextField
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            error={missing.patient}
                                            label={'Paciente'}
                                            className={classes.textField}
                                            value={!inputValues.patient ? " " : patients.filter(x => x._id === inputValues.patient.value)[0].lastname !== undefined ? patients.filter(x => x._id === inputValues.patient.value)[0].lastname + ", " + patients.filter(x => x._id === inputValues.patient.value)[0].fullname : ""}
                                            name={'patient'}
                                            InputLabelProps={{
                                                shrink: true,
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>
                                    {state.edit &&
                                        <Grid item xs={2}>
                                            <Tooltip title="Cancelar">
                                                <IconButton onClick={() => { setInputValues({ ...inputValues, patient: null }) }}>
                                                    <Close />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    }
                                    <Grid item xs={state.edit ? 10 : 12}>
                                        <TextField
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            error={missing.patient}
                                            label={'DNI'}
                                            className={classes.textField}
                                            value={!inputValues.patient ? " " : patients.filter(x => x._id === inputValues.patient.value)[0].uid}
                                            name={'patient'}
                                            InputLabelProps={{
                                                shrink: true,
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={state.edit ? 10 : 12}>
                                        <TextField
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            error={missing.patient}
                                            label={'Cobertura'}
                                            className={classes.textField}
                                            value=
                                            {!inputValues.patient ? " "
                                                : getUserPrepaidName(patients.filter(x => x._id === inputValues.patient.value)[0])}
                                            name={'patient'}
                                            InputLabelProps={{
                                                shrink: true,
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={state.edit ? 10 : 12}>
                                        <TextField
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            error={missing.patient}
                                            label={'Numero Cobertura'}
                                            className={classes.textField}
                                            value={!inputValues.patient ? " " : patients.filter(x => x._id === inputValues.patient.value)[0].prepaidNumber}
                                            name={'patient'}
                                            InputLabelProps={{
                                                shrink: true,
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={!inputValues.patient ? " " : patients.filter(x => x._id === inputValues.patient.value)[0].taxed}
                                                />
                                            }
                                            label={'Grabado'}
                                        />
                                    </Grid>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className={classes.button}
                                        endIcon={<Person />}
                                        onClick={() => handlePatientEdit(patients.filter(x => x._id === inputValues.patient.value)[0]._id)}
                                    >
                                        Editar Paciente
                                        </Button>
                                </Grid>
                            }
                        </Grid>
                        {(isSecretary() || isAdmin()) &&
                            <Grid item xs={12} className={classes.grid}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <TextField
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            label={'Doctor'}
                                            className={classes.textField}
                                            value={inputValues.doctor ? inputValues.doctor.label : ""}
                                            name={'doctor'}
                                            InputLabelProps={{
                                                shrink: true,
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        }
                        <Grid item xs={12} className={classes.grid}>
                            {state.edit &&
                                <PredictiveComboBox
                                    reduce={false}
                                    label={"Estudios"}
                                    error={missing.treatment}
                                    suggestions={treatmentTypes.map((it, ix) => {
                                        return {
                                            key: ix,
                                            label: it.name,
                                            value: it._id
                                        }
                                    })}
                                    multi={true}
                                    value={inputValues.treatment}
                                    changecallback={(v) => { handleAddTreatment(v); }}
                                />
                            }
                            {!state.edit && inputValues.treatment.map((it, ix) => {
                                return <Chip key={ix} label={it.label} value={it} />
                            })}
                        </Grid>
                        <Grid item xs={12} className={classes.grid}>
                            <TextField
                                InputProps={{
                                    readOnly: !state.edit,
                                }}
                                label={'Notas'}
                                className={classes.textFieldNotes}
                                value={inputValues.notes}
                                name={'notes'}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={e => {
                                    if (state.edit)
                                        setInputValues({ ...inputValues, notes: e.target.value })
                                }}
                            />
                        </Grid>
                        <Grid item xs={4} className={classes.grid}>
                            <FormControlLabel
                                control={
                                    <Checkbox checked={inputValues.reminder} onChange={() => { setInputValues({ ...inputValues, reminder: !inputValues.reminder }) }} value={inputValues.reminder} />
                                }
                                label={"Enviar al mail"} />
                        </Grid>
                        <Grid item xs={12} className={classes.grid}>
                            <DialogActions>
                                <Button onClick={handleCancel} color="primary">
                                    Cancelar
                                </Button>
                                <Button onClick={handleAccept} color="primary">
                                    Aceptar
                                </Button>
                                {!state.edit && <Button variant="contained" color="secondary" className={classes.button} onClick={handleTurnProcessing}>
                                    Atender
                                </Button>}
                            </DialogActions>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
}
