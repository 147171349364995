export const unfilteredschedule = date => {

    let array = [];

    for (let h = 0; h < 24; h++) {
        for (let m = 0; m < 4; m++) {
            array.push({ date: new Date(date.getYear() + 1900, date.getMonth(), date.getDate(), h, m * 15), dateYear: date.getYear() + 1900, dateMonth: date.getMonth(), dateDay: date.getDate(), dateHour: h, dateMinute: m * 15 })
        }
    }

    return array;
}

const isInRange = (date, dateRange) => {

    if (date.getTime() >= new Date(new Date(dateRange.from).toDateString()).getTime() && date.getTime() <= new Date(new Date(dateRange.to).toDateString()).getTime())
        return true;

    return false;
}

const checkDayAvailability = (weekDay, filter, date) => {

    if (!date || !filter || !weekDay)
        return [];

    const result = []

    if (filter.particulars !== undefined &&
        filter.particulars !== null) {
        for (let i = 0; i < filter.particulars.length; i++) {
            const particular = filter.particulars[i];
            if (particular.day && particular.day.indexOf(weekDay) !== -1 &&
                particular.dateRange && particular.dateRange.length > 0 && isInRange(date, particular.dateRange[0]) && particular.day.indexOf(weekDay) !== -1) {
                result.push(particular);
            }
        }
    }

    if (filter.exceptions !== undefined &&
        filter.exceptions !== null) {
        for (let i = 0; i < filter.exceptions.length; i++) {
            const exception = filter.exceptions[i];
            if (exception.dateRange && exception.dateRange.length > 0 && isInRange(date, exception.dateRange[0]) && exception.day.indexOf(weekDay) !== -1) {
                return result;
            }
        }
    }

    if (filter.availability !== undefined &&
        filter.availability !== null) {
        for (let i = 0; i < filter.availability.length; i++) {
            const available = filter.availability[i];
            if (available.day.indexOf(weekDay) !== -1) {
                result.push(available);
            }
        }
    }

    return result;
}

const checkHourAvailability = (time, filter) => {

    for (let i = 0; i < filter.length; i++) {

        for (let j = 0; j < filter[i].range.length; j++) {
            const range = filter[i].range[j];
            if (time >= range.from && time < range.to) {
                return true;
            }
        }
    }

    return false;
}

export const mergeSchedule = (doctorSchedule, schedule, date, log) => {

    const emtpyTurns = unfilteredschedule(date);

    const newSchedule = [];

    for (let j = 0; j < emtpyTurns.length; j++) {
        const o = emtpyTurns[j];
        let found = false;
        for (let i = 0; i < doctorSchedule.length; i++) {
            const n = doctorSchedule[i];

            try {
                if (n.dateHour !== o.dateHour || n.dateMinute !== o.dateMinute) {
                    continue;
                }
                newSchedule.push(n);
                found = true;
            } catch (e) { }
        }

        if (!found)
            newSchedule.push(o);
    }

    const result = filterSchedule(newSchedule, schedule, date);
    return result;

}

const filterSchedule = (unfiltered, filter, date) => {

    if (!unfiltered || !filter || !date) return [];

    return unfiltered.filter(i => {


        const unfilteredItem = parseInt(`${i.dateHour}${i.dateMinute < 10 ? `0${i.dateMinute}` : i.dateMinute}`);

        let ok = false;

        const hoursOpenInDay = checkDayAvailability(date.getDay(), filter, date);

        if (hoursOpenInDay.length > 0 && checkHourAvailability(unfilteredItem, hoursOpenInDay)) {
            ok = true;
        }

        if (!ok && i.patient) {
            ok = true;
            i.warning = true;
        }
        // TODO: Missing exceptions
        return ok;
    }
    );
}