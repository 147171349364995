import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Grid, IconButton, Paper, Tooltip, InputLabel } from '@material-ui/core';
import RichTextEditor from './RichTextEditor';
const {v4:uuidv4 } = require('uuid');


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  paper: {
    width: '100%',
    padding: theme.spacing(2),
  },
  container: {
    width: '100%',
    padding: theme.spacing(4),
  },
  grid: {
    width: '100%',
    margin: '10px',
  },
  textField: {
    width: '100%',
  },
  button: {
    margin: theme.spacing(1),
  },
  editor: {
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontSize: '16',
    fontWeight: '400',
    borderBottom: '1px solid grey',
  },
}));

export default function TreatmentReportListItem(props) {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState({
    uid: props.uid ? props.uid : uuidv4(),
    name: props.name,
  });

  const [editorState, setEditorState] = React.useState(
    props.text,
  );

  function handleChange(e) {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });

    if (!props.handleEdit)
      return;

    props.handleEdit({ ...props.item, name: e.target.value, text: editorState, uid: inputValue.uid });
  }

  function onChange(value) {
    setEditorState(value);

    if (!props.handleEdit)
      return;

    props.handleEdit({ ...props.item, name: inputValue.name, text: value, uid: inputValue.uid });
  }

  return (
    <div>
      <br />
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={12} className={classes.grid}>
            <TextField
              InputProps={{
                readOnly: !props.editable,
              }}
              label={'Nombre'}
              className={classes.textField}
              name={'name'}
              value={inputValue.name}
              onChange={e => {
                if (!props.editable) return;
                handleChange(e);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.grid}>
            <InputLabel shrink>Informe</InputLabel>
            <div className={classes.editor}>
              <RichTextEditor readOnly={!props.editable} value={editorState} onChange={onChange} />
            </div>
          </Grid>
          <Grid item className={classes.grid}>
            {props.icons &&
              props.icons.map((icon, index) => {
                return (
                  <Tooltip title={icon.tooltip} key={index}>
                    <IconButton
                      onClick={() => {
                        if (props.editable) {
                          icon.callback({
                            name: inputValue.name,
                            text: editorState,
                            uid: inputValue.uid
                          });
                        } else icon.callback(props.item);
                      }}
                    >
                      {icon.icon}
                    </IconButton>
                  </Tooltip>
                );
              })}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
