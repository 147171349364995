import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, ListItem, ListItemText, Tooltip } from '@material-ui/core';
import { mobileCheck } from '../utils/mobileCheck';
import { capitalize } from './../utils/stringUtils';

const useStyles = makeStyles(theme => ({
  poratender: {
    background: 'linear-gradient(45deg, #41b9e8 30%, #FFFFFF 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: '100%',
  },
  sobreturno: {
    background: 'linear-gradient(45deg, #989898 30%, #FFFFFF 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: '100%',
  },
  tardeturno: {
    background: 'linear-gradient(45deg, #41b9e8 30%, #FFFFFF 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: '100%',
  },
  ensaladeespera: {
    background: 'linear-gradient(45deg, #063afb 30%, #FFFFFF   90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: '100%',
  },
  atendiendo: {
    background: 'linear-gradient(45deg, #ffa21f 30%, #FFFFFF   90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: '100%',
  },
  atendido: {
    background: 'linear-gradient(45deg, #78d43f 30%, #FFFFFF   90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: '100%',
  },
  cancelado: {
    background: 'linear-gradient(45deg, #ff4112 30%, #FFFFFF   90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: '100%',
  },
  root: {
    display: 'flex',
  },
  paper: {
    padding: theme.spacing(2),
  },
  container: {
    padding: theme.spacing(mobileCheck() ? 0 : 4),
  },
  grid: {
    margin: '10px',
  },
  listItemTextTreatment: {
    alignContent: 'flex-start',
    width: '100%',
  },
  listItemTextHour: {
    alignContent: 'flex-start',
    width: '150px',
  },
  listItemTextHourArrived: {
    alignContent: 'flex-start',
    width: '150px',
  },
  listItemTextDoc: {
    alignContent: 'flex-start',
    width: '450px',
  },
  listItemTextPatient: {
    alignContent: 'flex-start',
    width: '550px',
  },
  listItemIcons: {
    width: '400px',
    textAlign: 'end',
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export default function ScheduleListItem(props) {
  const classes = useStyles();

  function getColor() {
    let targetColor = classes.nothing;

    if (!props.turn.patient)
      return targetColor;

    switch (props.turn.status) {
      case 'poratender':
        if (props.turn.overturn)
          targetColor = classes.sobreturno;
        else
          targetColor = classes.poratender;
        break;
      case 'sobreturno':
        targetColor = classes.sobreturno;
        break;
      case 'tardeturno':
        targetColor = classes.tardeturno;
        break;
      case 'ensaladeespera':
        targetColor = classes.ensaladeespera;
        break;
      case 'atendiendo':
        targetColor = classes.atendiendo;
        break;
      case 'atendido':
        targetColor = classes.atendido;
        break;
      case 'cancelado':
        targetColor = classes.cancelado;
        break;
      default:
        targetColor = classes.poratender;
        break;
    }

    return targetColor;
  }

  function getPatientName() {
    return props.turn.patient ? capitalize(`${(props.turn.patient.lastname !== undefined ? props.turn.patient.lastname.trim() + "," : "")} ${props.turn.patient.fullname.trim()}`) : '';
  }

  return (
    <div>
      <ListItem
        classes={{
          alignItemsFlexStart: 'flex-start',
          root: getColor(),
        }
        }
        button
        onClick={() => (props.onclick ? props.onclick() : {})}
      >
        <ListItemText
          className={classes.listItemTextHour}
          primary={`${props.turn.dateHour < 10 ? `0${props.turn.dateHour}` : props.turn.dateHour}:${props.turn.dateMinute < 10 ? `0${props.turn.dateMinute}` : props.turn.dateMinute
            }`}
        />
        <ListItemText
          className={classes.listItemTextHourArrived}
          primary={(props.turn.arrivalDate && props.turn.arrivalDate.dateHour > 0) &&
            `${props.turn.arrivalDate.dateHour < 10 ? `0${props.turn.arrivalDate.dateHour}` : props.turn.arrivalDate.dateHour}:${props.turn.arrivalDate.dateMinute < 10 ? `0${props.turn.arrivalDate.dateMinute}` : props.turn.arrivalDate.dateMinute
            }`}
        />
        {props.allMode && (
          <ListItemText className={classes.listItemTextDoc} primary={props.turn.doctor != null ? props.turn.doctor.fullname : ''} />
        )}
        <ListItemText
          className={classes.listItemTextPatient}
          primary={getPatientName()}
        />
        <ListItemText
          className={classes.listItemTextTreatment}
          primary={props.turn.treatments ? props.turn.treatments.map((x, index) => <div key={index}>{x.name}</div>) : <div />}
        />
        <div
          className={classes.listItemIcons}
        >
          {props.icons &&
            props.icons.map((icon, index) => {
              return (
                <Tooltip key={index} title={icon.tooltip}>
                  <IconButton
                    onClick={() => {
                      if (props.editable) icon.callback(props.turn);
                      else icon.callback(props.turn);
                    }}
                  >
                    {icon.icon}
                  </IconButton>
                </Tooltip>
              );
            })}
        </div>
      </ListItem>
    </div>
  );
}
