import axios from 'axios';

export const getAllTreatments = async () => {
  try {
    const res = await axios.get('/treatments');
    const r = { success: true, message: '', data: res.data };
    return r;
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const sendMail = async treatments => {
  try {
    let tts = treatments.join('-');
    const res = await axios.get(`/treatments/sendMail/${tts}`);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const getTreatmentsByPatient = async id => {
  try {
    const res = await axios.get(`/treatments/patient/${id}`);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const getTreatmentsFiles = async id => {
  try {
    const res = await axios.get(`/treatments/files/${id}/`);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const downloadTreatmentsFile = async (tid, file) => {
  try {
    const res = await axios.get(`/treatments/files/${tid}/${file}`);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};


export const createTreatment = async treatment => {
  try {
    const res = await axios.post(`/treatments/`, treatment);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const uploadTreatmentFiles = async (treatmentId, formData) => {
  try {
    const token = localStorage.getItem('token');
    const res = await axios({
      url: `/treatments/files/${treatmentId}`,
      method: 'POST',
      headers: {
        'x-auth-token': token
      },
      data: formData
    });
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const modifyTreatment = async treatment => {
  try {
    const res = await axios.put(`/treatments/`, treatment);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const deleteTreatment = async id => {
  try {
    const res = await axios.delete(`/treatments/${id}`);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};