import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Button, Container, Grid, Typography, IconButton, List, Tooltip, TextField } from '@material-ui/core/';
import AppContainer from './AppContainer';
import { getAllTreatmentTypes, deleteTreatmentType, modifyTreatmentTypesPositions } from '../actions/treatmentTypes';
import { Add, Edit, Delete,Pageview } from '@material-ui/icons';

import { mobileCheck } from '../utils/mobileCheck';
import TreatmentListItem from './TreatmentListItem';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'

import ReactExport from "react-export-excel-xlsx-fix";
import { getAllTreatmentPrices } from '../actions/treatmentTypePrices';
import { getPrepaids } from '../actions/prepaids';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    paper: {
        padding: theme.spacing(2),
    },
    container: {
        padding: theme.spacing(mobileCheck() ? 0 : 4),
    },
    grid: {
        margin: '10px',
    },
    textField: {
        width: '100%',
    },
    button: {
        margin: theme.spacing(1),
    },
    typography: {
        margin: theme.spacing(1),
    },
}));

export default function Treatments(props) {
    const classes = useStyles();

    const [state, setState] = React.useState({
        loaded: false,
        treatments: [],
    });
    const [treatmentPrices, setTreatmentPrices] = React.useState([]);


    const [pageLoaded, setPageLoaded] = React.useState(false);
    const [prepaids, setPrepaids] = React.useState([]);



    useEffect(() => {
        if (pageLoaded) return;
        setPageLoaded(true);

        fetchData();
        // eslint-disable-next-line
    }, []);

    async function fetchData() {
        const result = await getAllTreatmentTypes();

        if (!result.success) {
            if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
            props.openSnack(result.message.error, 'error');
            return;
        }

        // --------------------------Esto no lo usa nunca???
       
       const tp = await getAllTreatmentPrices();

        if (!tp.success) {
            if (tp.message.error.indexOf('Token') !== -1) props.tokenExpired();
            props.openSnack(tp.message.error, 'error');
            return;
        }
        setTreatmentPrices(tp.data.reverse());

        const ppds = await getPrepaids();

        if (!ppds.success) {
            if (ppds.message.error.indexOf('Token') !== -1) props.tokenExpired();
            props.openSnack(ppds.message.error, 'error');
            return;
        }

        setPrepaids(ppds.data);

        setState({
            ...state, treatments: result.data
        });
    }

    function detailsCallback(item) {
        props.history.push('treatment/' + item._id);
    }

    async function deleteCallback(item) {
        props.openDialog("Confirmación", "Seguro que desea eliminar este Estudio?", item, handleDeleteCallback);
    }

    async function handleDeleteCallback(confirmed, item) {
        if (!confirmed)
            return;
        const result = await deleteTreatmentType(item._id);
        if (!result.success) {
            if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
            props.openSnack(result.message.error, 'error');
            return;
        }
        fetchData();
    }

    function handleAdd() {
        props.history.push('treatment/');
    }

    function filter(e) {
        setState({ ...state, filter: e.target.value });
    }

    function handleItemDropped(from, to) {
        const movingTreament = state.treatments[from];

        const temp = state.treatments;
        temp.splice(from, 1);

        let half1 = [];
        let half2 = temp;

        if (to !== 0) {

            half1 = temp.slice(0, to);
            half2 = temp.slice(to);
        }

        //if (to === temp.)

        const newTreatments = [...half1, movingTreament, ...half2];

        for (let i = 0; i < newTreatments.length; i++) {
            const element = newTreatments[i];
            element.position = i;
        }

        modifyTreatmentTypesPositions(newTreatments);

        setState({ loaded: true, treatments: newTreatments });

    }
    return (
        <div>
            <AppContainer title={"Estudios"} history={props.history} content={
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        {!mobileCheck() && <Grid item xs={1} className={classes.grid} />} 
                        <Grid item xs={!mobileCheck() ? 10 : 12} className={classes.grid}>
                        <Grid container>
                            <Grid  item xs={12} lg={6} >
                                <Grid container>
                                    <Grid  item className={classes.grid}>
                                        <Typography variant="h6" noWrap className={classes.typography}>
                                            Editar por porcentaje
                                        </Typography>
                                    </Grid>
                                    <Grid item className={classes.grid}>
                                        <Tooltip title="Editar montos por porcentaje"><IconButton onClick={()=>{ props.history.push('treatmentPricesEdit/')}}><Edit color="primary" /></IconButton></Tooltip>
                                    </Grid>
                                </Grid>   
                            </Grid>
                            <Grid  item  xs={12} lg={6} >
                            <Grid container>
                                    <Grid  item className={classes.grid}>
                                        <Typography variant="h6" noWrap className={classes.typography}>
                                        Historial de precios
                                        </Typography>
                                    </Grid>
                                    <Grid item className={classes.grid}>
                                        <Tooltip title="Ver historial"><IconButton onClick={()=>{ props.history.push('treatmentPricesHistory/')}}><Pageview  color="primary" /></IconButton></Tooltip>
                                    </Grid>
                                </Grid>   
                                {/*<Grid container>
                                    <Typography variant="h6" noWrap className={classes.typography}>
                                            Historial de precios
                                    </Typography>
                           
                                    <Grid item className={classes.grid}>
                                        <Tooltip title="Ver historial"><IconButton onClick={()=>{ props.history.push('treatmentPricesEdit/')}}><Pageview color="primary" /></IconButton></Tooltip>
                                    </Grid>   
            </Grid>*/}
                            </Grid>
                           
                        </Grid>
                            <Grid container>
                                <Grid item className={classes.grid}>
                                    <Typography variant="h6" noWrap className={classes.typography}>
                                        Listado
                                </Typography>
                                </Grid>
                                <Grid item className={classes.grid}>
                                    <Tooltip title="Agregar nuevo Estudio"><IconButton onClick={handleAdd}><Add color="primary" /></IconButton></Tooltip>
                                </Grid>
                                
                            </Grid>
                            <Grid item className={classes.grid}>
                                <ExcelFile element={
                                    <Tooltip title="Exporta todos los estudios a un archivo de Excel">
                                        <Button color='primary' variant='contained'>
                                            EXPORTAR A EXCEL
                                        </Button>
                                    </Tooltip>}>
                                    <ExcelSheet data={state.treatments.map((tr, idx) => {
                                        let r = {
                                            code: tr.code,
                                            name: tr.name,
                                            pre: tr.pre,
                                        };

                                        prepaids.forEach(x => {
                                            const prices = treatmentPrices
                                            .filter(tp => x._id === tp.prepaid && tr._id === tp.treatmentType && new Date().getTime() > tp.from)
                                            .sort(function (a, b) {
                                                return b.from - a.from;
                                              });
                                            //const found = treatmentPrices.find(tp => tp.prepaid === x._id && tp.treatmentType === tr._id);

                                            if (prices.length>0)
                                               // r[x.name] = parseFloat(found.price);
                                            
                                               r[x.name]=parseFloat(prices[0].price);
                                                  else
                                                r[x.name] = 0;
                                        });
                                        return r;
                                    })} name="Estudios">
                                        <ExcelColumn label="Código" value="code" />
                                        <ExcelColumn label="Estudio" value="name" />
                                        <ExcelColumn label="Preparación" value="pre" />
                                        {prepaids.map((x, i) =>
                                            <ExcelColumn key={i} label={x.name} value={x.name} />)}
                                    </ExcelSheet>
                                </ExcelFile>
                            </Grid>
                            <Grid item xs={12} className={classes.grid}>
                                <TextField
                                    id="standard-name"
                                    label="Filtro"
                                    className={classes.textField}
                                    onChange={filter}
                                />
                            </Grid>

                            <Paper className={classes.paper}>
                                <DndProvider backend={HTML5Backend}>
                                    <List
                                        component="nav"
                                        className={classes.list}
                                    >
                                        {state.treatments &&
                                            state.treatments.filter(item => !state.filter
                                                || item.name.toLowerCase().includes(state.filter.toLowerCase())
                                            ).map((item, index) =>
                                            (<TreatmentListItem
                                                key={item._id}
                                                index={index}
                                                itemDropped={handleItemDropped}
                                                item={item}
                                                text={item.name}
                                                icons={[
                                                    { icon: <Edit />, callback: detailsCallback, tooltip: "Editar" },
                                                    { icon: <Delete />, callback: deleteCallback, tooltip: "Eliminar" },
                                                ]} />))}
                                    </List>
                                </DndProvider>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            } />
        </div>
    );
}
