import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { mobileCheck } from "../utils/mobileCheck";
import "./css/TreatmentPricesListByPrepaid.css";
import {
  getAllPrepaidTreatments,
  deletePrepaidAdjustment,
} from "../actions/treatmentsPrepaid";
import { Grid, IconButton, Tooltip, Paper, Snackbar } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import MySnackbarContentWrapper from "./AppSnackbar";
import ConfirmDialog from "./ConfirmDialog";
import { logout } from "../actions/auth";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    overflowX: "scroll",
    padding: theme.spacing(1),
  },
  container: {
    padding: theme.spacing(mobileCheck() ? 0 : 4),
  },
  grid: {
    margin: "10px",
  },
  textField: {
    width: "90%",
    fontSize: "8px",
  },
  button: {
    margin: theme.spacing(1),
  },
  typography: {
    margin: theme.spacing(1),
  },
}));
export default function TreatmentsPricesListByPrepaid(props) {
  const classes = useStyles();
  function tokenExpired() {
    logout();
  }
  const [snack, setSnack] = useState({
    open: false,
    text: "Snack text",
    variant: "error",
  });

  function openSnack(message, variant) {
    setSnack({ ...snack, open: true, text: message, variant: variant });
  }

  function handleSnackClose() {
    setSnack({ ...snack, open: false });
  }

  const [confirmDialog, setConfirmDialog] = useState({
    open: false,
    title: "Confirmación",
    message: "Seguro?",
    item: {},
    callback: () => {},
  });

  function openDialog(title, message, item, callback) {
    setConfirmDialog({
      ...confirmDialog,
      open: true,
      title: title,
      message: message,
      item: item,
      callback: callback,
    });
  }
  function handleDialogConfirm() {
    setConfirmDialog({ ...confirmDialog, open: false });
    if (confirmDialog.callback)
      confirmDialog.callback(true, confirmDialog.item);
  }

  function handleDialogCancel() {
    setConfirmDialog({ ...confirmDialog, open: false });

    if (confirmDialog.callback)
      confirmDialog.callback(false, confirmDialog.item);
  }
  function buildDateStr(date) {
    var d = new Date(date);
    var dateStr = `${d.getDate() < 10 ? "0" + d.getDate() : d.getDate()}/${
      d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1
    }/${d.getFullYear()}`;
    return dateStr;
  }
  const handleDeleteAdjustment = (dat) => {
    openDialog(
      "Confirmación",
      "Seguro que desea eliminar este aumento?",
      undefined,
      handleDeleteCallback
    );

    async function handleDeleteCallback(confirmed, item) {
      if (!confirmed) return;
      props.setDataLoading(true);
      const result = await deletePrepaidAdjustment(dat._id);
      if (result.success) {
        openSnack("Los cambios fueron procesados exitosamente.", "success");
      } else {
        if (result.message.error.indexOf("Token") !== -1) tokenExpired();
        openSnack(result.message.error, "error");
      }
      const tp = await getAllPrepaidTreatments(dat.prepaid);
      if (!tp.success) {
        if (tp.message.error.indexOf("Token") !== -1) tokenExpired();
        openSnack(tp.message.error, "error");
        return;
      }
      props.setData((prevData) => {
        return {
          ...prevData,
          percentageAdj: tp.percentageAdjustments,
          treatments: tp.prepaidPrices,
        };
      });

      props.setDataLoading(false);
    }
  };
  if (props.data.treatments.length > 0) {
    return (
      <div style={{ marginTop: "2%" }}>
        <Paper className={classes.paper}>
          {props.data.percentageAdj.length > 0 ? (
            <div className="existen">
              {props.data.percentageAdj.map((dato, item) => {
                if (dato.from < new Date().getTime()) {
                  return (
                    <div>
                      <p>Aumento porcentual actual</p>
                      <Grid
                        container
                        columnspacing={2}
                        alignItems="flex-end"
                        justifyContent="flex-start"
                      >
                        <Grid item xs={5} lg={3}>
                          <TextData
                            title={"Aumento actual (%)"}
                            content={dato.percentage}
                          />
                        </Grid>{" "}
                        <Grid item xs={5} lg={3}>
                          <TextData
                            title={"Desde"}
                            content={buildDateStr(dato.from)}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          justifyContent="flex-start"
                          alignItems="flex-end"
                        >
                          <Tooltip title="Borrar el aumento porcentual">
                            <IconButton
                              onClick={() => {
                                handleDeleteAdjustment(dato);
                              }}
                            >
                              <Delete color="primary" />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </div>
                  );
                } else {
                  return (
                    <div>
                      <p>Aumento porcentuales programados</p>
                      <Grid
                        container
                        columnspacing={2}
                        alignItems="flex-end"
                        justifyContent="flex-start"
                      >
                        <Grid item xs={5} lg={3}>
                          <TextData
                            title={"Aumento (%)"}
                            content={dato.percentage}
                          />
                        </Grid>{" "}
                        <Grid item xs={5} lg={3}>
                          <TextData
                            title={"Desde"}
                            content={buildDateStr(dato.from)}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          justifyContent="flex-start"
                          alignItems="flex-end"
                        >
                          <Tooltip title="Borrar el aumento porcentual">
                            <IconButton
                              onClick={() => {
                                handleDeleteAdjustment(dato);
                              }}
                            >
                              <Delete color="primary" />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </div>
                  );
                }
              })}
            </div>
          ) : (
            <div className="noExisten">
              No existen aumentos procentuales programados
            </div>
          )}
          <div>
            {props.data.treatments.map((dat) => {
              return (
                <div
                  style={{
                    boxShadow: "5px 8px #888888",
                    border: "1px solid",
                    paddingLeft: "1%",
                    paddingBottom: "2px",
                  }}
                >
                  {" "}
                  <h4>{dat.name}</h4>
                  <div>
                    {dat.price.map((dat, item) => {
                      if (item === 0) {
                        return (
                          <div style={{ paddingBottom: "2%" }}>
                            <Grid
                              container
                              spacing={3}
                              alignItems="flex-end"
                              justifyContent="flex-start"
                            >
                              <Grid item xs={4} lg={3}>
                                <TextData
                                  title={"Precio Actual ($)"}
                                  content={dat.price}
                                />
                              </Grid>
                              <Grid item xs={4} lg={3}>
                                <SmallTextData
                                  title={"Desde"}
                                  content={buildDateStr(dat.from)}
                                />
                              </Grid>
                              <Grid item xs={4} lg={3}>
                                <SmallTextData
                                  title={"Tipo"}
                                  content={
                                    dat.percentageAdj
                                      ? "Porcentual"
                                      : "Individual"
                                  }
                                />
                              </Grid>
                            </Grid>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            style={{
                              width: "100%",
                              paddingLeft: "2%",
                              fontSize: "12px",
                            }}
                          >
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              <Grid item xs={4} lg={3}>
                                <SmallTextData
                                  title={"Precio Futuro ($)"}
                                  content={dat.price}
                                />
                              </Grid>
                              <Grid item xs={4} lg={3}>
                                <SmallTextData
                                  title={"Desde"}
                                  content={buildDateStr(dat.from)}
                                />
                              </Grid>
                              <Grid item xs={4} lg={3}>
                                <SmallTextData
                                  title={"Tipo"}
                                  content={
                                    dat.percentageAdj
                                      ? "Porcentual"
                                      : "Individual"
                                  }
                                />
                              </Grid>
                            </Grid>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </Paper>
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          open={snack.open}
          autoHideDuration={6000}
          onClose={handleSnackClose}
        >
          <MySnackbarContentWrapper
            onClose={handleSnackClose}
            variant={snack.variant}
            message={snack.text}
          />
        </Snackbar>
        <ConfirmDialog
          open={confirmDialog.open}
          title={confirmDialog.title}
          message={confirmDialog.message}
          accept={handleDialogConfirm}
          cancel={handleDialogCancel}
        />
      </div>
    );
  } else {
    return null;
  }
}
const TextData = (props) => {
  return (
    <div>
      <div className="mainTitle">{props.title}</div>
      <div className="mainContent">{props.content}</div>
    </div>
  );
};
const SmallTextData = (props) => {
  return (
    <div>
      <div className="smallTitle">{props.title}</div>
      <div className="smallContent">{props.content}</div>
    </div>
  );
};
