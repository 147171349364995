import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Select from "react-select";
import {
  Grid,
  IconButton,
  Typography,
  Tooltip,
  CircularProgress,
  Paper,
  Button,
  Checkbox,
} from "@material-ui/core/";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import AppContainer from "./AppContainer";
import { Search, ArrowBack } from "@material-ui/icons";
import { mobileCheck } from "../utils/mobileCheck";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { getPrepaids } from "../actions/prepaids";
import { getTreatmentsPricesHistory } from "../actions/treatmentsPrepaid";

import TreatmentsPricesHistoryList from "./TreatmentsPricesHistoryList";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    padding: theme.spacing(1),
  },
  container: {
    padding: theme.spacing(mobileCheck() ? 0 : 2),
  },
  grid: {
    margin: "10px",
  },
  textField: {
    width: "100%",
  },
  button: {
    margin: theme.spacing(1),
  },
  typography: {
    margin: theme.spacing(1),
  },
  progress: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));
export default function TreatmentsPricesHistory(props) {
  const [dialog, setDialog] = useState({ visible: false });
  const [editable, setEditable] = useState(false);
  const [prepaids, setPrepaids] = useState([]);
  const [data, setData] = useState({
    prepaid: null,
    treatments: [],
    adjustments: [],
  });
  const handleClose = () => {
    setDialog({ visible: false });
  };
  const [dataLoading, setDataLoading] = useState(true);
  useEffect(() => {
    fetchData();
  }, []);
  async function fetchData() {
    const ppds = await getPrepaids();
    if (!ppds.success) {
      if (ppds.message.error.indexOf("Token") !== -1) props.tokenExpired();
      props.openSnack(ppds.message.error, "error");
      return;
    }
    setPrepaids(
      ppds.data.map((it, ix) => {
        return {
          label: it.name,
          value: it._id,
        };
      })
    );
    setDataLoading(false);
  }
  const classes = useStyles();
  const [selectedFromDate, setSelectedFromDate] = React.useState(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate()
    )
  );
  const [selectedToDate, setSelectedToDate] = React.useState(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() + 1
    )
  );
  const handleSelection = async (value) => {
    setData({ prepaid: value, treatments: [] });
  };
  function handleDateFromChange(fromDate) {
    setSelectedFromDate(fromDate);
  }

  function handleDateToChange(toDate) {
    setSelectedToDate(toDate);
  }
  const handleSearch = async () => {
    if (!data.prepaid) {
      setDialog({ visible: true, type: "error" });
      return;
    }
    const from = selectedFromDate.getTime();
    const to = selectedToDate.getTime();
    setDataLoading(true);
    const result = await getTreatmentsPricesHistory(
      data.prepaid.value,
      from,
      to
    );
    if (!result.success) {
      if (result.message.error.indexOf("Token") !== -1) props.tokenExpired();
      props.openSnack(result.message.error, "error");
      setDataLoading(false);
      return;
    }
    setData({
      ...data,
      treatments: result.prices,
      adjustments: result.adjustments,
    });
    setDataLoading(false);
  };

  return (
    <AppContainer
      title={"Estudios"}
      history={props.history}
      content={
        !dataLoading ? (
          <Grid container spacing={1}>
            {!mobileCheck() && <Grid item xs={1} className={classes.grid} />}
            <Grid item xs={!mobileCheck() ? 10 : 12} className={classes.grid}>
              <div
                style={{
                  paddingBottom: "2%",
                }}
              >
                <Grid container>
                  <Grid xs={10} lg={6} item>
                    <Typography>Seleccione la prepaga </Typography>
                    <Select
                      styles={{
                        menu: (provided) => ({ ...provided, zIndex: 9999 }),
                      }}
                      options={prepaids}
                      onChange={handleSelection}
                      value={data.prepaid}
                    />
                  </Grid>
                  <Grid xs={2} item justifyContent="flex-end">
                    <div
                      style={{
                        textAlign: "right",
                        width: "100%",
                      }}
                    >
                      <Tooltip title="Volver">
                        <IconButton
                          onClick={() => props.history.push("/treatments")}
                        >
                          <ArrowBack color="primary" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </Grid>
                </Grid>
              </div>
              <Paper
                className={classes.paper}
                style={{
                  width: mobileCheck() ? "120%" : "100%",
                }}
              >
                <Grid
                  container
                  spacing={1}
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item xs={6} sm={6} lg={3} justifyContent="center">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="Desde"
                        format="dd/MM/yyyy"
                        value={selectedFromDate}
                        InputAdornmentProps={{ position: "start" }}
                        onChange={handleDateFromChange}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={6} sm={6} lg={3}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="Hasta"
                        format="dd/MM/yyyy"
                        InputAdornmentProps={{ position: "start" }}
                        value={selectedToDate}
                        onChange={handleDateToChange}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={6} sm={3} lg={3} justifyContent="center">
                    <Grid container alignItems="center">
                      <Grid item>
                        <Typography className="agregarAumento">
                          Editable
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Checkbox
                          label
                          checked={editable}
                          onChange={() => setEditable(!editable)}
                          inputProps={{ "aria-label": "controlled" }}
                          style={{
                            color: "#3f51b5",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={6} sm={3} lg={3} justifyContent="flex-start">
                    <Grid
                      container
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <Grid item>
                        <Typography className="agregarAumento">
                          Buscar
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Tooltip title="Buscar precios">
                          <IconButton onClick={handleSearch}>
                            <Search color="primary" />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>

              <Grid item xs={12}>
                <TreatmentsPricesHistoryList
                  editable={editable}
                  data={data}
                  setData={setData}
                  parentProps={props}
                  setDataLoading={setDataLoading}
                  dates={{
                    from: selectedFromDate.getTime(),
                    to: selectedToDate.getTime(),
                  }}
                />
              </Grid>
            </Grid>
            <Dialog
              open={dialog.visible}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Prepaga no seleccionada"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Por favor seleccione una prepaga
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    handleClose();
                  }}
                  autoFocus
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        ) : (
          <div
            style={{
              marginTop: "3%",
              textAlign: "center",
              width: "100%",
            }}
          >
            <CircularProgress />{" "}
          </div>
        )
      }
    />
  );
}
