import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ListItem, Collapse, List, ListItemText, ListItemIcon } from '@material-ui/core';
import { ExpandLess, ExpandMore, Done, Close } from '@material-ui/icons';
import { capitalize } from './../utils/stringUtils';

const useStyles = makeStyles(theme => ({
    nested: {
        width: '100%',
        paddingLeft: theme.spacing(4),
    },
    listItemText: {
        textAlign: 'left',
        width: '100px',
    }
}));

export default function BillingListItem(props) {
    const classes = useStyles();

    const [state, setState] = React.useState({
        collapsed: false,
    })

    function handleCollapseChange() {
        setState({ ...state, collapsed: !state.collapsed })
    }

    return (
        <div>
            <ListItem button onClick={handleCollapseChange}>
                <ListItemText className={classes.listItemText} primary={`${new Date(props.treatment.date).getDate()}/${new Date(props.treatment.date).getMonth() + 1}/${new Date(props.treatment.date).getYear() + 1900}`} />
                <ListItemText className={classes.listItemText} primary={props.treatment.doctor.fullname} />
                <ListItemText className={classes.listItemText} primary={capitalize(props.treatment.patient.lastname !== undefined ? props.treatment.patient.lastname.trim() + ", " + props.treatment.patient.fullname : "")} />
                <ListItemText className={classes.listItemText} primary={props.treatment.title} />
                <ListItemText className={classes.listItemText} primary={props.treatment.prepaid === undefined ? "" : props.treatment.prepaid.name} />
                <ListItemIcon>{props.treatment.patient.taxed ? <Done /> : <Close />}</ListItemIcon>
                <ListItemText className={classes.listItemText} primary={`$${props.treatment.price}`} />
                {state.collapsed ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={state.collapsed} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem button className={classes.nested}>
                        <ListItemText primary="Info" />
                    </ListItem>
                </List>
            </Collapse>
        </div>
    );
}
