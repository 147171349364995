import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Container,
    Grid,
    Paper,
    Typography,
    CircularProgress,
    TextField,
    FormControlLabel,
    Checkbox,
    Button,
    Modal,
} from '@material-ui/core/';
import AppContainer from './AppContainer';
import { mobileCheck } from '../utils/mobileCheck';
import { getAllPatients } from '../actions/patients';
import { createTurn, getSchedule } from '../actions/schedules';
import PredictiveComboBox from './PredictiveText';
import { getDoctor } from '../actions/doctors';
import { getAllTreatmentTypes } from '../actions/treatmentTypes';
import { mergeSchedule, unfilteredschedule } from '../utils/unfilteredschedule';
import { dateToString } from '../utils/dateToString';
import { getPrepaids } from '../actions/prepaids';
import PatientPopup from './PatientPopup';
import { Person } from '@material-ui/icons';

import { capitalize } from './../utils/stringUtils';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    paper: {
        padding: theme.spacing(2),
    },
    container: {
        padding: theme.spacing(mobileCheck() ? 0 : 4),
    },
    grid: {
        margin: '10px',
        width: '100%',
    },
    textField: {
        width: '100%',
    },
    button: {
        margin: theme.spacing(1),
    },
    typography: {
        margin: theme.spacing(1),
    },
    avmessage: {
        width: '100%',
        textAlign: 'center',
    },
    progress: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}));

export default function NewTurn(props) {
    const classes = useStyles();

    const [patientModalShow, setPatientModalShow] = React.useState(false);
    const [doctor, setDoctor] = React.useState({ fullname: "" });
    const [patients, setPatients] = React.useState([]);
    const [prepaids, setPrepaids] = React.useState([]);
    const [treatmentTypes, setTreatmentTypes] = React.useState([]);
    const [availableTurns, setAvailableTurns] = React.useState([]);

    const [loading, setLoading] = React.useState(true);

    const [selectedDate] = React.useState(new Date(props.match.params.dateYear, props.match.params.dateMonth, props.match.params.dateDay));
    const [selectedTime, setSelectedTime] = React.useState({ dateHour: props.match.params.dateHour, dateMinute: props.match.params.dateMinute, label: `` });
    const [selectedPatient, setSelectedPatient] = React.useState();
    const [selectedTreatments, setSelectedTreatments] = React.useState([]);
    const [isOverturn, setIsOverturn] = React.useState(false);
    const [needsReminder, setNeedsReminder] = React.useState(true);

    const [pageLoaded, setPageLoaded] = React.useState(false);

    useEffect(() => {
        if (pageLoaded) return;
        setPageLoaded(true);
        fetchData();
        // eslint-disable-next-line
    }, []);

    async function fetchData() {
        setLoading(false);

        const doctor = await getDoctor(props.match.params.doctor);
        setDoctor(doctor.data);

        const patients = await getAllPatients();
        setPatients(patients.data);

        const treatmentTypes = await getAllTreatmentTypes()
        setTreatmentTypes([...treatmentTypes.data]);


        const date = new Date(props.match.params.dateYear, props.match.params.dateMonth, props.match.params.dateDay);

        const turns = await getSchedule(doctor.data._id, date);

        if (!turns.success) {
            props.openSnack(turns.message.error, 'error');
            return;
        }

        let mergedSch = mergeSchedule(turns.data, doctor.data.schedule, date);

        for (let i = 0; i < mergedSch.length; i++) {
            const turn = mergedSch[i];

            if (!turn)
                continue;

            const turnsTaken = turn.durationMinutes / 15

            if (turnsTaken === 1)
                continue;

            for (let j = 0; j < turnsTaken - 1; j++) {
                if (!mergedSch[i + j + 1].patient)
                    mergedSch[i + j + 1] = null;
            }

        }

        const availableTurns = mergedSch.filter(x => x != null);

        setAvailableTurns(availableTurns);

        const prepaids = await getPrepaids();
        setPrepaids([...prepaids.data]);
    }

    async function handleAccept() {

        if (selectedTreatments.length === 0) {
            props.openSnack("Debe seleccionar al menos un estudio", 'error');
            return;
        }

        if (selectedPatient === undefined || selectedPatient.value === "") {
            props.openSnack("Debe seleccionar un paciente", 'error');
            return;
        }

        setLoading(true);

        const result = await createTurn(getTurn());
        if (!result.success) {
            if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
            props.openSnack(result.message.error, 'error');
            setLoading(false);
            return;
        } else {
            props.openSnack('Turno creado correctamente', 'success');
        }

        props.history.push(`/schedule/`);
    }

    // function handlePatientEdit(patient_id) {
    //     props.history.push(
    //         `/patient/${patient_id}`
    //     );
    // }

    function getUserPrepaidName(patient) {
        let prepaid = prepaids.filter(y => y._id === patient.prepaid)[0];

        if (prepaid === undefined)
            return;

        let prepaidName = prepaid.name;
        const prepaidTypeName = prepaid.types !== undefined && prepaid.types.length > patient.prepaidType ? " " + prepaid.types[patient.prepaidType] : "";

        if (prepaidTypeName !== " undefined")
            prepaidName += prepaidTypeName;

        return prepaidName;
    }

    function getTurn() {
        return {
            dateYear: selectedDate.getYear() + 1900,
            dateMonth: selectedDate.getMonth(),
            dateDay: selectedDate.getDate(),
            dateHour: selectedTime.dateHour,
            dateMinute: selectedTime.dateMinute,
            durationMinutes: 15,
            doctor: props.match.params.doctor,
            patient: selectedPatient.value,
            treatments: selectedTreatments.map(it => it.value),
            overturn: isOverturn,
            reminder: needsReminder,
            notes: "",
        };
    }

    function handlePatientView(turn) {

        setPatientModalShow(true);
    }

    function handleViewDetails(item) {
        props.history && props.history.push(`/medicalExam/${item._id}`)
    }

    return (
        <AppContainer
            title={'Nuevo Turno'}
            history={props.history}
            content={
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        {!mobileCheck() && <Grid item xs={1} className={classes.grid} />}
                        <Grid item xs={!mobileCheck() ? 10 : 12} className={classes.grid}>
                            <Grid container>
                                <Grid item className={classes.grid}>
                                    <Typography variant="h6" noWrap className={classes.typography}>
                                        Nuevo Turno
                                    </Typography>

                                    <Paper className={classes.paper}>
                                        {loading ? (
                                            <Grid item xs={12} className={classes.grid}>
                                                <CircularProgress className={classes.progress} />
                                            </Grid>
                                        ) :
                                            <Grid container>
                                                <Grid item xs={12} className={classes.grid}>
                                                    <TextField
                                                        className={classes.textField}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        label={'Fecha'}
                                                        value={dateToString(selectedDate)}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }} />
                                                </Grid>
                                                {!isOverturn ?
                                                    <Grid item xs={12} className={classes.grid}>
                                                        <PredictiveComboBox
                                                            reduce={false}
                                                            label={"Horario"}
                                                            suggestions={availableTurns && availableTurns.filter(item => isOverturn || !item.patient).map((it, ix) => {
                                                                return {
                                                                    key: ix,
                                                                    dateHour: it.dateHour,
                                                                    dateMinute: it.dateMinute,
                                                                    label: `${it.dateHour}:${it.dateMinute < 10 ? `0${it.dateMinute}` : it.dateMinute}`,
                                                                    value: it._id
                                                                }
                                                            })}
                                                            value={
                                                                {
                                                                    label: `${props.match.params.dateHour}:${props.match.params.dateMinute < 10 ? `0${props.match.params.dateMinute}` : props.match.params.dateMinute}`,
                                                                    value: -1
                                                                }
                                                            }
                                                            multi={false}
                                                            changecallback={(e) => { setSelectedTime({ ...selectedTime, dateHour: e.dateHour, dateMinute: e.dateMinute }); }}
                                                        />
                                                    </Grid> :
                                                    <Grid item xs={12} className={classes.grid}>
                                                        <PredictiveComboBox
                                                            reduce={false}
                                                            label={"Horario"}
                                                            suggestions={unfilteredschedule(selectedDate).map((it, ix) => {
                                                                return {
                                                                    key: ix,
                                                                    dateHour: it.dateHour,
                                                                    dateMinute: it.dateMinute,
                                                                    label: `${it.dateHour}:${it.dateMinute < 10 ? `0${it.dateMinute}` : it.dateMinute}`,
                                                                    value: it._id
                                                                }
                                                            })}
                                                            value={
                                                                {
                                                                    label: `${props.match.params.dateHour}:${props.match.params.dateMinute < 10 ? `0${props.match.params.dateMinute}` : props.match.params.dateMinute}`,
                                                                    value: -1
                                                                }
                                                            }
                                                            multi={false}
                                                            changecallback={(e) => { setSelectedTime({ ...selectedTime, dateHour: e.dateHour, dateMinute: e.dateMinute }); }}
                                                        />
                                                    </Grid>
                                                }

                                                <Grid item xs={3} className={classes.grid}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox checked={isOverturn} onChange={() => { setIsOverturn(!isOverturn); }} />
                                                        }
                                                        label={"Sobreturno"} />
                                                </Grid>
                                                <Grid item xs={12} className={classes.grid}>
                                                    <TextField
                                                        className={classes.textField}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        label={'Doctor'}
                                                        value={doctor.fullname}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }} />
                                                </Grid>

                                                <Button color="primary" variant="contained" onClick={() => setPatientModalShow(true)}>
                                                    Nuevo paciente
                                                    <Person className={classes.toolbarIcon} />
                                                </Button>

                                                <Grid item xs={12} className={classes.grid}>
                                                    <PredictiveComboBox
                                                        label={'Paciente'}
                                                        suggestions={patients.map((it, ix) => {
                                                            return {
                                                                key: ix,
                                                                label: capitalize(`${it.uid} - ${it.lastname !== undefined ? it.lastname.trim() + ", " : ""}${it.fullname}`),
                                                                value: it._id,
                                                            };
                                                        })}
                                                        multi={false}
                                                        value={selectedPatient}
                                                        changecallback={p => { setSelectedPatient(p); console.log(p); }}
                                                    />
                                                </Grid>
                                                {selectedPatient !== undefined && <div>
                                                    <Grid item xs={12} className={classes.grid}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            label={'DNI'}
                                                            className={classes.textField}
                                                            value={!selectedPatient ? " " : patients.filter(x => x._id === selectedPatient.value)[0].uid}
                                                            name={'patient'}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                readOnly: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} className={classes.grid}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            label={'Telefono'}
                                                            className={classes.textField}
                                                            value={!selectedPatient ? " " : patients.filter(x => x._id === selectedPatient.value)[0].phone}
                                                            name={'patient'}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                readOnly: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} className={classes.grid}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            label={'Email'}
                                                            className={classes.textField}
                                                            value={!selectedPatient ? " " : patients.filter(x => x._id === selectedPatient.value)[0].mail}
                                                            name={'patient'}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                readOnly: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} className={classes.grid}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            label={'Cobertura'}
                                                            className={classes.textField}
                                                            value=
                                                            {!selectedPatient ? " "
                                                                : getUserPrepaidName(patients.filter(x => x._id === selectedPatient.value)[0])}
                                                            name={'patient'}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                readOnly: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} className={classes.grid}>
                                                        <TextField
                                                            InputProps={{
                                                                readOnly: true,
                                                            }}
                                                            label={'Numero Cobertura'}
                                                            className={classes.textField}
                                                            value={!selectedPatient ? " " : patients.filter(x => x._id === selectedPatient.value)[0].prepaidNumber}
                                                            name={'patient'}
                                                            InputLabelProps={{
                                                                shrink: true,
                                                                readOnly: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} className={classes.grid}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={!selectedPatient ? " " : patients.filter(x => x._id === selectedPatient.value)[0].taxed}
                                                                />
                                                            }
                                                            label={'Grabado'}
                                                        />
                                                    </Grid>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        className={classes.button}
                                                        onClick={() => handlePatientView()}
                                                    >
                                                        Editar Paciente
                                                </Button>
                                                </div>}
                                                <Grid item xs={12} className={classes.grid}>
                                                    <PredictiveComboBox
                                                        label={"Estudios"}
                                                        //enable this when all treatments are configured correctly
                                                        suggestions={treatmentTypes.filter(x => true /*x.doctors.indexOf(doctor._id) > 0*/).map((it, ix) => {
                                                            return {
                                                                key: ix,
                                                                label: it.name,
                                                                value: it._id
                                                            }
                                                        })}
                                                        multi={true}
                                                        value={selectedTreatments}
                                                        reduce={false}
                                                        changecallback={(v) => { if (v === null) setSelectedTreatments([]); else setSelectedTreatments([...v]); }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}><br /></Grid>
                                                <Grid item xs={12}><br /></Grid>
                                                <Grid item xs={4} className={classes.grid}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox checked={needsReminder} onChange={() => { setNeedsReminder(!needsReminder) }} />
                                                        }
                                                        label={"Enviar al mail"} />
                                                </Grid>
                                                <Grid item xs={12} className={classes.grid}>
                                                    <Button
                                                        onClick={handleAccept}
                                                        color="primary"
                                                        variant="contained">
                                                        Aceptar
                                                </Button>
                                                </Grid>
                                            </Grid>
                                        }
                                    </Paper>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Modal
                        open={patientModalShow}
                        style={{
                            overflow: 'scroll',
                            height: '100%',
                            display: 'block'
                        }}
                        onClose={() => { }}>
                        <PatientPopup viewDetails={handleViewDetails} patientId={selectedPatient && selectedPatient.value} openDialog={props.openDialog} onClose={() => setPatientModalShow(false)} onPatient={(p) => {

                            setPatients([...patients, p]);
                            setPatientModalShow(false);
                            setSelectedPatient({ value: p._id });
                        }} />
                    </Modal>
                </ Container>
            }
        />
    );
}
