import React from 'react';
import { logout } from '../actions/auth';

export default function Exit(props) {
  logout();
  props.history.push('/');

  return (
    <div>
     
    </div>
  );
}
