import axios from 'axios';

export const getAllTreatmentPrices = async () => {
  try {
    const res = await axios.get('/treatmentTypePrices');
    const r = { success: true, message: '', data: res.data };
    return r;
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const getTreatmentPriceByTreament = async (id, all) => {
  try {
    const res = await axios.get(`/treatmentTypePrices/${id}`);

    let data = res.data.sort((a, b) => a.from - b.from);
    if (all === undefined) {
      data = res.data.filter(x => {
        return Date.now() > x.from;
      });
    }

    return { success: true, message: '', data: data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const createTreatmentPrice = async treatment => {
  try {
    const res = await axios.post(`/treatmentTypePrices/`, treatment);

    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const modifyTreatmentPrice = async treatment => {
  try {
    const res = await axios.put(`/treatmentTypePrices/`, treatment);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const deleteTreatmentPrice = async id => {
  try {
    const res = await axios.delete(`/treatmentTypePrices/${id}`);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};