import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid } from '@material-ui/core/';
import AppContainer from './AppContainer';
import { mobileCheck } from '../utils/mobileCheck';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    paper: {
        padding: theme.spacing(2),
    },
    container: {
        padding: theme.spacing(mobileCheck() ? 0 : 4),
    },
    grid: {
        margin: '10px',
        width: '100%',
    },
    textField: {
        width: '100%',
    },
    button: {
        margin: theme.spacing(1),
    },
}));

export default function Home(props) {
    const classes = useStyles();

    return (
        <div>
            <AppContainer title={"Inicio"} history={props.history} content={
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                    </Grid>
                </Container>
            } />
        </div>
    );
}
