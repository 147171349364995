import axios from 'axios';

export const getAllUsers = async () => {
  try {
    const res = await axios.get(`/users/`);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const getUser = async id => {
  try {
    const res = await axios.get(`/users/${id}`);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const createUser = async user => {
  try {
    const res = await axios.post(`/users/`, user);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const modifyUser = async user => {
  try {
    const res = await axios.put(`/users/`, user);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    //console.log(JSON.stringify(err));
    return { success: false, message: err.response.data };
  }
};

export const changePassword = async (_id, password) => {
  try {
    const res = await axios.put(`/users/changePassword`, { _id, password });
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const deleteUser = async id => {
  try {
    const res = await axios.delete(`/users/${id}`);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};
