import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, ListItemText, ListItem, Tooltip } from '@material-ui/core';
import { mobileCheck } from '../utils/mobileCheck';
import { useDrag, useDrop } from 'react-dnd'


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    paper: {
        padding: theme.spacing(2),
    },
    container: {
        padding: theme.spacing(mobileCheck() ? 0 : 4),
    },
    grid: {
        margin: '10px',
    },
    textField: {
        width: '100%',
    },
    button: {
        margin: theme.spacing(1),
    },
}));

export default function TreatmentListItem(props) {
    const classes = useStyles();
    const [textValue] = React.useState(props.text);

    const ref = useRef(null);


    const [{ opacity }, drag] = useDrag({
        type: 'TreatmentListItem',
        item: { text: textValue, index: props.index },
        collect: (monitor) => {
            return ({
                opacity: monitor.isDragging() ? 0.5 : 1
            })
        }
    })

    const [, drop] = useDrop({
        accept: 'TreatmentListItem',
        drop(item, monitor) {
            const dragIndex = item.index;
            const hoverIndex = props.index;
            if (dragIndex === hoverIndex) {
                return;
            }

            props.itemDropped && props.itemDropped(dragIndex, hoverIndex);
        }
    });

    drag(drop(ref));

    return (
        <div ref={ref} style={{ opacity }} >
            <ListItem onClick={() => props.onclick ? props.onclick() : {}}>
                <ListItemText className={classes.listItemText} primary={textValue} />
                {props.icons &&
                    props.icons.map(
                        (icon, index) => {
                            return (icon &&
                                <Tooltip
                                    key={index}
                                    title={icon.tooltip}>
                                    <IconButton
                                        onClick={() => {
                                            if (props.editable)
                                                icon.callback(textValue);
                                            else
                                                icon.callback(props.item);
                                        }}>
                                        {icon.icon}
                                    </IconButton>
                                </Tooltip>
                            )
                        }
                    )}
            </ListItem>
        </div >
    );
}
