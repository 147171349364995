import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Grid,
  IconButton,
  TextField,
  Dialog,
  DialogContent,
  Tooltip,
  Typography,
} from '@material-ui/core/';
import { numberToDay, numberToTime, dateToString } from '../utils/dateToString';
import { Add, CalendarToday, Delete } from '@material-ui/icons';
import AvailabilityDialog from './AvailabilityDialog';
import AvailabilityDialogException from './AvailabilityDialogException';
import AvailabilityDialogParticular from './AvailabilityDialogParticular';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  dialog: {
    width: '100%',
  },
  paper: {
    padding: theme.spacing(2),
  },
  container: {
    width: '100%',
    padding: theme.spacing(0),
  },
  grid: {
    margin: '10px',
  },
  textField: {
    width: '100%',
    margin: theme.spacing(1),
  },
  textFieldNotes: {
    width: '100%',
    height: '250px',
  },
  button: {
    margin: theme.spacing(1),
  },
  typography: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    margin: theme.spacing(1),
  },
}));

export default function Availability(props) {
  const classes = useStyles();

  const [state, setState] = React.useState({
    loaded: false,
    addDialog: false,
    exceptionDialog: false,
  });

  const [pageLoaded, setPageLoaded] = React.useState(false);

  const [schedule, setSchedule] = React.useState({});

  /* eslint-disable*/
  useEffect(() => {
    if (pageLoaded) return;
    setPageLoaded(true);
    setSchedule(props.schedule);
  }, []);
  /* eslint-enable*/

  function displayAvailability(availability, index) {
    if (!availability) return;

    if (!availability.day) return;

    let days = '';
    for (let i = 0; i < availability.day.length; i++) {
      const day = availability.day[i];
      days += numberToDay(day) + ', ';
    }
    days = days.slice(0, days.length - 2);

    let ranges = [];

    for (let i = 0; i < availability.range.length; i++) {
      const range = availability.range[i];

      const f = numberToTime(range.from);
      const t = numberToTime(range.to);
      ranges.push(`${f} - ${t}`);
    }

    return (
      <Grid key={JSON.stringify(availability)} container>
        <Grid item xs={9} className={classes.grid}>
          <TextField
            InputProps={{
              readOnly: !state.edit,
            }}
            className={classes.textField}
            value={days}
          />
        </Grid>
        <Grid item xs={2} className={classes.grid}>
          {props.edit && (
            <Tooltip title="Eliminar">
              <IconButton onClick={() => handleRemove(availability, index)}>
                <Delete />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
        {ranges.map((item, idx) => (
          <Grid key={item} item xs={3} className={classes.grid}>
            <TextField
              InputProps={{
                readOnly: !state.edit,
              }}
              className={classes.textField}
              value={item}
            />
          </Grid>
        ))}
      </Grid>
    );
  }

  function displayAvailabilityParticulars(availabilityParticular, index) {
    if (!availabilityParticular) return;

    if (!availabilityParticular.day) return;

    let days = '';
    for (let i = 0; i < availabilityParticular.day.length; i++) {
      const day = availabilityParticular.day[i];
      days += numberToDay(day) + ', ';
    }
    days = days.slice(0, days.length - 2);

    let ranges = [];

    for (let i = 0; i < availabilityParticular.range.length; i++) {
      const range = availabilityParticular.range[i];

      const f = numberToTime(range.from);
      const t = numberToTime(range.to);
      ranges.push(`${f} - ${t}`);
    }
    let dateFrom = dateToString(new Date(availabilityParticular.dateRange[0].from));
    let dateTo = dateToString(new Date(availabilityParticular.dateRange[0].to));

    return (
      <Grid key={JSON.stringify(availabilityParticular)} container>
        <Grid item xs={9} className={classes.grid}>
          <Typography>Fecha adicional:</Typography>
        </Grid>
        <Grid item xs={9} className={classes.grid}>
          <TextField
            InputProps={{
              readOnly: !state.edit,
            }}
            className={classes.textField}
            value={dateFrom}
          />
        </Grid>

        <Grid item xs={9} className={classes.grid}>
          <TextField
            InputProps={{
              readOnly: !state.edit,
            }}
            className={classes.textField}
            value={dateTo}
          />
        </Grid>
        <Grid item xs={9} className={classes.grid}>
          <TextField
            InputProps={{
              readOnly: !state.edit,
            }}
            className={classes.textField}
            value={days}
          />
        </Grid>
        <Grid item xs={2} className={classes.grid}>
          {props.edit && (
            <Tooltip title="Eliminar">
              <IconButton onClick={() => handleRemoveParticular(availabilityParticular, index)}>
                <Delete />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
        {ranges.map((item, idx) => (
          <Grid key={item} item xs={3} className={classes.grid}>
            <TextField
              InputProps={{
                readOnly: !state.edit,
              }}
              className={classes.textField}
              value={item}
            />
          </Grid>
        ))}
      </Grid>
    );
  }

  function displayAvailabilityException(availabilityException, index) {
    if (!availabilityException) return;

    if (!availabilityException.day) return;

    let days = '';
    for (let i = 0; i < availabilityException.day.length; i++) {
      const day = availabilityException.day[i];
      days += numberToDay(day) + ', ';
    }
    days = days.slice(0, days.length - 2);

    let timeRanges = [];

    for (let i = 0; i < availabilityException.timeRange.length; i++) {
      const timeRange = availabilityException.timeRange[i];

      const f = numberToTime(timeRange.from);
      const t = numberToTime(timeRange.to);
      timeRanges.push(`${f} - ${t}`);
    }

    let dateFrom = dateToString(new Date(availabilityException.dateRange[0].from));
    let dateTo = dateToString(new Date(availabilityException.dateRange[0].to));

    return (
      <Grid key={JSON.stringify(availabilityException)} container>
        <Grid item xs={9} className={classes.grid}>
          <Typography>Excepción:</Typography>
        </Grid>
        <Grid item xs={9} className={classes.grid}>
          <TextField
            InputProps={{
              readOnly: !state.edit,
            }}
            className={classes.textField}
            value={days}
          />
        </Grid>
        <Grid item xs={2} className={classes.grid}>
          {props.edit && (
            <Tooltip title="Eliminar">
              <IconButton onClick={() => handleRemoveException(availabilityException, index)}>
                <Delete />
              </IconButton>
            </Tooltip>
          )}
        </Grid>

        <Grid item xs={9} className={classes.grid}>
          <TextField
            InputProps={{
              readOnly: !state.edit,
            }}
            className={classes.textField}
            value={dateFrom}
          />
        </Grid>

        <Grid item xs={9} className={classes.grid}>
          <TextField
            InputProps={{
              readOnly: !state.edit,
            }}
            className={classes.textField}
            value={dateTo}
          />
        </Grid>
        {false &&
          timeRanges.map((item, idx) => (
            <Grid key={idx} item xs={3} className={classes.grid}>
              <TextField
                InputProps={{
                  readOnly: !state.edit,
                }}
                className={classes.textField}
                value={item}
              />
            </Grid>
          ))}
      </Grid>
    );
  }

  function handleRemove(availability, index) {
    props.removeCallback(availability, index);
  }

  function handleRemoveParticular(availabilityParticular, index) {
    props.removeParticularCallback(availabilityParticular, index);
  }

  function handleRemoveException(availabilityException, index) {
    props.removeExceptionCallback(availabilityException, index);
  }

  function handleCloseDialog(data) {
    setState({ ...state, addDialog: false });

    if (!data) return;

    props.addCallback(data);
  }

  function handleCloseExceptionDialog(data) {
    setState({ ...state, exceptionDialog: false });

    if (!data) return;

    props.addExceptionCallback(data);
  }

  function handleCloseParticularDialog(data) {
    setState({ ...state, particularDialog: false });

    if (!data) return;

    props.addParticularCallback && props.addParticularCallback(data);
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container>
        <Grid item xs={12} className={classes.grid}>
          {props.edit && (
            <Tooltip title="Agregar disponibilidad">
              <IconButton onClick={() => setState({ state, addDialog: true })}>
                <Add />
              </IconButton>
            </Tooltip>
          )}
          {props.edit && (
            <Tooltip title="Agregar excepción">
              <IconButton onClick={() => setState({ state, exceptionDialog: true })}>
                <Delete />
              </IconButton>
            </Tooltip>
          )}
          {props.edit && (
            <Tooltip title="Agregar particular">
              <IconButton onClick={() => setState({ state, particularDialog: true })}>
                <CalendarToday />
              </IconButton>
            </Tooltip>
          )}
          <Grid container>
            <Grid item xs={12} className={classes.grid}>
              {schedule.availability &&
                schedule.availability.map((item, index) => displayAvailability(item, index))}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} className={classes.grid}>
              {schedule.exceptions &&
                schedule.exceptions.map((item, index) => displayAvailabilityException(item, index))}
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} className={classes.grid}>
              {schedule.particulars &&
                schedule.particulars.map((item, index) => displayAvailabilityParticulars(item, index))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Dialog
        className={classes.dialog}
        open={state.addDialog ? state.addDialog : false}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <AvailabilityDialog close={data => handleCloseDialog(data)} />
        </DialogContent>
      </Dialog>
      <Dialog
        className={classes.dialog}
        open={state.exceptionDialog ? state.exceptionDialog : false}
        onClose={handleCloseExceptionDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <AvailabilityDialogException close={data => handleCloseExceptionDialog(data)} />
        </DialogContent>
      </Dialog>
      <Dialog
        className={classes.dialog}
        open={state.particularDialog ? state.particularDialog : false}
        onClose={handleCloseParticularDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <AvailabilityDialogParticular close={data => handleCloseParticularDialog(data)} />
        </DialogContent>
      </Dialog>
    </Container>
  );
}
