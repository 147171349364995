import React, {useState} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PrivateRoute from './routes/PrivateRoute';
import LoginView from './components/LoginView';
import NotFoundView from './components/NotFoundView';
import Home from './components/Home';
import ScheduleSecretary from './components/ScheduleSecretary';
import Prepaids from './components/Prepaids';
import Prepaid from './components/Prepaid';
import Patients from './components/Patients';
import Patient from './components/Patient';
import Users from './components/Users';
import User from './components/User';
import Treatments from './components/Treatments';
import TreatmentNew from './components/TreatmentNew';
import Treatment from './components/Treatment';
import TreatmentsPricesEdit from './components/TreatmentsPricesEdit';
import TreatmentsPricesHistory from './components/TreatmentsPricesHistory';
import Billing from './components/Billing';
import TreatmentCode from './components/TreatmentCode';
import TreatmentCodes from './components/TreatmentCodes';
import RecoverPasswordView from './components/RecoverPasswordView';
import UpdatePasswordView from './components/UpdatePasswordView';
import MedicalExam from './components/MedicalExam';
import Supplies from './components/Supplies';
import ScheduleDoctor from './components/ScheduleDoctor';
import NewTurn from './components/NewTurn';
import NewTurnFromScratch from './components/NewTurnFromScratch';
import Supply from './components/Supply';
import Holidays from './components/Holidays';
import Holiday from './components/Holiday';

import Exit from './components/Exit';
import DownloadExcel from './components/DownloadExcel';
import { useEffect } from 'react';
import { getApiPort } from './utils/authUtils';
//const TreatmentsPricesHistory =React.lazy(()=>import ('./components/TreatmentsPricesHistory'));
//const  ScheduleSecretary =React.lazy(()=>import ('./components/ScheduleSecretary'));
function App() {

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {loadConfig()}, [])

  const loadConfig = async () => {

 // if (process.env.NODE_ENV === 'production')

    await getApiPort();
    
    setLoaded(true);
  }
  
  return (<>
    {loaded &&
    <Router>
      <Switch>
        <Route path="/" exact component={LoginView} />
        <Route path="/recover/" exact component={RecoverPasswordView} />
        <Route path="/update/:token" exact component={UpdatePasswordView} />
        <PrivateRoute path="/home/" exact component={Home} />
        <PrivateRoute path="/prepaids/" exact component={Prepaids} />
        <PrivateRoute path="/prepaid/" exact component={Prepaid} />
        <PrivateRoute path="/prepaid/:id" exact component={Prepaid} />
        <PrivateRoute path="/patients/" exact component={Patients} />
        <PrivateRoute path="/patient/" exact component={Patient} />
        <PrivateRoute path="/patient/:id" exact component={Patient} />
        <PrivateRoute path="/holidays/" exact component={Holidays} />
        <PrivateRoute path="/holiday/" exact component={Holiday} />
        <PrivateRoute path="/holiday/:id" exact component={Holiday} />
        <PrivateRoute path="/users/" exact component={Users} />
        <PrivateRoute path="/user/" exact component={User} />
        <PrivateRoute path="/user/:id" exact component={User} />
        <PrivateRoute
          path="/newturn/:doctor/:dateYear/:dateMonth/:dateDay/:dateHour/:dateMinute"
          exact
          component={NewTurn}
        />
        <PrivateRoute
          path="/newturnfromscratch/"
          exact
          component={NewTurnFromScratch}
        />
        <PrivateRoute
          path="/newturn/:doctor/:dateYear/:dateMonth/:dateDay/:dateHour/:dateMinute/:patient"
          exact
          component={NewTurn}
        />
        <PrivateRoute path="/scheduleDoctor/" exact component={ScheduleDoctor} />
        <PrivateRoute path="/scheduleDoctor/:doctor" exact component={ScheduleDoctor} />
        <PrivateRoute path="/scheduleDoctor/:dateYear/:dateMonth/:dateDay" exact component={ScheduleDoctor} />
        <PrivateRoute
          path="/scheduleDoctor/:doctor/:dateYear/:dateMonth/:dateDay"
          exact
          component={ScheduleDoctor}
        />
        <PrivateRoute path="/schedule/" exact component={ScheduleSecretary} />
        <PrivateRoute path="/schedule/:doctor" exact component={ScheduleSecretary} />
        <PrivateRoute path="/schedule/:dateYear/:dateMonth/:dateDay" exact component={ScheduleSecretary} />
        <PrivateRoute
          path="/schedule/:doctor/:dateYear/:dateMonth/:dateDay"
          exact
          component={ScheduleSecretary}
        />
        <PrivateRoute path="/medicalExam/patient/:patient" exact component={MedicalExam} />
        <PrivateRoute
          path="/medicalExam/patient/:patient/treatment/:treatment"
          exact
          component={MedicalExam}
        />
        <PrivateRoute path="/medicalExam/:id" exact component={MedicalExam} />
        <PrivateRoute path="/medicalExam/" exact component={MedicalExam} />
        <PrivateRoute path="/treatmentCode/" exact component={TreatmentCode} />
        <PrivateRoute path="/treatmentCode/:id" exact component={TreatmentCode} />
        <PrivateRoute path="/treatmentCodes/" exact component={TreatmentCodes} />
        <PrivateRoute path="/treatments/" exact component={Treatments} />
        <PrivateRoute path="/treatment/" exact component={TreatmentNew} />
        <PrivateRoute path="/treatmentPricesEdit/" exact component={TreatmentsPricesEdit} />
        <PrivateRoute path="/treatmentPricesHistory" exact component={TreatmentsPricesHistory} />
        <PrivateRoute path="/treatment/:id" exact component={Treatment} />
        <PrivateRoute path="/billing/" exact component={Billing} />
        <PrivateRoute path="/billing/:year/" exact component={Billing} />
        <PrivateRoute path="/billing/:year/:month" exact component={Billing} />
        <PrivateRoute path="/billing/:year/:month/:doctor" exact component={Billing} />
        <PrivateRoute path="/supplies/" exact component={Supplies} />
        <PrivateRoute path="/supply/:id" exact component={Supply} />
        <PrivateRoute path="/supply/" exact component={Supply} />
        <PrivateRoute path="/exit/" exact component={Exit} />
        <PrivateRoute path="/download/" exact component={DownloadExcel} />
        <Route component={NotFoundView} />
      </Switch>
    </Router>}</>
  );
}
export default App;
