
export function capitalize(interesting) {
    if (interesting === undefined || interesting === null)
        return '';
    let eee = interesting;
    eee = eee.toLowerCase();
    let sp = eee.split(' ');

    let result = [];

    sp.forEach(el => {
        let cap = el.toUpperCase();

        el = el.slice(1);

        cap = cap.slice(0, 1);

        result.push(cap + el);
    });

    eee = result.join(' ');

    if (interesting !== eee)
        return eee;

    return interesting;
}