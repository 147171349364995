import axios from "axios";

export const getAllPrepaidTreatments = async (prepaid) => {
  try {
    const res = await axios.get(`/treatmentPrepaid/${prepaid}`);
    //Filtro los treatments que existen en cada obra social y los ordeno por el numero de posicion
    const prepaidPrices = res.data.treatments
      .filter((treatment) => {
        if (treatment.price.length > 0) {
          return treatment.price.slice(-1)[0].price > 0;
        }
        return false;
      })
      .sort(function (a, b) {
        return a.position - b.position;
      });
    const r = {
      success: true,
      message: "",
      prepaidPrices: prepaidPrices,
      percentageAdjustments: res.data.futurePercentageAdjustments,
    };
    return r;
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};
export const addPrepaidPriceAdjustment = async (
  prepaid,
  from,
  percentage,
  treatments
) => {
  try {
    const res = await axios.post(
      "/treatmentPrepaid/",
      prepaid,
      from,
      percentage ? percentage : "0",
      treatments
    );

    const r = { success: true, message: "", data: res };
    return r;
  } catch (err) {
    if (err.response) {
      return { success: false, message: err.response.data };
    } else if (err.request) {
      return { success: false, message: "Error de conexión" };
    } else {
      return { success: false, message: err.message };
    }
  }
};
export const deletePrepaidAdjustment = async (id) => {
  try {
    const res = await axios.delete(`/treatmentPrepaid/${id}`);
    const r = { success: true, message: "", data: res };
    return r;
  } catch (err) {
    if (err.response) {
      return { success: false, message: err.response.data };
    } else if (err.request) {
      return { success: false, message: "Error de conexión" };
    } else {
      return { success: false, message: err.message };
    }
  }
};
export const getTreatmentsPricesHistory = async (prepaid, from, to) => {
  try {
    const res = await axios.get(`/treatmentPrepaid/${prepaid}/${from}/${to}`);
    //Filtro los treatments que existen en cada obra social y los ordeno por el numero de posicion
    console.log(res.data.treatments);
    const prepaidPrices = res.data.treatments
      .filter((treatment) => {
        if (treatment.price.length > 0) {
          return treatment.price.slice(-1)[0].price > 0;
        }
        return false;
      })
      .sort(function (a, b) {
        return a.position - b.position;
      });
    const r = {
      success: true,
      message: "",
      prices: prepaidPrices,
      adjustments: res.data.prepaidAdjustments,
    };
    return r;
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const checkFuturePrices = async (prepaid, treatmentType, from) => {
  try {
    const res = await axios.post("/treatmentPrepaid/chechfuture/", {
      prepaid,
      treatmentType,
      from,
    });
    return { success: true, isFutureAdjustments: res.data.isFutureAdjustments };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};
