import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Container, Grid, TextField, IconButton, Typography, List, ListItem, Tooltip } from '@material-ui/core/';
import AppContainer from './AppContainer';
import { getPrepaid, createPrepaid, modifyPrepaid, deletePrepaid } from '../actions/prepaids';
import { Edit, Delete, Done, Close, Add } from '@material-ui/icons';
import CustomListItem from './CustomListItem';
import { isAdmin } from '../utils/authUtils';
import { mobileCheck } from '../utils/mobileCheck';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    paper: {
        padding: theme.spacing(2),
    },
    container: {
        padding: theme.spacing(mobileCheck() ? 0 : 4),
    },
    grid: {
        margin: '10px',
    },
    textField: {
        width: '100%',
    },
    button: {
        margin: theme.spacing(1),
    },
    typography: {
        margin: theme.spacing(1),
    },
}));

export default function Prepaid(props) {
    const classes = useStyles();

    const [originalObjectData, setOriginalObjectData] = React.useState({
        name: '',
        notes: '',
        types: [],
    });

    const [objectData, setObjectData] = React.useState({
        name: '',
        notes: '',
        types: [],
    });

    const [newType, setNewType] = React.useState("");

    const [state, setState] = React.useState({
        loaded: false,
        create: false,
        edit: false,
        addNewType: false,
    })
    
    const [pageLoaded, setPageLoaded] = React.useState(false);

    /* eslint-disable*/
    useEffect(() => {
        if(pageLoaded) return;

        if (props.match.params.id)
            fetchData(props.match.params.id);
        else
            setState({ ...state, create: true, edit: true, loaded: true });
    }, []);
    /* eslint-enable*/

    async function fetchData(id) {
        const result = await getPrepaid(id);

        if (!result.success) {
            if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
            props.openSnack(result.message.error, 'error');

            return;
        }

        setObjectData(result.data);
        setOriginalObjectData(result.data);
        setPageLoaded(true);
    }

    function handleEdit() {
        setState({ ...state, edit: true });
    }

    function handleCancelEdit() {
        if (state.create) {
            props.history.push('/prepaids');
            return;
        }

        setObjectData({ ...originalObjectData });
        setState({ ...state, edit: false });
    }

    async function handleDoneEdit() {
        let result = {};
        if (state.create) {
            result = await createPrepaid(objectData);
            props.history.push('/prepaid/' + result.data[0]._id);
        }
        else
            result = await modifyPrepaid(objectData);

        if (!result.success) {
            if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
            props.openSnack(result.message.error, 'error');

            return;
        }

        setOriginalObjectData({ ...result.data });
        setState({ ...state, edit: false, create: false });
    }

    function handleDeletePrepaid() {
        if (!state.create)
            props.openDialog("Confirmación", "Seguro que desea eliminar esta prepaga?", objectData, handleDeleteCallback);
        else
            props.history.push('/prepaids');
    }

    async function handleDeleteCallback(confirmed, item) {
        if (!confirmed)
            return;

        const result = await deletePrepaid(item._id);
        if (!result.success) {
            if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
            props.openSnack(result.message.error, 'error');
            return;
        }
        props.history.push('/prepaids');
    }

    function handleAddNewType() {
        setState({ ...state, addNewType: true });
    }

    function handleAddType(item) {
        const newTypes = objectData.types ? [...objectData.types] : [];
        newTypes.push(item);
        setObjectData({ ...objectData, types: newTypes });
        setState({ ...state, addNewType: false });
        setNewType("");
    }

    function handleDeleteType(item) {
        const newTypes = [...objectData.types];
        

        for (var i = newTypes.length - 1; i >= 0; i--) {
            if (newTypes[i] === item) {
                newTypes[i] = "";
                break;
            }
        }
        setObjectData({ ...objectData, types: newTypes });
    }

    function handleChange(event) {
        setObjectData({
            ...objectData,
            [event.target.name]: event.target.value,
        });
    }

    return (
        <div>
            <AppContainer title={"Prepaga"} history={props.history} content={
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        {!mobileCheck() && <Grid item xs={1} className={classes.grid} />}
                        <Grid item xs={!mobileCheck() ? 10 : 12} className={classes.grid}>
                            <Grid container>
                                <Grid item className={classes.grid}>
                                    <Typography variant="h6" noWrap className={classes.typography}>
                                        Información
                                </Typography>
                                </Grid>
                                <Grid item className={classes.grid}>
                                    {(isAdmin() && !state.edit) && <Tooltip title="Editar"><IconButton onClick={handleEdit}><Edit /></IconButton></Tooltip>}
                                    {state.edit && <Tooltip title="Cancelar"><IconButton onClick={handleCancelEdit}><Close /></IconButton></Tooltip>}
                                    {state.edit && <Tooltip title="Aceptar"><IconButton onClick={handleDoneEdit}><Done color="primary" /></IconButton></Tooltip>}
                                    {(isAdmin() && state.edit) && <Tooltip title="Eliminar"><IconButton onClick={handleDeletePrepaid}><Delete color="error" /></IconButton></Tooltip>}
                                </Grid>
                            </Grid>
                            <Paper className={classes.paper}>
                                <Grid container>
                                    <Grid item xs={8} className={classes.grid}>
                                        <TextField
                                            InputProps={{
                                                readOnly: !state.edit,
                                            }}
                                            label={'Nombre'}
                                            className={classes.textField}
                                            value={objectData.name}
                                            name={'name'}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={e => {
                                                if (!state.edit) return;
                                                handleChange(e);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={8} className={classes.grid}>
                                        <TextField
                                            InputProps={{
                                                readOnly: !state.edit,
                                            }}
                                            label={'Notas'}
                                            className={classes.textField}
                                            value={objectData.notes}
                                            name={'notes'}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={e => {
                                                if (!state.edit) return;
                                                handleChange(e);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                            <Typography variant="h6" noWrap className={classes.typography}>
                                Planes
                        </Typography>
                            <Paper className={classes.paper}>
                                {!state.addNewType && state.edit && <Grid container className={classes.grid}>
                                    <Grid item>
                                        <Tooltip title="Agregar nuevo tipo">
                                            <IconButton onClick={handleAddNewType}><Add /></IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>}
                                <List
                                    component="nav"
                                    className={classes.list}
                                >
                                    {state.addNewType && state.edit &&
                                        <ListItem>
                                            <TextField
                                                InputProps={{
                                                    readOnly: !state.edit,
                                                }}
                                                label={'Tipo'}
                                                className={classes.textField}
                                                value={newType}
                                                name={'newType'}
                                                InputLabelProps={{
                                                    readOnly: !state.edit,
                                                    shrink: true,
                                                }}
                                                onChange={e => {
                                                    if (!state.edit) return;
                                                    setNewType(e.target.value);
                                                }}
                                            />
                                            <Tooltip title="Aceptar">
                                                <IconButton onClick={() => handleAddType(newType)}><Done /></IconButton>
                                            </Tooltip>
                                        </ListItem>
                                    }
                                    {objectData.types && objectData.types.filter((item) => item !== "").map((item, index) =>
                                        (<CustomListItem
                                            key={index}
                                            item={item}
                                            text={item}
                                            icons={state.edit && [
                                                isAdmin() ? { icon: <Delete />, callback: handleDeleteType, tooltip: "Eliminar" } : undefined,
                                            ]} />)
                                    )}
                                </List>
                            </Paper>
                        </Grid>
                    </Grid>
                </ Container>
            } />
        </div>
    );
}
