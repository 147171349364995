import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Grid, Chip } from '@material-ui/core';
import { Done } from '@material-ui/icons';
import { mobileCheck } from '../utils/mobileCheck';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    paper: {
        padding: theme.spacing(2),
    },
    container: {
        padding: theme.spacing(mobileCheck() ? 0 : 4),
    },
    grid: {
        margin: '10px',
    },
    textField: {
        width: '100%',
    },
    button: {
        margin: theme.spacing(1),
    },
}));

export default function PatientPendingTurn(props) {
    const classes = useStyles();

    return (
        <Grid container>
            <Grid item xs={2} className={classes.grid}>
                <TextField
                    InputProps={{
                        readOnly: true,
                    }}
                    id="date"
                    label="Fecha"
                    value={`${props.turn.dateDay}/${props.turn.dateMonth + 1}/${props.turn.dateYear}`}
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                        readOnly: true,
                    }} />
            </Grid>
            <Grid item xs={1} className={classes.grid}>
                <TextField
                    InputProps={{
                        readOnly: true,
                    }}
                    id="time"
                    label="Hora"
                    value={`${props.turn.dateHour}:${props.turn.dateMinute < 10 ? `0${props.turn.dateMinute}` : props.turn.dateMinute}`}
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                        readOnly: true,
                    }} />
            </Grid>
            <Grid item xs={3} className={classes.grid}>
                <TextField
                    InputProps={{
                        readOnly: true,
                    }}
                    id="doctor"
                    label="Doctor"
                    value={`${props.turn.doctor.fullname}`}
                    className={classes.textField}
                    InputLabelProps={{
                        shrink: true,
                        readOnly: true,
                    }} />
            </Grid>
            {props.turn.treatments.map((it, ix) =>
                <Grid key={ix} item xs={1} className={classes.grid}>
                    <Chip
                        key={ix}
                        label={it.name}
                        value={it}
                        onDelete={() => { props.handleTreatmentClick(it) }}
                        className={classes.chip}
                        deleteIcon={<Done />}
                    />
                </Grid>)}
        </Grid>
    );
}
