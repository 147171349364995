import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuthenticated, hasPermission } from "../utils/authUtils";
import { Snackbar } from "@material-ui/core";
import MySnackbarContentWrapper from "../components/AppSnackbar";
import ConfirmDialog from "../components/ConfirmDialog";
import { logout } from "../actions/auth";

const PrivateRoute = ({ component: Component, ...rest }) => {
  function tokenExpired() {
    logout();
    /*openDialog("Sesión cerrada", "Su Token ha expirado", undefined, () =>
      logout()
    );*/
  }

  const [snack, setSnack] = React.useState({
    open: false,
    text: "Snack text",
    variant: "error",
  });

  function openSnack(message, variant) {
    setSnack({ ...snack, open: true, text: message, variant: variant });
  }

  function handleSnackClose() {
    setSnack({ ...snack, open: false });
  }

  const [confirmDialog, setConfirmDialog] = React.useState({
    open: false,
    title: "Confirmación",
    message: "Seguro?",
    item: {},
    callback: () => {},
  });

  function openDialog(title, message, item, callback) {
    setConfirmDialog({
      ...confirmDialog,
      open: true,
      title: title,
      message: message,
      item: item,
      callback: callback,
    });
  }

  function handleDeleteConfirm() {
    setConfirmDialog({ ...confirmDialog, open: false });

    if (confirmDialog.callback)
      confirmDialog.callback(true, confirmDialog.item);
  }

  function handleDeleteCancel() {
    setConfirmDialog({ ...confirmDialog, open: false });

    if (confirmDialog.callback)
      confirmDialog.callback(false, confirmDialog.item);
  }

  return (
    <div>
      <Route
        {...rest}
        render={(props) => {
          props = {
            ...props,
            openSnack: openSnack,
            openDialog: openDialog,
            tokenExpired: tokenExpired,
          };
          return !isAuthenticated() || !hasPermission({ ...props }) ? (
            <Redirect to="/" />
          ) : (
            <Component {...props} />
          );
        }}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snack.open}
        autoHideDuration={6000}
        onClose={handleSnackClose}
      >
        <MySnackbarContentWrapper
          onClose={handleSnackClose}
          variant={snack.variant}
          message={snack.text}
        />
      </Snackbar>
      <ConfirmDialog
        open={confirmDialog.open}
        title={confirmDialog.title}
        message={confirmDialog.message}
        accept={handleDeleteConfirm}
        cancel={handleDeleteCancel}
      />
    </div>
  );
};

export default PrivateRoute;
