import React, { useEffect,useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Container, Grid, TextField, IconButton, Typography, Tooltip, CircularProgress } from '@material-ui/core/';
import AppContainer from './AppContainer';
import { getTreatmentType, createTreatmentType, modifyTreatmentType, deleteTreatmentType } from '../actions/treatmentTypes';
import { Edit, Delete, Done, Close, Add } from '@material-ui/icons';
import TreatmentReportListItem from './TreatmentReportListItem';
import TreatmentPrepaidPriceListItem from './TreatmentPrepaidPriceListItem';
import { getPrepaids } from '../actions/prepaids';
import { getTreatmentPriceByTreament, createTreatmentPrice, modifyTreatmentPrice } from '../actions/treatmentTypePrices';
import { mobileCheck } from '../utils/mobileCheck';

const {v4:uuidv4 } = require('uuid');

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    paper: {
        padding: theme.spacing(2),
    },
    container: {
        padding: theme.spacing(mobileCheck() ? 0 : 4),
    },
    grid: {
        margin: '10px',
    },
    textField: {
        width: '100%',
    },
    button: {
        margin: theme.spacing(1),
    },
    typography: {
        margin: theme.spacing(1),
    },
    progress: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',},
}));

export default function Treatment(props) {

    const classes = useStyles();

    const [originalObjectData, setOriginalObjectData] = useState({});

    const [objectData, setObjectData] = useState({
        name: '',
        detail: '',
        affects: '',
        code: '',
        pre: '',
    });

    const [prepaids, setPrepaids] = useState([]);

    const [prepaidPrices, setPrepaidPrices] = useState([]);

    const [state, setState] = useState({
        loaded: false,
        create: false,
        edit: false,
        addNewReport: false,
    })

    const [loading, setLoading] = useState(true);

    const [pageLoaded, setPageLoaded] = useState(false);

    /* eslint-disable*/
    useEffect(() => {
        if (pageLoaded) return;
        setPageLoaded(true);

        if (props.match.params.id)
            fetchData(props.match.params.id);
        else {
            setState({ ...state, create: true, edit: true });
            setLoading(false);
        }
    }, []);
    /* eslint-enable*/

    async function fetchData(id) {
        setLoading(true);
        const treatmentTypes = await getTreatmentType(id);

        if (!treatmentTypes.success) {
            if (treatmentTypes.message.error.indexOf('Token') !== -1) props.tokenExpired();
            props.openSnack(treatmentTypes.message.error, 'error');

            return;
        }

        for (let i = 0; i < treatmentTypes.data.reports.length; i++) {
            let element = treatmentTypes.data.reports[i];
            if (!element.uid)
                element.uid = uuidv4();
        }

        setObjectData(treatmentTypes.data);
        setOriginalObjectData(treatmentTypes.data);

        const prepaids = await getPrepaids();

        if (!prepaids.success) {
            if (prepaids.message.error.indexOf('Token') !== -1) props.tokenExpired();
            props.openSnack(prepaids.message.error, 'error');

            return;
        }

        const prepaidPrices = await getTreatmentPriceByTreament(id, true);

        if (!prepaidPrices.success) {
            if (prepaidPrices.message.error.indexOf('Token') !== -1) props.tokenExpired();
            props.openSnack(prepaidPrices.message.error, 'error');

            return;
        }

        for (let i = 0; i < prepaids.data.length; i++) {
            const prepaid = prepaids.data[i];
            if (prepaidPrices.data.filter(pp => pp.prepaid === prepaid._id).length > 0)
                break;

            prepaidPrices.data.push({ _id: prepaid._id, price: 0, from: 0, treatmentType: treatmentTypes.data._id, prepaid: prepaid._id, });
        }

        setPrepaids(prepaids.data);
        setPrepaidPrices(prepaidPrices.data);
        setLoading(false);
    }

    function handleEdit() {
        setState({ ...state, edit: true });
    }

    function handleCancelEdit() {
        if (state.create) {
            props.history.push('/treatments');
            return;
        }

        setObjectData({ ...originalObjectData });
        setState({ ...state, edit: false });
        fetchData(objectData._id);
    }

    function handleReportEdited(report) {
        let reports = objectData.reports;

        for (let i = 0; i < reports.length; i++) {
            let element = reports[i];
            if (element.uid !== report.uid)
                continue;

            reports[i] = report;
        }
        setObjectData({ ...objectData, reports: [...reports] });

    }

    async function handleDoneEdit() {
        let result = {};
        if (state.create) {
            result = await createTreatmentType(objectData);
            props.history.push('/treatment/' + result.data[0]._id);
        }
        else {
            props.history.push('/treatment/' + objectData._id);
            result = await modifyTreatmentType(objectData);
        }

        if (!result.success) {
            if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
            props.openSnack(result.message.error, 'error');

            return;
        }

        for (let i = 0; i < prepaidPrices.length; i++) {
            const pp = prepaidPrices[i];

            if (!pp.changed)
                continue;

            handlePriceCommit(pp);
        }

        setOriginalObjectData({ ...result.data });
        setState({ ...state, edit: false, create: false });
        window.location.reload();
    }

    function handleDeleteTreatment() {
        if (!state.create)
            props.openDialog("Confirmación", "Seguro que desea eliminar este Estudio?", objectData, handleDeleteCallback);
        else
            props.history.push('/treatments');
    }

    async function handleDeleteCallback(confirmed, item) {
        if (!confirmed)
            return;
        const result = await deleteTreatmentType(item._id);
        if (!result.success) {
            if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
            props.openSnack(result.message.error, 'error');
            return;
        }
        props.history.push('/treatments');
    }

    function handleAddNewReport() {
        setState({ ...state, addNewReport: true });
    }
    
    function handleAddType(item) {
        const newReports = objectData.reports ? [...objectData.reports] : [];
        newReports.push(item);
        setObjectData({ ...objectData, reports: newReports });
        setState({ ...state, addNewReport: false });
    }

    function handleDeleteReport(item) {
        if (!state.create) {
            props.openDialog("Confirmación", "Seguro que desea eliminar este reporte?", item, handleDeleteReportCallback);
        }
        else
            props.history.push('/treatments');
    }

    async function handleDeleteReportCallback(confirmed, item) {
        if (!confirmed)
            return;

        let newReports = objectData.reports ? [...objectData.reports] : [];




        for (var i = 0; i < newReports.length; i++) {
            if (newReports[i].uid === item.uid) {
                newReports.splice(i, 1);
                break;
            }
        }
    setObjectData({ ...objectData, reports: newReports });
    }

    function handleChange(event) {
        setObjectData({
            ...objectData,
            [event.target.name]: event.target.value,
        });
    }

    async function handleMaxChange(price, value) {
        let modifiedItem = prepaidPrices.filter(item => item._id === price._id)[0];

        if (!modifiedItem) {
            modifiedItem = price;
        }

        modifiedItem.max = `${value}`;
        modifiedItem.changed = true;

        const newarray = [...prepaidPrices.filter(item => item._id !== price._id), modifiedItem];

        setPrepaidPrices(newarray);
    }

    async function handlePriceCommit(price) {
        const newprice = { ...price };
        delete (newprice._id);

        let result

        if (price._id === undefined)
            result = await createTreatmentPrice(newprice)
        else
            result = await modifyTreatmentPrice(price)

        if (!result.success) {
            if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
            props.openSnack(result.message.error, 'error');
            return;
        } else {
            props.openSnack("Precio actualizado", 'success');
        }
    }

    function getTreatmentPriceByPrepaid(prepaid) {
        const prices = prepaidPrices.filter(p => prepaid._id === p.prepaid && objectData._id === p.treatmentType && new Date().getTime() > p.from);

        const price = prices[prices.length - 1];

        return price;
    }
    function getFutureTreatmentPricesByPrepaid(prepaid) {
        const allPrices = prepaidPrices.filter(p => prepaid._id === p.prepaid && objectData._id === p.treatmentType);
        const futurePricesAmount = allPrices.filter((price) => Date.now() < price.from).length;
        const prices = allPrices.sort((a, b) => a.from - b.from).slice(allPrices.length - 1 - futurePricesAmount).map(obj => ({ ...obj, status: "original",color:"" }));
        return prices;
    }


    function getFutureTreatmentPriceByPrepaid(prepaid) {

        const prices = prepaidPrices.filter(p => prepaid._id === p.prepaid && objectData._id === p.treatmentType && new Date().getTime());
        const price = prices[prices.length - 1];
        if (price !== undefined) {

            const curr = getTreatmentPriceByPrepaid(prepaid);
            if (curr !== undefined && price._id === curr._id) {

                return undefined;
            }

        }

        return price;
    }

    return (
        <div>
            <AppContainer title={"Estudio"} history={props.history} content={
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        {!mobileCheck() && <Grid item xs={1} className={classes.grid} />}
                        <Grid item xs={!mobileCheck() ? 10 : 12} className={classes.grid}>
                            <Grid container>
                                <Grid item className={classes.grid}>
                                    <Typography variant="h6" noWrap className={classes.typography}>
                                        Información
                                </Typography>
                                </Grid>
                                <Grid item className={classes.grid}>
                                    {!state.edit && <Tooltip title="Editar"><IconButton onClick={handleEdit}><Edit /></IconButton></Tooltip>}
                                    {state.edit && <Tooltip title="Cancelar"><IconButton onClick={handleCancelEdit}><Close /></IconButton></Tooltip>}
                                    {state.edit && <Tooltip title="Aceptar"><IconButton onClick={handleDoneEdit}><Done color="primary" /></IconButton></Tooltip>}
                                    {state.edit && <Tooltip title="Eliminar"><IconButton onClick={handleDeleteTreatment}><Delete color="error" /></IconButton></Tooltip>}
                                </Grid>
                            </Grid>
                            <Paper className={classes.paper}>
                                {loading ?
                                    <Grid item xs={12} className={classes.grid}>
                                        <CircularProgress className={classes.progress} />
                                    </Grid>
                                    :
                                    <Grid container>
                                        <Grid item xs={8} className={classes.grid}>
                                            <TextField
                                                InputProps={{
                                                    readOnly: !state.edit,
                                                }}
                                                label={'Nombre'}
                                                className={classes.textField}
                                                value={objectData.name}
                                                name={'name'}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={e => {
                                                    if (!state.edit) return;
                                                    handleChange(e);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={8} className={classes.grid}>
                                            <TextField
                                                InputProps={{
                                                    readOnly: !state.edit,
                                                }}
                                                label={'Detalle'}
                                                className={classes.textField}
                                                value={objectData.detail}
                                                name={'detail'}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={e => {
                                                    if (!state.edit) return;
                                                    handleChange(e);
                                                }}
                                            />
                                        </Grid>
                                        
                                        <Grid item xs={8} className={classes.grid}>
                                            <TextField
                                                InputProps={{
                                                    readOnly: !state.edit,
                                                }}
                                                label={'Código SMG'}
                                                className={classes.textField}
                                                value={objectData.affects}
                                                name={'affects'}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={e => {
                                                    if (!state.edit) return;
                                                    handleChange(e);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={8} className={classes.grid}>
                                            <TextField
                                                InputProps={{
                                                    readOnly: !state.edit,
                                                }}
                                                label={'Codigo'}
                                                className={classes.textField}
                                                value={objectData.code}
                                                name={'code'}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={e => {
                                                    if (!state.edit) return;
                                                    handleChange(e);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={8} className={classes.grid}>
                                            <TextField
                                                InputProps={{
                                                    readOnly: !state.edit,
                                                }}
                                                label={'Preparacion'}
                                                className={classes.textField}
                                                value={objectData.pre}
                                                name={'pre'}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={e => {
                                                    if (!state.edit) return;
                                                    handleChange(e);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={8} className={classes.grid}>
                                            <TextField
                                                InputProps={{
                                                    readOnly: !state.edit,
                                                }}
                                                label={'Tiempo en minutos'}
                                                className={classes.textField}
                                                name={'turnsTaken'}
                                                type='number'
                                                value={objectData.turnsTaken}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                onChange={e => {
                                                    if (!state.edit) return;
                                                    handleChange(e);
                                                }} />
                                        </Grid>
                                    </Grid>
                                }
                            </Paper>
                            <Grid container>
                                <Grid item xs={10} className={classes.grid}>
                                    <Typography variant="h6" noWrap className={classes.typography}>
                                        Precios
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper className={classes.paper}>
                                        {loading ?
                                            <Grid item xs={12} className={classes.grid}>
                                                <CircularProgress className={classes.progress} />
                                            </Grid>
                                            :
                                            prepaids && prepaids.map((item, index) =>
                                            (<div style={{borderBottom: "1px solid"}}><TreatmentPrepaidPriceListItem
                                                openSnack={props.openSnack}
                                                openDialog={props.openDialog}
                                                key={index}
                                                item={item}
                                                name={item.name}
                                                treatmentType={props.match.params.id}
                                                prepaid={item}
                                                price={getTreatmentPriceByPrepaid(item)}
                                                futurePrices={getFutureTreatmentPricesByPrepaid(item)}
                                                newPrice={getFutureTreatmentPriceByPrepaid(item)}
                                                editable={state.edit}
                                                maxChanged={handleMaxChange}
                                                icons={[]} /></div>)
                                            )}
                                    </Paper>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={10} className={classes.grid}>
                                    <Typography variant="h6" noWrap className={classes.typography}>
                                        Informes
                                    </Typography>
                                    {!loading && !state.addNewReport &&
                                        <Grid item>
                                            {state.edit && <Tooltip title="Agregar nuevo Informe"><IconButton onClick={handleAddNewReport}><Add /></IconButton></Tooltip>}
                                        </Grid>}
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        state.addNewReport &&
                                        <TreatmentReportListItem
                                            key={-1}
                                            item={''}
                                            text={''}
                                            editable={true}
                                            icons={[
                                                { icon: <Done />, callback: handleAddType, tooltip: "Aceptar" },
                                            ]} />
                                    }
                                    {loading ?
                                        <Grid item xs={12} className={classes.grid}>
                                            <CircularProgress className={classes.progress} />
                                        </Grid> :
                                        objectData.reports && objectData.reports.map((item, index) =>
                                        (<TreatmentReportListItem
                                            key={item.uid}
                                            item={item}
                                            name={item.name}
                                            text={item.text}
                                            uid={item.uid}
                                            editable={state.edit}
                                            handleEdit={handleReportEdited}
                                            icons={state.edit && [
                                                { icon: <Delete />, callback: handleDeleteReport, tooltip: "Eliminar" },
                                            ]} />)
                                        )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ Container>
            } />
        </div>
    );
}
