import React, { useEffect,useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, CircularProgress  } from '@material-ui/core/';
import AppContainer from './AppContainer';
import BillingBasicView from './BillingBasicView';
import { getAllTreatments } from '../actions/treatments';
import { mobileCheck } from '../utils/mobileCheck';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    paper: {
        padding: theme.spacing(2),
    },
    container: {
        padding: theme.spacing(mobileCheck() ? 0 : 4),
    },
    grid: {
        margin: '10px',
    },
    textField: {
        width: '100%',
    },
    button: {
        margin: theme.spacing(1),
    },
    typography: {
        margin: theme.spacing(1),
    },
}));

export default function Billing(props) {
    const classes = useStyles();

    const [view, setView] = useState(<div />);
    
    const [pageLoaded, setPageLoaded] = useState(false);
    const [pageLoading,setPageLoading]= useState(true);
    useEffect(() => {
        if(pageLoaded) return;
        setPageLoaded(true);
        fetchData();
        // eslint-disable-next-line
    }, []);

    async function fetchData() {
        const result = await getAllTreatments();

        if (!result.success) {
            if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
            props.openSnack(result.message.error, 'error');
            return;
        }

        setView(<BillingBasicView openSnack={props.openSnack}/>);
        setPageLoading(false);
    }
if (!pageLoading){
    return (
        <div>
            <AppContainer title={"Facturación"} history={props.history} content={
                <Container maxWidth="lg" className={classes.container}>
                    {view}
                </Container>
            } />
        </div>
    );
} else {return (
    <div>
        <AppContainer title={"Facturación"} history={props.history} content={

              <div
            style={{
              marginTop: "3%",
              textAlign: "center",
              width: "100%",
            }}
          >
            <CircularProgress />{" "}
          </div>
        } />
    </div>
);}
}
