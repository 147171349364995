import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, Tooltip, IconButton, List } from '@material-ui/core';
import { dateToString } from '../utils/dateToString';
import { getDoctor } from '../actions/doctors';
import { Edit } from '@material-ui/icons';
import { isAdmin, isSecretary, isDoctor } from '../utils/authUtils';
import { getTreatmentsByPatient } from '../actions/treatments';
import CustomListItem from './CustomListItem';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  paper: {
    width: '100%',
    padding: theme.spacing(2),
  },
  container: {
    width: '100%',
    padding: theme.spacing(4),
  },
  grid: {
    width: '100%',
    margin: '10px',
  },
  textField: {
    width: '100%',
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export default function PatientMedicalExamListView(props) {
  const classes = useStyles();

  const [pageLoaded, setPageLoaded] = React.useState(false);
  const [doctor, setDoctor] = React.useState({});
  const [treatments, setTreatments] = React.useState([]);

  /* eslint-disable*/
  useEffect(() => {
    if (pageLoaded)
      return;

    setPageLoaded(true);
    fetchData();
  });
  /* eslint-enable*/

  async function fetchData() {
    const doctorData = await getDoctor(props.medicalExam.doctor);

    if (!doctorData.success) {
      if (doctorData.message.error.indexOf('Token') !== -1) props.tokenExpired();
      return;
    }

    setDoctor(doctorData.data);

    const treatmentsData = await getTreatmentsByPatient(props.medicalExam.patient);

    if (!treatmentsData.success) {
      if (treatmentsData.message.error.indexOf('Token') !== -1) props.tokenExpired();
      props.openSnack && props.openSnack(treatmentsData.message.error, 'error');
      return;
    }

    setTreatments(treatmentsData.data);
  }

  return (
    <div>
      <br />
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          {(isAdmin() || isSecretary() || isDoctor()) && (
            <Tooltip title="Ver detalle">
              <IconButton onClick={() => props.detailHandle()}>
                <Edit />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
        <Grid container spacing={2}>
          <List
            component="nav"
            className={classes.list}
          >
            <CustomListItem
              key={-3}              
              text={`Fecha: ${dateToString(new Date(props.medicalExam.date))}`}
              icons={[
              ]} />
            {doctor !== undefined && doctor.fullname !== undefined &&
              <CustomListItem
                key={-2}                
                text={`Doctor: ${doctor.fullname}`}
                icons={[
                ]} />}
            {treatments.length > 0 &&
              <CustomListItem
                key={-1}                
                text={`Estudios: ${props.medicalExam.treatmentReports.map((tr, idx) => {

                  let item = treatments.filter(x => x._id === tr)[0];

                  let text = item !== undefined ? item.title : "";

                  return text;
                }).join(", ")}`}
                icons={[
                ]} />}

          </List>
        </Grid>
      </Paper>
    </div >
  );
}
