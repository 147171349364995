export const dateToString = date => {

    try
    {
        date.getDate();
    }
    catch(e)
    {
        date = new Date(date);
    }


    let day = "";
    let month = "";
    let year;

    day = date.getDate();
    month = numberToMonth(date.getMonth())
    year = date.getYear() + 1900;

    return `${day} de ${month} de ${year}`;
};

export const dateToString4File = date => {

    let day = "";
    let month = "";
    let year;

    day = date.getDate();
    month = numberToMonth(date.getMonth())
    year = date.getYear() + 1900;

    return `${year}-${month}-${day}`;
};

export function numberToMonth(number) {
    switch (number + 1) {
        case 1:
            return "Enero";
        case 2:
            return "Febrero";
        case 3:
            return "Marzo";
        case 4:
            return "Abril";
        case 5:
            return "Mayo";
        case 6:
            return "Junio";
        case 7:
            return "Julio";
        case 8:
            return "Agosto";
        case 9:
            return "Septiembre";
        case 10:
            return "Octubre";
        case 11:
            return "Noviembre";
        case 12:
            return "Diciembre";
        default:
            return "Error";
    }
}

export function numberToDay(number) {
    let val = "";

    switch (number) {
        case 0:
            val = "Domingo";
            break;
        case 1:
            val = "Lunes";
            break;
        case 2:
            val = "Martes";
            break;
        case 3:
            val = "Miercoles";
            break;
        case 4:
            val = "Jueves";
            break;
        case 5:
            val = "Viernes";
            break;
        case 6:
            val = "Sabado";
            break;
        default:
            val = "Error";
            break;
    }

    return val;
}

export function numberToTime(number) {

    if (number !== 0 && !number)
        return `number undefined: ${number} `;

    let stringed = number.toString();
    let final = "";

    if (stringed < 10)
        final = `0:0${stringed[0]}`;
    else if (stringed < 100)
        final = `0:${stringed[0]}${stringed[1]}`;
    else if (stringed < 1000)
        final = `${stringed[0]}:${stringed[1]}${stringed[2]}`;
    else
        final = `${stringed[0]}${stringed[1]}:${stringed[2]}${stringed[3]}`;
    return final;
}

export function getDateFromTurn(turn) {
    return new Date(turn.dateYear, turn.dateMonth, turn.dateDay, turn.dateHour, turn.dateMinute);
}