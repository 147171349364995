import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {  CircularProgress,TextField, Grid, IconButton, Paper, Tooltip, InputLabel, Chip, Dialog, DialogContent, DialogTitle, DialogActions, Button } from '@material-ui/core';
import { CloudDownload, Edit, Done, Delete } from '@material-ui/icons';
import { modifyTreatment } from '../actions/treatments';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { dateToString } from '../utils/dateToString';
import RichTextEditor from './RichTextEditor';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  paper: {
    width: '100%',
    padding: theme.spacing(2),
  },
  container: {
    width: '100%',
    padding: theme.spacing(4),
  },
  grid: {
    width: '100%',
    margin: '10px',
  },
  textField: {
    width: '100%',
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export default function PatientTreatment(props) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [inputValue] = React.useState({
    date: new Date(props.data.date),
    title: props.data.title,
    result: props.data.result,
    notes: props.data.notes,
  });

  const [isDraft] = React.useState(props.isDraft);

  const [selectedDate, setSelectedDate] = React.useState(new Date(props.data.date));

  const [addenda, setAddenda] = React.useState('');

  const [state, setState] = React.useState({
    editMode: false,
    addendaDialog: false,
  })

  const [editorState, setEditorState] = React.useState(
    props.data.result
  );

  function onChange(value) {
    setEditorState(value);
  }

  function handleEdit() {
  //Despues de 90 minutos (hora y media) hay que acer Addenda para editar
   if (!isDraft && Date.now() > props.data.date + 90* 60 * 1000) {
      addendaCallback();
    }
    else {
      setState({ ...state, editMode: true });
    }
  }

  async function addendaCallback(item) {
    props.openDialog("Addenda", "El tiempo de edición para este estudio ha terminado. Desea crear una Addenda?", item, handleAddendaCallback);
  }

  async function handleAddendaCallback(confirmed, item) {
    if (!confirmed)
      return;

    setState({ ...state, addendaDialog: true });
  }

  async function handleCloseAddendaDialog() {
    setState({ ...state, addendaDialog: false });
    setAddenda('');
  }

  async function handleAcceptAddenda() {
    setLoading(true);
    setState({ ...state, addendaDialog: false });
    const date = new Date();

    const newEditorState = editorState + `<br/><h4><strong>Addenda ${date.getDate()}/${date.getMonth() + 1}/${date.getYear() + 1900}</strong></h4>` + addenda;

    setEditorState(newEditorState);

    await modifyTreatment({ ...props.data, result: newEditorState, date: selectedDate.getTime() });
    setLoading(false);
  }

  function handleRemove() {
    props.delete();
  }

  async function handleDoneEdit() {
    await modifyTreatment({ ...props.data, result: editorState, date: selectedDate.getTime() });
    setState({ ...state, editMode: false });
  }

  function handleDateChange(date) {
    setSelectedDate(date);
  }
  if (loading){
    return (<Grid item xs={12} className={classes.grid}>
      <CircularProgress className={classes.progress} />
    </Grid>);
  }else {
    return (
      <div>
        <br />
        <Paper className={classes.paper}>
          <Grid container>
            {props.displayDate &&
              <Grid item xs={3} className={classes.grid}>
                {props.editable ?
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                      autoOk
                      openTo="date"
                      value={selectedDate}
                      onChange={handleDateChange}
                    />
                  </MuiPickersUtilsProvider>
                  :
                  <TextField
                    label={'Fecha'}
                    className={classes.textField}
                    value={dateToString(new Date(props.data.date))}
                    name={'date'}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      readOnly: true,
                    }}
                  />
                }
              </Grid>}
            <Grid item xs={4} className={classes.grid}>
              <TextField
                InputProps={{
                  readOnly: !props.editable,
                }}
                label={'Titulo'}
                className={classes.textField}
                name={'text'}
                value={inputValue.title}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={4} className={classes.grid}>
              {props.editable && !state.editMode &&
                <Tooltip title="Editar">
                  <IconButton onClick={handleEdit}>
                    <Edit />
                  </IconButton>
                </Tooltip>}
              {state.editMode &&
                <div>
                  <Tooltip title="Editar">
                    <IconButton onClick={handleDoneEdit}>
                      <Done />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Editar">
                    <IconButton onClick={handleRemove}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </div>}
            </Grid>
            <Grid item xs={12} className={classes.grid}>
              <InputLabel shrink>Estudio</InputLabel>
              <div className={classes.editor}>
                <RichTextEditor readOnly={!state.editMode} value={editorState} onChange={onChange} />
              
              </div>
            </Grid>
            <Grid item xs={12} className={classes.grid}>
              {props.data.files &&
                props.data.files.map((it, ix) => {
                  return (
                    <Chip
                      key={ix}
                      label={it}
                      value={it}
                      onDelete={() => {
                        props.download(it, props.data);
                      }}
                      deleteIcon={<CloudDownload />}
                    />
                  );
                })}
            </Grid>
            {false && <Grid item xs={12} className={classes.grid}>
              <TextField
                InputProps={{
                  readOnly: true,
                }}
                label={'Notas'}
                className={classes.textField}
                value={inputValue.notes}
                name={'notes'}
                multiline
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>}
            <Grid item className={classes.grid}>
              {props.icons &&
                props.icons.map((icon, index) => {
                  return (
                    <Tooltip title={icon.tooltip}>
                      <IconButton
                        key={index}
                        onClick={() => {
                          if (props.editable) icon.callback(inputValue);
                          else icon.callback(props.item);
                        }}
                      >
                        {icon.icon}
                      </IconButton>
                    </Tooltip>
                  );
                })}
            </Grid>
          </Grid>
        </Paper>
        <Dialog
          className={classes.dialog}
          open={state.addendaDialog ? state.addendaDialog : false}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Addenda</DialogTitle>
          <DialogContent>
            <RichTextEditor readOnly={false} value={''} onChange={(value) => setAddenda(value)} />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseAddendaDialog} color="secondary">
              Cancelar
            </Button>
            <Button onClick={handleAcceptAddenda} color="primary" autoFocus>
              Aceptar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
              
    );
  }
}
