import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Container, Grid, TextField, IconButton, Typography, Tooltip } from '@material-ui/core/';
import AppContainer from './AppContainer';
import { getSupply, createSupply, modifySupply, deleteSupply } from '../actions/supplies';
import { Edit, Delete, Done, Close } from '@material-ui/icons';
import { isAdmin } from '../utils/authUtils';
import { mobileCheck } from '../utils/mobileCheck';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    paper: {
        padding: theme.spacing(2),
    },
    container: {
        padding: theme.spacing(mobileCheck() ? 0 : 4),
    },
    grid: {
        margin: '10px',
    },
    textField: {
        width: '100%',
    },
    button: {
        margin: theme.spacing(1),
    },
    typography: {
        margin: theme.spacing(1),
    },
}));

export default function Supply(props) {
    const classes = useStyles();

    const [originalObjectData, setOriginalObjectData] = React.useState({
        name: '',
        notes: '',
        types: [],
    });

    const [objectData, setObjectData] = React.useState({
        name: '',
        notes: '',
        types: [],
    });

    const [state, setState] = React.useState({
        loaded: false,
        create: false,
        edit: false,
        addNewType: false,
    })

    const [pageLoaded, setPageLoaded] = React.useState(false);

    /* eslint-disable*/
    useEffect(() => {
        if (pageLoaded) return;

        if (props.match.params.id)
            fetchData(props.match.params.id);
        else
            setState({ ...state, create: true, edit: true, loaded: true });
    }, []);
    /* eslint-enable*/

    async function fetchData(id) {
        const result = await getSupply(id);

        if (!result.success) {
            if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
            props.openSnack(result.message.error, 'error');

            return;
        }

        setObjectData(result.data);
        setOriginalObjectData(result.data);
        setPageLoaded(true);
    }

    function handleEdit() {
        setState({ ...state, edit: true });
    }

    function handleCancelEdit() {
        if (state.create) {
            props.history.push('/supplies');
            return;
        }

        setObjectData({ ...originalObjectData });
        setState({ ...state, edit: false });
    }

    async function handleDoneEdit() {
        let result = {};
        if (state.create) {
            result = await createSupply(objectData);
            props.history.push('/supplies');
        }
        else
            result = await modifySupply(objectData);

        if (!result.success) {
            if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
            props.openSnack(result.message.error, 'error');

            return;
        }

        setOriginalObjectData({ ...result.data });
        setState({ ...state, edit: false, create: false });
    }

    function handleDeleteSupply() {
        if (!state.create)
            props.openDialog("Confirmación", "Seguro que desea eliminar este insumo?", objectData, handleDeleteCallback);
        else
            props.history.push('/supplies');
    }

    async function handleDeleteCallback(confirmed, item) {
        if (!confirmed)
            return;

        const result = await deleteSupply(item._id);
        if (!result.success) {
            if (result.message.error?.indexOf('Token') !== -1) props.tokenExpired();
            props.openSnack(result.message.error, 'error');
            return;
        }
        props.history.push('/supplies');
    }

    function handleChange(event) {
        setObjectData({
            ...objectData,
            [event.target.name]: event.target.value,
        });
    }

    return (
        <div>
            <AppContainer title={"Insumo"} history={props.history} content={
                <Container maxWidth="lg" className={classes.container}>
                    <Grid container spacing={3}>
                        {!mobileCheck() && <Grid item xs={1} className={classes.grid} />}
                        <Grid item xs={!mobileCheck() ? 10 : 12} className={classes.grid}>
                            <Grid container>
                                <Grid item className={classes.grid}>
                                    <Typography variant="h6" noWrap className={classes.typography}>
                                        Información
                                </Typography>
                                </Grid>
                                <Grid item className={classes.grid}>
                                    {(isAdmin() && !state.edit) && <Tooltip title="Editar"><IconButton onClick={handleEdit}><Edit /></IconButton></Tooltip>}
                                    {state.edit && <Tooltip title="Cancelar"><IconButton onClick={handleCancelEdit}><Close /></IconButton></Tooltip>}
                                    {state.edit && <Tooltip title="Aceptar"><IconButton onClick={handleDoneEdit}><Done color="primary" /></IconButton></Tooltip>}
                                    {(isAdmin() && state.edit) && <Tooltip title="Eliminar"><IconButton onClick={handleDeleteSupply}><Delete color="error" /></IconButton></Tooltip>}
                                </Grid>
                            </Grid>
                            <Paper className={classes.paper}>
                                <Grid container>
                                    <Grid item xs={8} className={classes.grid}>
                                        <TextField
                                            InputProps={{
                                                readOnly: !state.edit,
                                            }}
                                            label={'Nombre'}
                                            className={classes.textField}
                                            value={objectData.name}
                                            name={'name'}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            onChange={e => {
                                                if (!state.edit) return;
                                                handleChange(e);
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </ Container>
            } />
        </div>
    );
}
