import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { mobileCheck } from "../utils/mobileCheck";
import "./css/TreatmentPricesListByPrepaid.css";
import { Delete } from "@material-ui/icons";
import { Grid, IconButton, Tooltip, Paper } from "@material-ui/core";
import {
  getTreatmentsPricesHistory,
  deletePrepaidAdjustment,
  checkFuturePrices,
} from "../actions/treatmentsPrepaid";
import { deleteTreatmentPrice } from "../actions/treatmentTypePrices";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    padding: theme.spacing(2),
  },
  container: {
    padding: theme.spacing(mobileCheck() ? 0 : 4),
  },
  grid: {
    margin: "10px",
  },
  textField: {
    width: "100%",
  },
  button: {
    margin: theme.spacing(1),
  },
  typography: {
    margin: theme.spacing(1),
  },
  progress: {
    display: "block",
    marginLeft: "auto",
    marginRight: "auto",
  },
}));
export default function TreatmentsPricesHistoryList(props) {
  const classes = useStyles();
  function buildDateStr(date) {
    var d = new Date(date);
    var dateStr = `${d.getDate() < 10 ? "0" + d.getDate() : d.getDate()}/${
      d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1
    }/${d.getFullYear()}`;
    return dateStr;
  }
  const handleDeleteAdjustment = async (dat) => {
    props.parentProps.openDialog(
      "Confirmación",
      "Está seguro que desea eliminar este aumento porcentual?",
      {},
      handleDeleteCallback
    );

    async function handleDeleteCallback(confirmed, item) {
      if (!confirmed) return;
      props.setDataLoading(true);
      const result = await deletePrepaidAdjustment(dat._id);
      if (result.success) {
        props.parentProps.openSnack(
          "Los cambios fueron procesados exitosamente.",
          "success"
        );
        const tp = await getTreatmentsPricesHistory(
          props.data.prepaid.value,
          props.dates.from,
          props.dates.to
        );
        if (!tp.success) {
          if (tp.message.error.indexOf("Token") !== -1) props.tokenExpired();
          props.openSnack(tp.message.error, "error");
          return;
        }
        props.setData((prevData) => {
          return {
            ...prevData,
            treatments: tp.prices,
            adjustments: tp.adjustments,
          };
        });
      } else {
        if (result.message.error.indexOf("Token") !== -1)
          props.parentProps.tokenExpired();
        props.parentProps.openSnack(result.message.error, "error");
      }

      props.setDataLoading(false);
    }
  };
  const handleDeletePrice = async (dat) => {
    props.parentProps.openDialog(
      "Confirmación",
      "¿Está seguro que desea eliminar este aumento?",
      { dat },
      handleDeletePriceCallback
    );
    async function handleDeletePriceCallback(confirmed, item) {
      if (confirmed) {
        props.setDataLoading(true);
        const result = await checkFuturePrices(
          props.data.prepaid.value,
          dat.treatmentType,
          dat.from
        );
        props.setDataLoading(false);
        if (!result.success) {
          if (result.message.error.indexOf("Token") !== -1)
            props.tokenExpired();
          props.openSnack(result.message.error, "error");
          return;
        }
        if (result.isFutureAdjustments) {
          props.parentProps.openDialog(
            "Alerta",
            "Existen aumentos porcentuales con fecha posterior a este aumento. ¿Está segura que desea eliminarlo? ",
            {},
            handlelconfirmedDeleteCallback
          );
        } else {
          handlelconfirmedDeleteCallback(true, {});
        }
      }

      async function handlelconfirmedDeleteCallback(confirmed, item) {
        if (confirmed) {
          props.setDataLoading(true);
          const result = await deleteTreatmentPrice(dat._id);
          if (!result.success) {
            if (result.message.error?.indexOf("Token") !== -1)
              props.tokenExpired();
            props.openSnack(result.message.error, "error");
          } else {
            props.parentProps.openSnack(
              "Los cambios fueron procesados exitosamente.",
              "success"
            );
            const tp = await getTreatmentsPricesHistory(
              props.data.prepaid.value,
              props.dates.from,
              props.dates.to
            );
            if (!tp.success) {
              if (tp.message.error.indexOf("Token") !== -1)
                props.tokenExpired();
              props.openSnack(tp.message.error, "error");
              return;
            }
            props.setData((prevData) => {
              return {
                ...prevData,
                treatments: tp.prices,
                adjustments: tp.adjustments,
              };
            });
          }
          props.setDataLoading(false);
        }
      }
    }
  };
  if (props.data.treatments.length > 0) {
    return (
      <div style={{ marginTop: "2%" }}>
        <Paper
          className={classes.paper}
          style={{
            width: mobileCheck() ? "160%" : "100%",
          }}
        >
          <div
            style={{
              boxShadow: "5px 8px #888888",
              border: "1px solid",
              paddingLeft: "1%",
              paddingBottom: "1%",
              backgroundColor: "#fcfcfc",
              marginBottom: "2%",
            }}
          >
            <h3>Aumentos porcentuales</h3>
            {props.data.adjustments.length === 0 && (
              <div>No existen aumentos porcentuales en este período</div>
            )}
            {props.data.adjustments.map((dato) => {
              return (
                <Grid
                  container
                  spacing={0}
                  alignItems="flex-end"
                  justifyContent="flex-start"
                >
                  <Grid item xs={11}>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      justifyContent="flex-start"
                    >
                      <Grid item xs={3} lg={3} spacing={1}>
                        <TextData
                          title={"Aumento (%)"}
                          content={dato.percentage}
                        />
                      </Grid>
                      <Grid item xs={3} lg={3} spacing={1}>
                        <TextData
                          title={"Desde"}
                          content={buildDateStr(dato.from)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {props.editable && (
                    <Grid item xs={1}>
                      <Tooltip title="Borrar el aumento porcentual">
                        <IconButton
                          onClick={() => {
                            handleDeleteAdjustment(dato);
                          }}
                        >
                          <Delete color="primary" />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              );
            })}
          </div>

          {props.data.treatments.map((dat) => {
            return (
              <div
                style={{
                  boxShadow: "5px 8px #888888",
                  border: "1px solid",
                  paddingLeft: "1%",
                  paddingBottom: "2px",
                }}
              >
                {" "}
                <h4>{dat.name}</h4>
                <div style={{ paddingBottom: "2%" }}>
                  {dat.price.map((dat, item) => {
                    return (
                      <div
                        style={{
                          width: "100%",
                          paddingLeft: "0.5%",
                        }}
                      >
                        <Grid
                          container
                          spacing={0}
                          alignItems="flex-end"
                          justifyContent="flex-start"
                        >
                          <Grid item xs={11}>
                            <Grid
                              container
                              spacing={1}
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              <Grid item xs={3} lg={3} spacing={1}>
                                <SmallTextData
                                  title={"Precio ($)"}
                                  content={dat.price}
                                />
                              </Grid>
                              <Grid item xs={3} lg={3} spacing={1}>
                                <SmallTextData
                                  title={"%"}
                                  content={
                                    dat.percentage == -1 ? "--" : dat.percentage
                                  }
                                />
                              </Grid>
                              <Grid item xs={3} lg={3} spacing={1}>
                                <SmallTextData
                                  title={"Desde"}
                                  content={buildDateStr(dat.from)}
                                />
                              </Grid>
                              <Grid item xs={3} lg={3} spacing={1}>
                                <SmallTextData
                                  title={"Tipo"}
                                  content={
                                    dat.percentageAdj
                                      ? "Porcentual"
                                      : "Individual"
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          {props.editable && (
                            <Grid item xs={1}>
                              <Tooltip title="Borrar el aumento">
                                <IconButton
                                  onClick={() => {
                                    handleDeletePrice(dat);
                                  }}
                                >
                                  <Delete color="primary" />
                                </IconButton>
                              </Tooltip>
                            </Grid>
                          )}
                        </Grid>
                      </div>
                    );
                  })}
                  <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    justifyContent="flex-start"
                    style={{ paddingLeft: "2%", paddingTop: "1%" }}
                  >
                    <Grid item xs={8} lg={6} spacing={1}>
                      <TextData
                        title={"Total porcentual en el período (%)"}
                        content={
                          dat.price[0].price == 0
                            ? "--"
                            : Math.round(
                                (dat.price[dat.price.length - 1].price /
                                  dat.price[0].price -
                                  1) *
                                  100 *
                                  100
                              ) / 100
                        }
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
            );
          })}
        </Paper>
      </div>
    );
  } else {
    return null;
  }
}
const TextData = (props) => {
  return (
    <div>
      <div className="mainTitle">{props.title}</div>
      <div className="mainContent">{props.content}</div>
    </div>
  );
};
const SmallTextData = (props) => {
  return (
    <div>
      <div className="smallTitle">
        <b>{props.title}</b>
      </div>
      <div className="smallContent">
        <b>{props.content}</b>
      </div>
    </div>
  );
};
