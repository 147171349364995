import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, DialogActions, Button, TextField, InputLabel } from '@material-ui/core/';
import { getAllTreatmentTypes } from '../actions/treatmentTypes';
import PredictiveComboBox from './PredictiveText';
import Upload from './drop/upload/Upload';
import RichTextEditor from './RichTextEditor';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  paper: {
    padding: theme.spacing(2),
  },
  container: {
    padding: theme.spacing(0),
  },
  grid: {
    margin: '10px',
  },
  textField: {
    width: '100%',
  },
  smallTextField: {
    width: '100%',
  },
  textFieldNotes: {
    width: '100%',
  },
  button: {
    margin: theme.spacing(1),
  },
  typography: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    margin: theme.spacing(1),
  },
  editor: {
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
    fontSize: '16',
    fontWeight: '400',
    borderBottom: '1px solid grey',
  },
}));

export default function PatientTreatmentDialog(props) {
  const classes = useStyles();

  const [treatments, setTreatments] = React.useState([]);

  const [treatmentFiles, setTreatmentFiles] = React.useState([]);

  const [selTT, setSelTT] = React.useState({
    label: '',
    value: -1,
  });

  const [inputValues, setInputValues] = React.useState({
    treatmentData: {},
    title: '',
    notes: '',
  });

  const [reportOptions, setReportOptions] = React.useState([]);
  const [pageLoaded, setPageLoaded] = React.useState(false);
  const [editorState, setEditorState] = React.useState("");
  const [buttonEnabled, setButtonEnabled]=React.useState(true);
  /* eslint-disable*/
  useEffect(() => {
    if (pageLoaded) return;
    setPageLoaded(true);
    fetchData();
  }, []);
  /* eslint-enable*/

  async function fetchData() {
    const t = await getAllTreatmentTypes();
    setTreatments([...t.data]);

    if (selTT.value === undefined) return;

    loadTreatmentData(selTT.value, t.data);
  }

  function loadTreatmentData(treatmentId, allTreatments) {
    if (!allTreatments) allTreatments = treatments;

    const treatment = allTreatments.filter(item => item._id === treatmentId)[0];

    if (!treatment) return;

    setInputValues({ ...inputValues, title: treatment.name });
    setReportOptions([...treatment.reports]);
  }

  function handleCancel() {
    props.close();
  }

  function handleAccept() {
    setButtonEnabled(false);
    if (buttonEnabled) {
    props.close(
      {
        treatmentType: selTT.value,
        date: new Date().getTime(),
        title: inputValues.title,
        result: editorState,
        notes: inputValues.notes,
      },
      treatmentFiles,
    );
    }
  }

  function handleTreatmentChange(t) {
    setSelTT(t);
    loadTreatmentData(t.value);
  }

  function handleChange(event) {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
  }

  function handleReportChange(text) {
    setEditorState(text);
    setInputValues({ ...inputValues, report: text });
  }

  function onChange(value) {
    setEditorState(value);
  }

  function handleFilesAdded(files) {
    const newArray = [...treatmentFiles, ...files];
    setTreatmentFiles(newArray);
  }

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container>
        <Grid item xs={12} className={classes.grid}>
          <PredictiveComboBox
            reduce={false}
            label={'Estudios'}
            suggestions={treatments.map((it, ix) => {
              return {
                key: ix,
                label: it.name,
                value: it._id,
              };
            })}
            multi={false}
            value={selTT}
            changecallback={v => {
              handleTreatmentChange(v);
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.grid}>
          {reportOptions.map((it, ix) => (
            <Button
              key={ix}
              variant="contained"
              onClick={() => {
                handleReportChange(it.text);
              }}
              className={classes.button}
            >
              {!it.name ? '-' : it.name}
            </Button>
          ))}
        </Grid>
        <Grid item xs={12} className={classes.grid}>
          <TextField
            label={'Titulo'}
            className={classes.smallTextField}
            value={inputValues.title}
            name={'title'}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => {
              handleChange(e);
            }}
          />
        </Grid>
        <Grid item xs={12} className={classes.grid}>
          <InputLabel shrink>Informe</InputLabel>
          <br />
          <div className={classes.editor}>
            <RichTextEditor readOnly={false} value={editorState} onChange={onChange} />
          </div>
        </Grid>
        {false && <Grid item xs={12} className={classes.grid}>
          <TextField
            label={'Notas'}
            className={classes.textField}
            value={inputValues.notes}
            name={'notes'}
            multiline
            InputLabelProps={{
              shrink: true,
            }}
            onChange={e => {
              handleChange(e);
            }}
          />
        </Grid>}
        <Grid item xs={12} className={classes.grid}>
          <Upload filesAdded={handleFilesAdded} />
        </Grid>
        <br />
        <br />
        <Grid item xs={12} className={classes.grid}>
          <DialogActions>
            <Button onClick={handleCancel} color="primary">
              Cancelar
            </Button>
            <Button onClick={handleAccept} color="primary">
              Aceptar
            </Button>
          </DialogActions>
        </Grid>

      </Grid>
    </Container>
  ); 

}
