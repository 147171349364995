import { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import updateColorStatus from "../actions/treatmentPricesSetColors";
export default function TreatmentAddPriceDialog(props) {
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [currentPrice, setCurrentPrice] = useState(0);
  const handleClose = () => {
    props.setAddData({ visible: false });
  };
  const handleAddPrices = () => {
    if (!currentPrice) {
      props.openSnack("El campo precio no puede estar vacío", "error");
      return;
    }
    //Hago un clon de props.futurePrices para que no cambie el valor original

    let futurePrices = props.futurePrices.map((a) => {
      return { ...a };
    });
    //Agrego el nuevo precio después del actual
    let actualPrice = props.futurePrices[0];
    let allNewPrices = [];
    futurePrices.splice(1, 0, {
      status: "added",
      from: calendarDate.getTime(),
      price: currentPrice,
      color: "",
    });
    if (!actualPrice) {
      actualPrice = {
        status: "",
        from: 0,
        price: 0,
        color: "",
      };
      futurePrices = updateColorStatus(futurePrices);
    } else {
      futurePrices = updateColorStatus(futurePrices).slice(1); //Chequeo los colores y le saco el precio actual
    }

    allNewPrices = futurePrices.sort((a, b) => a.from - b.from); //ordeno
    allNewPrices.splice(0, 0, actualPrice); //agreo el precio actual
    console.log(allNewPrices);
    props.setFuturePrices(allNewPrices);
    props.setAddData({ ...props.addData, visible: false });
  };

  return (
    <Dialog
      open={props.addData.visible}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{`Nuevo Precio `}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Por favor ingrese el valor del estudio junto con su fecha de inicio.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="currentPrice"
          label="Aumento ($)"
          type="number"
          variant="standard"
          defaultValue={currentPrice}
          onChange={(e) => {
            setCurrentPrice(e.target.value);
          }}
        />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            autoOk
            orientation="landscape"
            variant="static"
            openTo="date"
            value={calendarDate}
            onChange={(date) => {
              setCalendarDate(date);
            }}
          />
        </MuiPickersUtilsProvider>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            handleClose();
          }}
          autoFocus
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleAddPrices();
          }}
          autoFocus
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
