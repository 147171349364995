import axios from 'axios';

export const getAllDoctors = async () => {
  try {
    const res = await axios.get(`/doctors/`);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

export const getDoctor = async id => {
  try {

    if(!id)
      id = "self";

    const res = await axios.get(`/doctors/${id}`);
    return { success: true, message: '', data: res.data };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

