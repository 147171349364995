import axios from "axios";

export const getBilling = async (from, to) => {
  try {
    const res = await axios.get(
      `/billing/${from.getYear() + 1900}/${from.getMonth()}/${from.getDate()}/${
        to.getYear() + 1900
      }/${to.getMonth()}/${to.getDate()}`
    );
    console.log(res.data.length);
    const r = { success: true, message: "", data: res.data };
    return r;
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};
