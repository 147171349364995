import axios from "axios";
import { setAuthToken } from "../utils/authUtils";

// Login User
export const login = async (username, password) => {
  try {
    const res = await axios.post(
      `/auth`,
      { username, password },
      {
        validateStatus: function (status) {
          return status < 500; // Resolve only if the status code is less than 500
        },
      }
    );
    setAuthToken(res.data);

    return { success: true, message: "" };
  } catch (err) {
    if (err.response) {
      return { success: false, message: err.response.data };
    } else if (err.request) {
      console.log(err.request);
      //return { success: false, message: err.request };
      return { success: false, message: "Error de red" };
    } else {
      console.log(err);
      return { success: false, message: err.message };
    }
  }
};

// Recover User
export const recoverPassword = async (username) => {
  try {
    const res = await axios.post(`/auth/recover`, { username });
    setAuthToken(res.data);
    return { success: true, message: "" };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

// Update User
export const updatePassword = async (token, password) => {
  try {
    const res = await axios.post(`/auth/update`, { token, password });
    setAuthToken(res.data);
    return { success: true, message: "" };
  } catch (err) {
    return { success: false, message: err.response.data };
  }
};

// Logout User
export const logout = () => {
  setAuthToken();
};
