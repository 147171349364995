import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Typography, List, Checkbox, TextField } from '@material-ui/core/';
import CustomListItem from './CustomListItem';
import { mobileCheck } from '../utils/mobileCheck';
import { doctorCutomTime } from '../utils/doctorUtils';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    paper: {
        padding: theme.spacing(2),
    },
    container: {
        padding: theme.spacing(mobileCheck() ? 0 : 4),
    },
    grid: {
        margin: '10px',
    },
    textField: {
        width: '100%',
    },
    textField2: {
        width: '50px',
    },
    button: {
        margin: theme.spacing(1),
    },
    typography: {
        margin: theme.spacing(1),
    },
}));

export default function DoctorTreatmentTypes(props) {
    const classes = useStyles();

    return (
        <div>
            <Grid container>
                <Grid item className={classes.grid}>
                    <Typography variant="h6" noWrap className={classes.typography}>
                        Estudios
                    </Typography>
                </Grid>
            </Grid>
            <Paper className={classes.paper}>
                <List
                    component="nav"
                    className={classes.list}
                >
                    {props.treatments.map(item => {
                        const enabled = item.doctors.indexOf(props.doctor._id) > -1;
                        return <CustomListItem
                            key={item._id}
                            item={item}
                            text={item.name}
                            icons={!enabled ? [
                                {
                                    icon: <Checkbox
                                        disabled={!props.editMode}
                                        checked={enabled}
                                        onChange={() => { if (!props.editMode) return; props.changedCallback(item, !enabled); }}
                                    />
                                    , callback: () => { }, tooltip: enabled ? "Deshabilitar" : "Habilitar"
                                },
                            ] : [{
                                icon: <TextField
                                    InputProps={{
                                        readOnly: !props.editMode,
                                    }}
                                    disabled={!props.editMode}
                                    label={'Tiempo'}
                                    className={classes.textField2}
                                    name={'turnsTaken'}
                                    type='number'
                                    value={doctorCutomTime(props.doctor, item._id) ?? item.turnsTaken}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={e => {
                                        props.changedTimeCallback(item, e.target.value)
                                    }} />, callback: () => { }, tooltip: enabled ? "Deshabilitar" : "Habilitar"
                            }, {
                                icon: <Checkbox
                                    disabled={!props.editMode}
                                    checked={enabled}
                                    onChange={() => { if (!props.editMode) return; props.changedCallback(item, !enabled); }}
                                />, callback: () => { }, tooltip: enabled ? "Deshabilitar" : "Habilitar"
                            }]} />
                    }
                    )}
                </List>
            </Paper>
        </div >
    );
}
